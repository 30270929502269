import React, { useEffect, useState } from 'react';
import {
  Divider, Drawer, Tag, Popconfirm, Space,
} from 'antd';
import { patchWindAction } from 'common/business/actions/windsAction';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  TagOutlined, EditOutlined, DeleteOutlined, DiffOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import WindForm from '../../shared/forms/WindForm';
import TableTemplate from '../../shared/TableTemplate';
import DashboardList from './DashboardStyle';

const WindsList = ({ isAGS, isADMIN, roleCode }) => {
  const [visible, setVisible] = useState(false);
  const { companies } = useSelector((state) => state.companiesReducer);
  const { winds } = useSelector((state) => state.windsReducer);
  const { blocks } = useSelector((state) => state.blocksReducer);
  const { domains } = useSelector((state) => state.domainsReducer);
  const [update, setUpdate] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentWind, setCurrentWind] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const date = Date.now();
  const TOKEN = process.env.REACT_APP_API_AEOLUS_TOKEN;

  const renderData = () => {
    const data = [];
    winds?.map((wind) => {
      const strBlocks = wind.blocksId;
      const airyBlocks = strBlocks.split(', ');
      const arrayBlocks = [];
      let companyName = '';
      let domainName = '';

      airyBlocks?.map((airyBlock) => {
        blocks?.map((block) => {
          if (block.id === airyBlock) {
            domainName = block.domainId;
            arrayBlocks.push(block.name);
          }
          return null;
        });
        companies?.map((company) => {
          if (company.id === wind.companyId) {
            companyName = company.name;
          }
          return null;
        });
        domains?.map((domain) => {
          if (domain.id === domainName) {
            domainName = domain.name;
          }
          return null;
        });
        return null;
      });
      data.push({
        key: wind.serial,
        blocks: arrayBlocks,
        company: companyName,
        serial: wind.serial,
        domain: domainName,
        ...wind,
      });
      return null;
    });
    setTableData(data);
  };
  useEffect(() => {
    if (winds) {
      renderData();
    }
  }, [winds, blocks]);

  const onClose = () => {
    setVisible(false);
    setUpdate(false);
  };

  const confirm = (id) => {
    dispatch(patchWindAction.request({
      roleCode,
      serial: id,
      suppressionDate: date,
      companyId: '',
      domainId: '',
      blocksId: '',
      token: TOKEN,
    }));
  };

  const columns = [
    {
      title: t('module.wind.title'),
      dataIndex: 'serial',
      key: 'serial',
      render: (text) => <span key={text}>{text}</span>,
      responsive: ['md', 'lg'],
      width: 90,
      hidden: false,
    },
    {
      title: t('module.wind.blocks'),
      dataIndex: 'blocks',
      key: 'blocks',
      render: (text) => text?.map((block) => (
        <Tag key={block} icon={<TagOutlined />} color="#8ADFC1">{block}</Tag>
      )),
      responsive: ['md', 'lg'],
      width: 120,
      hidden: false,
    },
    {
      title: t('module.wind.domain'),
      dataIndex: 'domain',
      key: 'domain',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      width: 50,
      align: 'center',
      hidden: false,
    },
    {
      title: t('module.actions'),
      dataIndex: t('module.actions'),
      key: t('module.actions'),
      align: 'right',
      width: 50,
      hidden: !(isAGS || isADMIN),
      render: (text, record) => (
        <Space size="middle">
          <EditOutlined
            className="iconAction"
            onClick={() => {
              setVisible(!visible);
              setCurrentWind(record);
              setUpdate(true);
            }}
          />
          {isAGS
            && (
              <Popconfirm
                title={t('module.delete')}
                onConfirm={() => confirm(record.serial)}
              >
                <DeleteOutlined className="iconAction" />
              </Popconfirm>
            )}
        </Space>
      ),
      responsive: ['md', 'lg'],
    },
  ];

  const renderColumns = columns.filter((col) => col.hidden === false);

  return (
    <>
      <DashboardList width="600">
        <div className="header-list">
          <h3>
            {t('module.wind.list')}
          </h3>
          {(isAGS || isADMIN) && (
          <DiffOutlined onClick={() => setVisible(!visible)} />
          )}
        </div>
        <Divider />
        <TableTemplate columns={renderColumns} data={tableData} />
      </DashboardList>
      <Drawer
        title={update ? t('module.wind.update') : t('module.wind.create')}
        placement="right"
        closable
        onClose={onClose}
        open={visible}
        width={500}
      >
        <WindForm
          update={!!update}
          onClose={onClose}
          currentWind={currentWind}
        />
      </Drawer>
    </>
  );
};

WindsList.propTypes = {
  roleCode: PropTypes.string.isRequired,
  isAGS: PropTypes.bool.isRequired,
  isADMIN: PropTypes.bool.isRequired,
};

export default WindsList;
