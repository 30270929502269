import api from '../../utils/query';

export const getProductsService = (data) => {
    const { source, token } = data;
    const url = "product"
   
    return api.get({url, source, token});
}
export const postProductsService = (data) => {
    return api.create("product", data);
}
export const patchProductsService = (data) => {
    const { productId } = data;
    const url = `product/${productId}`;
    return api.update(url, data);
}
export const deleteProductsService = (data) => {
    const { token, id } = data;
    const url = `product/${id}`

    return api.del(url,token);
}
