import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getPayloadsBlockAction } from 'common/business/actions/blocksAction';
import { getPayloadsDomainAction } from 'common/business/actions/domainsAction';
import { AuthContext } from '../../utils/context/authContext';
import FormTemplate from './formTemplate';
import { renderSelectOptions } from '../../utils/utils';
import { onCancel, onError, onSuccess } from '../../utils/formFx';

const ObservationForm = ({
  handleSwitchFormAndObservations,
  extractMethod,
  setStartDateValue,
  setEndDateValue,
  setBlockNameValue,
  setDomainNameValue,
  setExtractMethod,
  loadingDomain,
}) => {
  const { loading: loadingBlock, blocks } = useSelector((state) => state.blocksReducer);
  const { domains } = useSelector((state) => state.domainsReducer);
  const [domainIdValue, setDomainIdValue] = useState(null);
  const [blockIdValue, setBlockIdValue] = useState(null);
  const [showSelect, setShowSelect] = useState(false);
  const [showDateStart, setShowDateStart] = useState(false);
  const [showPeriodExtract, setShowPeriodExtract] = useState(false);
  const { token } = useContext(AuthContext);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const extractionMethodIsBlock = extractMethod === 'block';

  useEffect(() => {
    if ((extractMethod === 'block' && blockIdValue) || (extractMethod === 'domain' && domainIdValue)) {
      setShowDateStart(true);
    } else {
      setShowDateStart(false);
      setShowPeriodExtract(false);
    }
  }, [domainIdValue, blockIdValue, extractMethod]);

  const handleChangeTypeExtractValue = (e) => {
    setExtractMethod(e.target.value);
    setShowSelect(true);
    setDomainIdValue(null);
    setBlockIdValue(null);
    setShowDateStart(null);
    form.resetFields(['blockId', 'domainId', 'timestampStartDate', 'periodExtract']);
  };

  const onFinish = ({
    domainId, blockId, timestampStartDate, periodExtract,
  }) => {
    const resultPromise = [];

    let startDate = null;
    const endDate = moment(timestampStartDate).valueOf();

    if (periodExtract === '1week') {
      startDate = moment(endDate).subtract(1, 'week').valueOf();
    } else if (periodExtract === '1month') {
      startDate = moment(endDate).subtract(1, 'month').valueOf();
    }

    if (extractMethod === 'block') {
      const blockNameSelected = blocks?.find((block) => block.id === blockId)?.name;
      resultPromise.push(dispatch(getPayloadsBlockAction.request({
        blockId,
        startDate,
        endDate,
        token,
      })));
      setBlockNameValue(blockNameSelected);
    } else {
      const domainNameSelected = domains?.find((domain) => domain.id === domainId)?.name;
      resultPromise.push(dispatch(getPayloadsDomainAction.request({
        domainId,
        startDate,
        endDate,
        token,
      })));
      setDomainNameValue(domainNameSelected);
    }

    setStartDateValue(startDate);
    setEndDateValue(endDate);

    handleSwitchFormAndObservations();

    return Promise.allSettled(resultPromise);
  };
  const variables = [
    {
      name: 'extractionBy',
      label: 'form.extractMethodBy',
      type: 'radioGroup',
      onChangeOption: (e) => handleChangeTypeExtractValue(e),
      radioOptions: [
        { label: t('form.block'), value: 'block' },
        { label: t('form.domains'), value: 'domain' },
      ],
      rules: [
        {
          required: true,
        },
      ],
    },
    {
      name: extractionMethodIsBlock ? 'blockId' : 'domainId',
      label: extractionMethodIsBlock ? 'form.block' : 'form.domains',
      type: 'select',
      selectValues: extractionMethodIsBlock
        ? renderSelectOptions(blocks) : renderSelectOptions(domains),
      change: true,
      rules: [
        {
          required: true,
        },
      ],
      placeholder: extractionMethodIsBlock ? 'form.blockPH' : 'form.domainPH',
      hidden: !showSelect,
    },
    {
      name: 'timestampStartDate',
      label: 'form.startDate',
      type: 'date',
      disabledDate: (current) => current && current > moment().endOf('day'),
      onChange: () => setShowPeriodExtract(true),
      rules: [
        {
          required: true,
        },
      ],
      hidden: !showDateStart,
    },
    {
      name: 'periodExtract',
      label: 'form.periodExtract',
      type: 'radioGroup',
      radioOptions: [
        { label: t('form.1week'), value: '1week' },
        { label: t('form.1month'), value: '1month' },
      ],
      rules: [
        {
          required: true,
        },
      ],
      hidden: !showPeriodExtract,
    },
  ];
  return (
    <FormTemplate
      className="form"
      nameForm="statistic"
      useForm={form}
      variables={variables}
      onSuccess={() => onSuccess({
        loading: loadingBlock || loadingDomain, form, messageTxt: 'module.statistics.dataSuccess',
      })}
      onFinishApiCall={(event) => onFinish(event)}
      onError={() => onError({ messageTxt: 'domain.error.title', descriptionTxt: 'domain.error.description' })}
      buttonValidText="form.valid"
      onCancel={() => onCancel(form)}
      setSelectedValue={extractionMethodIsBlock ? setBlockIdValue : setDomainIdValue}
      setStartDateSelected={setShowPeriodExtract}
      hiddenButton={!showPeriodExtract}
    />
  );
};

ObservationForm.propTypes = {
  handleSwitchFormAndObservations: PropTypes.func.isRequired,
  extractMethod: PropTypes.string,
  setStartDateValue: PropTypes.func.isRequired,
  setEndDateValue: PropTypes.func.isRequired,
  setBlockNameValue: PropTypes.func.isRequired,
  setDomainNameValue: PropTypes.func.isRequired,
  setExtractMethod: PropTypes.func.isRequired,
  loadingDomain: PropTypes.bool.isRequired,
};

ObservationForm.defaultProps = {
  extractMethod: null,
};

export default ObservationForm;
