import { TYPE_TASKS } from '../actions/typeTaskAction';

const INITIAL_STATE = { typeTask: null, typeTaskState: null, loading: false };

const typeTaskReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case TYPE_TASKS.FETCH.REQUEST:
      return {...state, loading: true};
    case TYPE_TASKS.FETCH.SUCCESS:
        return {...state, typeTask: payload, loading: false};
    case TYPE_TASKS.FETCH.FAILURE:
        return {...state, loading: false};    
    // case TYPE_TASKS.POST.REQUEST:
    //     return { ...state, loading: true };
    // case TYPE_TASKS.POST.SUCCESS:
    //     return { ...state, typeTaskState: payload, loading: false };
    // case TYPE_TASKS.POST.FAILURE:
    //     return { ...state, loading: false };
    // case TYPE_TASKS.PATCH.REQUEST:
    //     return { ...state, loading: true };
    // case TYPE_TASKS.PATCH.SUCCESS:
    //     return { ...state, typeTaskState: payload, loading: false };
    // case TYPE_TASKS.PATCH.FAILURE:
    //     return { ...state, loading: false };
    // case TYPE_TASKS.DELETE.REQUEST:
    //     return { ...state, loading: true };
    // case TYPE_TASKS.DELETE.SUCCESS:
    //     return { ...state, typeTaskState: payload, loading: false};
    // case TYPE_TASKS.DELETE.FAILURE:
    //     return { ...state, loading: false};
    default:
      return state;
  }
};

export default typeTaskReducer;
