import { put, takeEvery, call } from 'redux-saga/effects';
import { getLoginAction, LOGIN, getLogoutAction } from '../actions/loginAction';
import { getLoginService } from '../api/loginService';

export function* getLogin({ payload }) {
  const { login, password, source} = payload;

  const users = yield call(getLoginService, source);
  const user = users.find((elt) => elt.email === login && elt.password === password);
  
  try {
    if(user) {
      yield put(getLoginAction.success(user));
    }
    else {
      console.log('Erreur')
    }
  } catch (error) {
    yield put(getLoginAction.failure(error));
  }
}

export function* getLoginSaga() {
  yield takeEvery(LOGIN.FETCH.REQUEST, getLogin);
}

export function* getLogout() {
  try {
    yield put(getLogoutAction.success('logout'));
  } catch (error) {
    yield put(getLogoutAction.failure(error));
  }
}

export function* getLogoutSaga() {
  yield takeEvery(LOGIN.DELETE.REQUEST, getLogout);
}
