import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DatePicker, TimePicker, Radio, Spin,
} from 'antd';
import {
  currentDate, direction, filters, formatDate, windDirection,
} from 'common/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  ArrowLeftOutlined, ArrowRightOutlined, TagOutlined,
} from '@ant-design/icons';
import { getSentinellesAction } from 'common/business/actions/sentinellesAction';
import { getCurrentBlockAction } from 'common/business/actions/blocksAction';
import { customIcon } from '../../utils/Icons';

const PanelControl = ({
  setTimestamp, weatherData, setFilterValue, filterValue,
}) => {
  const { t } = useTranslation();
  const { blocks, currentBlock } = useSelector((state) => state.blocksReducer);
  const { currentDomain } = useSelector((state) => state.domainsReducer);
  const [isDisabled, setIsDisabled] = useState(false);
  const { token } = useSelector((state) => state.authReducer);
  const { loading } = useSelector((state) => state.picassoReducer);
  const { weather } = useSelector((state) => state.weatherReducer);
  const customDate = formatDate({ shortDate: currentDate });
  const [date, setDate] = useState(moment(customDate, t('form.formatDate')));
  const initialDate = moment(customDate, t('form.formatDate'));
  const diffBetweenDates = date.diff(initialDate, 'days');
  const [hour, setHour] = useState(moment('120000', 'HHmmss'));
  const directionDegree = windDirection(weather?.wd);
  const directionVue = direction[directionDegree];

  const dateFormat = t('form.formatDate');
  const timeFormat = 'HH:00';
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const formatHour = formatDate({ unixHour: hour.toDate() });
    const formatDateunix = formatDate({ shortDate: date.toDate() / 1000, lang: 'fr' });
    const splitDate = formatDateunix.split('-');
    const forFormatUnixDate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
    const forUnixHour = formatHour.split('h');
    dispatch(getSentinellesAction.request({
      currentDomainId: currentDomain.id,
      companyId: currentDomain.companyId,
      currentBlockId: currentBlock.id,
      token: token.accessToken,
    }));
    setTimestamp(new Date(`${forFormatUnixDate}T${forUnixHour[0]}:00:00`).getTime() / 1000);
  }, [date, hour, currentBlock]);

  useEffect(() => {
    const handle = setTimeout(() => {
      setIsDisabled(false);
    }, 1200);
    return () => clearTimeout(handle);
  }, [isDisabled]);

  const handleClick = (event, value) => {
    event.preventDefault();
    const currentBlockData = blocks.filter((block) => block.id === value);
    dispatch(getCurrentBlockAction.request(currentBlockData[0]));
  };

  const renderHealthRadios = () => (
    <Radio.Group className="filter-radios" onChange={(e) => setFilterValue(e.target.value)} value={filterValue}>
      {filters.map((filter) => (
        <Radio
          key={filter.id}
          value={filter.id}
          disabled={filter.disabled}
        >
          {t(filter.label)}

        </Radio>
      ))}
    </Radio.Group>
  );

  const renderBlocksRadios = () => (
    <Radio.Group defaultValue={currentBlock.name} size="large">
      {blocks.map((block) => (
        <Radio.Button
          key={block.id}
          value={block.name}
          onClick={(event) => handleClick(event, block.id)}
        >
          {block.name}
          <TagOutlined className="picto-tag" />
        </Radio.Button>
      ))}
    </Radio.Group>
  );

  const renderDatasWeather = () => (
    <>
      <div className="weather-panel-temp weather-padding">
        {customIcon.thermometer(30)}
        <span>
          {Math.round(weatherData.t2m)}
          °C
        </span>
        <p>
          {customIcon.thermometerH(20)}
          <span>
            {Math.round(weatherData.dewt2m)}
            °C (T° humide)
          </span>
        </p>
      </div>
      <div className="weather-panel-humidity weather-padding">
        <ul>
          <li>
            {customIcon.water(30)}
            <span>
              {weatherData.rh2m}
              %
            </span>
          </li>
          <li>
            {customIcon.rain(30)}
            <span>
              {Math.floor(weatherData.precip)}
              mm/h
            </span>
          </li>
        </ul>
      </div>
      <div className="weather-panel-wind weather-padding">
        <ul>
          <li>
            {customIcon.windy(30)}
            <span>
              {Math.round(weatherData.ws2m * 3.6)}
              km/h
            </span>
          </li>
          <li>
            {customIcon.direction(30)}
            <span>{directionVue}</span>
          </li>
        </ul>
      </div>

    </>
  );

  const handleChangePicker = (value) => {
    const nextDate = moment(date).add(1, 'days');
    const prevDate = moment(date).add(-1, 'days');
    const nextHour = moment(hour).add(1, 'hour');
    const prevHour = moment(hour).add(-1, 'hour');
    if (!isDisabled) {
      switch (value) {
        case 'prevDate': return (setDate(prevDate), setIsDisabled(true));
        case 'nextDate': return (setDate(nextDate), setIsDisabled(true));
        case 'prevHour': return (setHour(prevHour), setIsDisabled(true));
        case 'nextHour': return (setHour(nextHour), setIsDisabled(true));
        default:
          return null;
      }
    }
    return null;
  };

  const disabledDate = (current) => (
    current < moment(initialDate).add(-7, 'days') || current > moment(initialDate).add(7, 'days')
  );

  const renderPicker = (type) => (
    <div className="picker-input">
      {
         diffBetweenDates === -7 && type === 'datePicker' ? null : <ArrowLeftOutlined onClick={() => handleChangePicker(type === 'datePicker' ? 'prevDate' : 'prevHour')} />
      }
      {type === 'datePicker' ? (
        <DatePicker
          disabledDate={disabledDate}
          defaultValue={date}
          value={date}
          format={dateFormat}
          onChange={setDate}
          allowClear={false}
        />
      ) : (
        <TimePicker
          defaultValue={hour}
          value={hour}
          format={timeFormat}
          onChange={setHour}
          allowClear={false}
        />
      ) }
      {
         diffBetweenDates === 7 && type === 'datePicker' ? null : <ArrowRightOutlined onClick={() => handleChangePicker(type === 'datePicker' ? 'nextDate' : 'nextHour')} />
      }
    </div>
  );

  return (
    <section className="control-panel">
      <h2>{t('vines.filters')}</h2>
      <div className="filter-bloc">
        {renderBlocksRadios()}
      </div>
      {loading && <Spin />}
      {renderHealthRadios()}
      <h2>{t('vines.date')}</h2>
      <section className="picker-panel">
        {renderPicker('datePicker')}
        {renderPicker('hourPicker')}
      </section>
      <section className="weather-panel">
        {filterValue > 3 && renderDatasWeather()}
      </section>
    </section>
  );
};

PanelControl.propTypes = {
  setTimestamp: PropTypes.func.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  weatherData: PropTypes.shape().isRequired,
  filterValue: PropTypes.number.isRequired,
};

export default PanelControl;
