import React from 'react';
import { Table } from 'antd';
import { PropTypes } from 'prop-types';

const TableTemplate = ({ columns, data, height }) => (
  <Table data-testid="table" columns={columns} dataSource={data} size="small" pagination={false} scroll={{ y: height }} />
);

TableTemplate.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  height: PropTypes.number,
};

TableTemplate.defaultProps = {
  height: 220,
};

export default TableTemplate;
