import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';
const PATCH = 'PATCH';
const DELETE = 'DELETE';

export const USER = createRequestTypes('USER', [FETCH, POST, PATCH, DELETE]);
export const USERINFOS = createRequestTypes('USERINFOS', [FETCH]);

export const getUserAction = {
  request: (id) => action(USER.FETCH.REQUEST, id),
  success: (data) => action(USER.FETCH.SUCCESS, data),
  failure: (error) => action(USER.FETCH.FAILURE, error),
};

export const getUserInfosAction = {
  request: (id) => action(USERINFOS.FETCH.REQUEST, id),
  success: (data) => action(USERINFOS.FETCH.SUCCESS, data),
  failure: (error) => action(USERINFOS.FETCH.FAILURE, error),
};

export const postUserAction = {
  request: (id) => action(USER.POST.REQUEST, id),
  success: (data) => action(USER.POST.SUCCESS, data),
  failure: (error) => action(USER.POST.FAILURE, error),
};

export const patchUserAction = {
  request: (id) => action(USER.PATCH.REQUEST, id),
  success: (data) => action(USER.PATCH.SUCCESS, data),
  failure: (error) => action(USER.PATCH.FAILURE, error),
};

export const deleteUserAction = {
  request: (id) => action(USER.DELETE.REQUEST, id),
  success: (data) => action(USER.DELETE.SUCCESS, data),
  failure: (error) => action(USER.DELETE.FAILURE, error),
};
