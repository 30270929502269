import React from 'react';

const HoverInfos = (marker) => {
  const { hoverInfos } = marker;
  const {
    sentinelId, temperature, humidity,
  } = hoverInfos;

  return (
    <article className="tip">
      <h2>Données de la sentinelle</h2>
      <p>
        Sentinelle :
        {sentinelId}
      </p>
      <p>
        température:
        {temperature}
        {' '}
        °C
      </p>
      <p>
        Humidité:
        {humidity}
        {' '}
        %
      </p>
    </article>
  );
};

export default HoverInfos;
