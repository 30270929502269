import api from '../../utils/query';

export const getTasksService = (data) => {
    const {source, companyId, domainId, token} = data
    const url = `${companyId}/domain/${domainId}/manual-task`
   
    return api.get({url, source, token});
}
export const postTasksService = (data) => {
    const { companyId, domainId } = data
    const url = `${companyId}/domain/${domainId}/manual-task`
    return api.create(url, data );
}
export const patchTasksService = (data) => {
    const {id, source, currentDomainId, companyId, token} = data
    const url = `${companyId}/domain/${currentDomainId}/manual-task/${id}`;
    return api.update(url, data);
}

export const deleteTasksService = (id) => {
    const idToString = id.toString();
    const url = `tasks/${idToString}`;
    return api.del(url);
  }
  