import React from 'react';
import { Input } from 'antd';
import { PropTypes } from 'prop-types';

const { TextArea } = Input;

const TextareaForm = ({
  maxLength, showCount,
}) => (
  <TextArea
    maxLength={maxLength}
    showCount={showCount}
    autoSize={{ minRows: 3, maxRows: 5 }}
  />
);

TextareaForm.propTypes = {
  maxLength: PropTypes.number.isRequired,
  showCount: PropTypes.func.isRequired,
};

export default TextareaForm;
