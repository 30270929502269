import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';

export const IMG = createRequestTypes('IMG', [FETCH]);
export const CAM0F = createRequestTypes('CAM0F', [FETCH]);
export const CAM1F = createRequestTypes('CAM1F', [FETCH]);
export const CAM0W = createRequestTypes('CAM0W', [FETCH]);
export const CAM1W = createRequestTypes('CAM1W', [FETCH]);
export const LASTIMG = createRequestTypes('LASTIMG', [FETCH]);

export const getImagesAction = {
  request: payload => action(IMG.FETCH.REQUEST, payload),
  success: data => action(IMG.FETCH.SUCCESS, data),
  failure: error => action(IMG.FETCH.FAILURE, error),
};

export const getImagesCam0FAction = {
  success: data => action(CAM0F.FETCH.SUCCESS, data),
  failure: error => action(CAM0F.FETCH.FAILURE, error),
};

export const getImagesCam1FAction = {
  success: data => action(CAM1F.FETCH.SUCCESS, data),
  failure: error => action(CAM1F.FETCH.FAILURE, error),
};

export const getImagesCam0WAction = {
  success: data => action(CAM0W.FETCH.SUCCESS, data),
  failure: error => action(CAM0W.FETCH.FAILURE, error),
};

export const getImagesCam1WAction = {
  success: data => action(CAM1W.FETCH.SUCCESS, data),
  failure: error => action(CAM1W.FETCH.FAILURE, error),
};

export const getLastImageAction = {
  request: payload => action(LASTIMG.FETCH.REQUEST, payload),
  success: data => action(LASTIMG.FETCH.SUCCESS, data),
  failure: error => action(LASTIMG.FETCH.FAILURE, error),
};
