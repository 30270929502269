import React from 'react';
import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';
// import { Button, Drawer } from 'antd';
import {
  AuditOutlined, ExperimentOutlined,
  SettingOutlined, SolutionOutlined, TagOutlined, UsergroupAddOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import Register from '../users/Register';

const Settings = () => {
  const history = useHistory();
  const userInfos = useSelector((state) => state.usersReducer?.userInfos);
  const roleCode = userInfos?.roleCode;
  const { t } = useTranslation();

  const menu = [
    {
      key: 'company',
      label: t('settings.addCompany'),
      picto: <SolutionOutlined className="menu-picto" />,
      link: 'companySettings',
      roles: ['AGS_ADMIN', 'AGS_USER'],
    },
    {
      key: 'domain',
      label: t('settings.addDomain'),
      picto: <SolutionOutlined className="menu-picto" />,
      link: 'domainSettings',
      roles: ['AGS_ADMIN', 'AGS_USER'],
    },
    {
      key: 'block',
      label: t('settings.addBlock'),
      picto: <SolutionOutlined className="menu-picto" />,
      link: 'blockSettings',
      roles: ['AGS_ADMIN', 'AGS_USER'],
    },
    {
      key: 'user',
      label: t('settings.addUser'),
      picto: <UsergroupAddOutlined className="menu-picto" />,
      link: 'usersSettings',
      roles: ['AGS_ADMIN', 'AGS_USER', 'SUPER_ADMIN'],
    },
    {
      key: 'tasks',
      label: t('tasks.addTask'),
      picto: <TagOutlined className="menu-picto" />,
      link: 'tasksSettings',
      roles: ['AGS_ADMIN', 'AGS_USER', 'SUPER_ADMIN', 'ADMIN'],
    },
    {
      key: 'sentinel',
      label: t('settings.addSent'),
      picto: <TagOutlined className="menu-picto" />,
      link: 'sentinelSettings',
      roles: ['AGS_ADMIN', 'AGS_USER'],
    },
    {
      key: 'wind',
      label: t('settings.addWind'),
      picto: <TagOutlined className="menu-picto" />,
      link: 'windSettings',
      roles: ['AGS_ADMIN', 'AGS_USER'],
    },
    {
      key: 'position',
      label: t('settings.addPos'),
      picto: <TagOutlined className="menu-picto" />,
      link: 'positionSettings',
      roles: ['AGS_ADMIN', 'AGS_USER'],
    },
    {
      key: 'product',
      label: t('product.addProduct'),
      picto: <ExperimentOutlined className="menu-picto" />,
      link: 'productsSettings',
      roles: ['AGS_ADMIN', 'AGS_USER', 'SUPER_ADMIN', 'ADMIN'],
    },
    {
      key: 'vehicle',
      label: t('vehicle.addvehicle'),
      picto: <AuditOutlined className="menu-picto" />,
      link: 'vehiclesSettings',
      roles: ['AGS_ADMIN', 'AGS_USER', 'SUPER_ADMIN', 'ADMIN'],
    },
    {
      key: 'serviceProvider',
      label: t('settings.serviceProviderSettings'),
      picto: <SettingOutlined className="menu-picto" />,
      link: 'serviceProvider',
      roles: ['AGS_ADMIN', 'AGS_USER', 'SUPER_ADMIN'],
    },
  ];

  const handleClick = (elt) => {
    history.push(elt);
  };

  const renderFormsSetting = () => menu.map((elt) => elt.roles.map((access) => {
    if (access === roleCode) {
      return (
        <li className="menu-settings" key={elt.key} onClick={() => handleClick(elt.link)} aria-hidden="true">
          {elt.picto}
          <span>{elt.label}</span>
        </li>
      );
    }
    return null;
  }));

  return (
    <section>
      <ul className="block-menu">{renderFormsSetting()}</ul>
    </section>
  );
};

export default Settings;
