import api from '../../utils/query';

export const getImagesService = (data) => {
    const {source, currentBlockId, currentDomainId, companyId, timestamp, token} = data;
    const url = `${companyId}/domain/${currentDomainId}/block/${currentBlockId}/date/${timestamp}/sentinel/images`;
   
    return api.get({url, source, token});
}

export const getImageSent = async (data) => {
    const { link, token } = data;
    const url = `images/${link}`;
    return await api.getImages({url, token})
}
