import React,
{
  useEffect, useLayoutEffect, useContext, useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Form, message, notification,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { patchBlockAction, postBlockAction } from 'common/business/actions/blocksAction';
import { getCompaniesAction } from 'common/business/actions/companyAction';
import { PropTypes } from 'prop-types';
import { AGS, normalizeKey } from 'common/utils/utils';
import FormTemplate from './formTemplate';
import { AuthContext } from '../../utils/context/authContext';
import { renderDomainsCheckbox, renderSelectOptions } from '../../utils/utils';

const BlockForm = ({
  update, onClose, block,
}) => {
  const [domainOptionValue, setDomainOptionValue] = useState(null);
  const [form] = Form.useForm();
  const { domains, currentDomain } = useSelector((state) => state.domainsReducer);
  const { blocks } = useSelector((state) => state.blocksReducer);
  const { userInfos } = useSelector((state) => state.usersReducer);
  const { roleCode } = userInfos;
  const { companies, currentCompany } = useSelector((state) => state.companiesReducer);
  const [currentCompanyState, setCurrentCompany] = useState(currentCompany);
  const { t } = useTranslation();
  const { token } = useContext(AuthContext);
  const dispatch = useDispatch();
  const isAGS = AGS.includes(roleCode);

  const activeDomains = domains.filter((domain) => domain.active);

  useLayoutEffect(() => {
    if ((!companies || !currentCompany) && !currentDomain) {
      dispatch(getCompaniesAction.request({ token }));
    }
  }, []);

  useEffect(() => {
    if (update) {
      if (block) {
        const {
          company, weatherMeasureId, name, domainId, shape, surface, rows,
          orientation, varietyId, pruningMethod,
          groundType, near, risks, sentinels,
          hightTrellisingHeight, midTrellisingHeight,
          lowTrellisingHeight,
          alertTemperatureThresholdLow,
          alertTemperatureThresholdHight, blockCoordinates,
        } = block;
        const coordinates = [];
        blockCoordinates?.forEach((coord) => {
          coordinates.push(coord);
        });

        form.setFieldsValue({
          companyId: company,
          weatherMeasureId,
          name,
          currenDomainId: domainId,
          shape,
          surface,
          rows,
          orientation,
          varietyId,
          pruningMethod,
          groundType,
          near,
          risks,
          sentinels,
          hightTrellisingHeight,
          midTrellisingHeight,
          lowTrellisingHeight,
          alertTemperatureThresholdLow,
          alertTemperatureThresholdHight,
          points: coordinates,
        });
      }
    } else {
      form.resetFields();
    }
  }, [block, update]);

  const onSuccess = () => {
    notification.success({
      message: <Trans i18nKey="block.success.title" />,
      description: <Trans i18nKey="block.success.description" />,
    });
    if (update) {
      onClose();
    } else {
      form.resetFields();
    }
  };

  const onFinish = (event) => {
    const resultPromise = [];
    if (update) {
      const domainData = domains.find((domain) => domain.id === block.domainId);
      const { companyId } = domainData;
      resultPromise.push(dispatch(patchBlockAction.request({
        companyId,
        domainId: block.domainId,
        blockId: block.id,
        event,
        token,
      })));
    } else {
      resultPromise.push(dispatch(postBlockAction.request({ event, token })));
    }
    return Promise.allSettled(resultPromise);
  };

  const onError = () => {
    notification.error({
      message: <Trans i18nKey="domain.error.title" />,
      description: <Trans i18nKey="domain.error.description" />,
    });
  };

  const onCancel = () => {
    form.resetFields();
  };

  const onChangeOption = (e) => {
    setDomainOptionValue(e.target.value);
  };

  const variables = [
    {
      name: 'companyId',
      label: t('company.form.label'),
      type: 'select',
      change: true,
      noUpdate: update,
      initialValue: currentCompany,
      selectValues: renderSelectOptions(companies, currentCompany),
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'company.form.placeholder',
    },
    {
      name: 'currentDomainId',
      label: 'navigation.Domain',
      type: 'radioGroup',
      noUpdate: update,
      radioOptions: renderDomainsCheckbox({ currentCompanyState, activeDomains }),
      rules: [
        {
          required: true,
        },
      ],
      onChangeOption: (e) => onChangeOption(e),
      selectedOption: domainOptionValue,
    },
    {
      name: 'weatherMeasureId',
      label: 'company.form.weather',
      type: 'numeric',
      hidden: !isAGS,
      // noUpdate: renderItemToShow(),
      rules: [
        {
          required: !update,
        },
      ],
    },
    {
      name: 'name',
      label: t('block.name'),
      rules: [
        {
          required: true,
          min: 2,
          whitespace: true,
        },
        () => ({
          validator(rule, value) {
            if (update && block && normalizeKey(block.name.toLowerCase())
            === normalizeKey(value.toLowerCase())) {
              return Promise.resolve();
            }

            const blockExists = blocks.find((elt) => normalizeKey(elt.name.toLowerCase())
            === normalizeKey(value.toLowerCase()));

            if (!blockExists) {
              return Promise.resolve();
            }

            return Promise.reject(
              message.error(t('block.form.errorName')),
            );
          },
        }),
      ],
      placeholder: t('block.form.name'),
    },
    {
      name: 'shape',
      label: t('block.shape'),
      rules: [
        {
          min: 2,
          whitespace: true,
        },
      ],
      placeholder: t('block.form.shape'),
    },
    {
      name: 'surface',
      label: t('block.surface'),
      rules: [
        {
          min: 1,
        },
      ],
      placeholder: t('block.form.surface'),
    },
    {
      name: 'numberOfRows',
      label: t('block.rows'),
      rules: [
        {
          min: 1,
        },
      ],
      placeholder: t('block.form.rows'),
    },
    {
      name: 'orientation',
      label: t('block.orientation'),
      type: 'numeric',
      rules: [
        {
          required: true,
        },
      ],
      placeholder: t('block.form.orientation'),
    },
    {
      name: 'varietyId',
      label: t('block.variety'),
      rules: [
        {
          required: true,
          min: 2,
          whitespace: true,
        },
      ],
      placeholder: t('block.form.variety'),
    },
    {
      name: 'pruningMethod',
      label: t('block.pruningMethod'),
      rules: [
        {
          min: 2,
          whitespace: true,
        },
      ],
      placeholder: t('block.form.pruningMethod'),
    },
    {
      name: 'groundType',
      label: t('block.groundType'),
      rules: [
        {
          min: 2,
          whitespace: true,
        },
      ],
      placeholder: t('block.form.groundType'),
    },
    {
      name: 'nearOf',
      label: t('block.near'),
      rules: [
        {
          min: 2,
          whitespace: true,
        },
      ],
      placeholder: t('block.form.near'),
    },
    {
      name: 'risks',
      label: t('block.risks'),
      rules: [
        {
          min: 2,
          whitespace: true,
        },
      ],
      placeholder: t('block.form.risks'),
    },
    {
      name: 'sentinels',
      label: t('block.sentinels'),
      rules: [
        {
          min: 1,
        },
      ],
      placeholder: t('block.form.sentinels'),
    },
    {
      name: 'hightTrellisingHeight',
      label: t('block.hightTrellisingHeight'),
      rules: [
        {
          min: 1,
        },
      ],
      placeholder: t('block.form.hightTrellisingHeight'),
    },
    {
      name: 'midTrellisingHeight',
      label: t('block.midTrellisingHeight'),
      rules: [
        {
          min: 1,
        },
      ],
      placeholder: t('block.form.midTrellisingHeight'),
    },
    {
      name: 'lowTrellisingHeight',
      label: t('block.lowTrellisingHeight'),
      rules: [
        {
          min: 1,
        },
      ],
      placeholder: t('block.form.lowTrellisingHeight'),
    },
    {
      name: 'alertTemperatureThresholdLow',
      label: t('block.alertTempThresholdLow'),
      rules: [
        {
          min: 1,
        },
      ],
      placeholder: t('block.form.alertTempThresholdLow'),
    },
    {
      name: 'alertTemperatureThresholdHight',
      label: t('block.alertTempThresoldHigh'),
      rules: [
        {
          min: 1,
        },
      ],
      placeholder: t('block.form.alertTempThresoldHigh'),
    },
    {
      name: 'points',
      label: t('block.coordinates'),
      inputs: [t('block.latitude'), t('block.longitude'), t('block.altitude'), t('block.point')],
      type: 'groupInput',
      noUpdate: !isAGS,
    },
  ];

  const variablesItems = () => variables;

  return (
    <FormTemplate
      className="form"
      nameForm="register"
      useForm={form}
      variables={variablesItems()}
      onSuccess={onSuccess}
      onFinishApiCall={(event) => onFinish(event)}
      onError={onError}
      buttonValidText="form.valid"
      buttonCancelText="form.cancel"
      onCancel={onCancel}
      setSelectedValue={setCurrentCompany}
    />
  );
};

BlockForm.propTypes = {
  update: PropTypes.bool,
  onClose: PropTypes.func,
  block: PropTypes.shape({
    company: PropTypes.string,
    weatherMeasureId: PropTypes.number,
    name: PropTypes.string,
    id: PropTypes.string,
    domainId: PropTypes.string,
    shape: PropTypes.string,
    surface: PropTypes.string,
    rows: PropTypes.number,
    orientation: PropTypes.string,
    varietyId: PropTypes.string,
    pruningMethod: PropTypes.string,
    groundType: PropTypes.string,
    near: PropTypes.string,
    risks: PropTypes.string,
    sentinels: PropTypes.number,
    hightTrellisingHeight: PropTypes.string,
    midTrellisingHeight: PropTypes.string,
    lowTrellisingHeight: PropTypes.string,
    alertTemperatureThresholdLow: PropTypes.string,
    alertTemperatureThresholdHight: PropTypes.string,
    blockCoordinates: PropTypes.arrayOf(PropTypes.shape()),
  }),
};

BlockForm.defaultProps = {
  update: false,
  onClose: () => {},
  block: {},
};

export default BlockForm;
