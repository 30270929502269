import React from 'react';
import { Input } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Password } = Input;

const InputPasswordForm = ({
  prefix, placeholder,
}) => {
  const { t } = useTranslation();
  return (
    <Password
      className="form-input"
      prefix={prefix}
      placeholder={t(placeholder)}
      iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
    />
  );
};

InputPasswordForm.propTypes = {
  prefix: PropTypes.shape().isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default InputPasswordForm;
