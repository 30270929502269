import api from '../../utils/query';

export const getSentPositionsService = (data) => {
    const { blockID, token } = data;
    const url = `block/${blockID}/sentinel-position`
    return api.get({ url, token });
}

export const postPositionService = (data) => {
    const { domainId, blockId, sentinelId} = data;
    const url = `domain/${domainId}/block/${blockId}/sentinel/${sentinelId}/sentinel-position`;
    return api.create(url, data);
}

export const patchSentPositionService = (data) => {
    const { domainId, blockId, sentinelPositionId } = data;
    const url = `domain/${domainId}/block/${blockId}/sentinel-position/${sentinelPositionId}`;
    return api.update(url, data)
}