import React from 'react';
import { PageHeader, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import TaskForm from '../../shared/forms/TaskForm';

const Tasks = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader onBack={() => window.history.back()} title={t('tasks.addTask')} />
      <Divider />
      <TaskForm />
    </>
  );
};

export default Tasks;
