import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import SlideImages from './SlideImages';
import useViewport from '../../utils/hooks/useViewPort';
import { getBreakPoints } from '../../utils/utils';

const ThumbList = ({
  currentSentinelle, cam0f, cam1f, setVisibleModal, setCurrentList, setCurrentImage,
}) => {
  const { loading } = useSelector((state) => state.imagesReducer);
  const { t } = useTranslation();
  const { width } = useViewport();
  const breakpoint = getBreakPoints(width);

  const defaultbreakpointIndex = () => {
    if (breakpoint === 'mobile') {
      return 2;
    }
    if (breakpoint === 'smallDesk') {
      return 3;
    } if (breakpoint === 'mediumDesk') {
      return 5;
    }
    return 6;
  };

  const renderList = (picturesList) => picturesList.map((list, index) => {
    const arrayList = list[Object.keys(list)[0]];

    const defaultIndex = () => {
      if (list) {
        return arrayList?.length - defaultbreakpointIndex();
      }
      return null;
    };
    const slideKey = defaultIndex() + index;

    return (
      <SlideImages
        key={slideKey}
        slideKey={slideKey}
        imagesList={arrayList}
        defaultbreakpointIndex={defaultbreakpointIndex}
        defaultIndex={defaultIndex}
        setCurrentList={setCurrentList}
        setCurrentImage={setCurrentImage}
        setVisibleModal={setVisibleModal}
      />
    );
  });

  const renderThumbList = () => currentSentinelle.map((elt, index) => {
    const uniKey = elt.name + index;
    const picturesList = [];

    picturesList.push(
      {
        [`list${elt?.name}`]: cam0f?.filter((item) => item?.sentId === elt?.name),
        place: '0f',
      },
      {
        [`list${elt?.name}`]: cam1f?.filter((item) => item?.sentId === elt?.name),
        place: '1f',
      },
    );
    return (
      <div key={uniKey} className="slide-article">
        <h2>
          {t('menu.sentinel')}
          {elt?.name}
        </h2>
        { renderList(picturesList) }
      </div>
    );
  });
  return (
    <>
      {loading ? <Spin />
        : currentSentinelle && renderThumbList()}
    </>
  );
};

ThumbList.propTypes = {
  currentSentinelle: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  cam0f: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  cam1f: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setCurrentList: PropTypes.func.isRequired,
  setCurrentImage: PropTypes.func.isRequired,
  setVisibleModal: PropTypes.func.isRequired,
};

export default ThumbList;
