import React, { useEffect, useState } from 'react';
import {
  Divider, Drawer, Tag,
} from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AimOutlined, DiffOutlined } from '@ant-design/icons';
import { formatDate } from 'common/utils/utils';
import TaskForm from '../../shared/forms/TaskForm';
import DashboardList from './DashboardStyle';
import TableTemplate from '../../shared/TableTemplate';

const TasksList = ({ isAGS, isADMIN }) => {
  const [visible, setVisible] = useState(false);
  const [currentTask] = useState();
  const { typeTask } = useSelector((state) => state.typeTaskReducer);
  const { tasks } = useSelector((state) => state.tasksReducer);
  const { blocks } = useSelector((state) => state.blocksReducer);
  const [update, setUpdate] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();

  const filterList = (data) => data?.map((item) => ({
    text: <span>{item.name}</span>, value: item.id,
  }));

  const renderData = () => {
    const data = [];
    if (tasks) {
      tasks?.map((task) => data.push({
        key: task,
        date: task.timestamp,
        typeTask: task.taskType,
        blocks: task.blockIds,
      }));
      setTableData(data);
    }
  };

  useEffect(() => {
    renderData();
  }, [tasks]);

  const onClose = () => {
    setVisible(false);
    setUpdate(false);
  };

  const columns = [
    {
      title: t('module.task.date'),
      dataIndex: 'date',
      key: 'date',
      render: (text) => <span key={text}>{formatDate({ unixDate: text })}</span>,
      responsive: ['md', 'lg'],
      width: 70,
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: t('module.task.type'),
      dataIndex: 'typeTask',
      key: 'typeTask',
      render: (text) => typeTask.map((type) => type.id === text
      && <span key={type.id}>{type.name}</span>),
      responsive: ['md', 'lg'],
      width: 80,
      filters: filterList(typeTask),
      onFilter: (value, record) => (record.typeTask === value),
      filterSearch: true,
    },
    {
      title: t('module.task.block'),
      dataIndex: 'blocks',
      key: 'blocks',
      render: (text) => text && text.map((item) => blocks.map((block) => {
        if (block.id === item) {
          return (
            <Tag key={block.id} icon={<AimOutlined />} color="#8ADFC1">{block.name}</Tag>
          );
        }
        return null;
      })),
      responsive: ['md', 'lg'],
      width: 80,
      filters: filterList(blocks),
      onFilter: (value, record) => (record.blockIds.includes(value)),
      filterSearch: true,
    },
  ];

  return (
    <>
      <DashboardList width="500">
        <div className="header-list">
          <h3>{t('module.task.list')}</h3>
          {(isAGS || isADMIN) && (
          <DiffOutlined onClick={() => setVisible(!visible)} />
          )}
        </div>
        <Divider />
        <TableTemplate columns={columns} data={tableData} />
        <Divider />
        <Drawer
          title={update ? t('tasks.modify') : t('tasks.create')}
          placement="right"
          closable
          onClose={onClose}
          open={visible}
          width={500}
        >
          <TaskForm update={!!update} onClose={onClose} task={currentTask} />
        </Drawer>
      </DashboardList>
    </>
  );
};

TasksList.propTypes = {
  isADMIN: PropTypes.bool.isRequired,
  isAGS: PropTypes.bool.isRequired,
};

export default TasksList;
