import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';
const PATCH = 'PATCH';
const DELETE = 'DELETE';

export const VEHICLE = createRequestTypes('VEHICLE', [FETCH, POST, PATCH, DELETE]);

export const getVehicleAction = {
  request: payload => action(VEHICLE.FETCH.REQUEST, payload),
  success: data => action(VEHICLE.FETCH.SUCCESS, data),
  failure: error => action(VEHICLE.FETCH.FAILURE, error),
};
export const postVehicleAction = {
  request: (payload) => action(VEHICLE.POST.REQUEST, payload),
  success: (data) => action(VEHICLE.POST.SUCCESS, data),
  failure: (error) => action(VEHICLE.POST.FAILURE, error),
};
export const patchVehicleAction = {
  request: (payload) => action(VEHICLE.PATCH.REQUEST, payload),
  success: (data) => action(VEHICLE.PATCH.SUCCESS, data),
  failure: (error) => action(VEHICLE.PATCH.FAILURE, error),
};
export const deleteVehicleAction = {
  request: (id) => action(VEHICLE.DELETE.REQUEST, id),
  success: (data) => action(VEHICLE.DELETE.SUCCESS, data),
  failure: (error) => action(VEHICLE.DELETE.FAILURE, error),
};
