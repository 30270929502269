import api from '../../utils/query';

export const getBlocksService = (data) => {
    const {companyId, currentDomainId, blockId, token} = data;
    const url = currentDomainId ? `${companyId}/domain/${currentDomainId}/block` : companyId ? `${companyId}/domains/blocks` : 'domains/blocks';
    const urlDetails = `${companyId}/domain/${currentDomainId}/block/${blockId}`;
    const getUrl = blockId ? urlDetails : url;
    return api.get({url: getUrl, token});
}

export const getPayloadsBlockService = (data) => {
    const { blockId, startDate, endDate, token } = data
    const url = `block/${blockId}/payload/${startDate}/${endDate}`
    return api.get({url, token});
}

export const postBlockService = (data) => {
    const { event } = data;
    const {companyId, currentDomainId} = event;
    const url = `${companyId}/domains/${currentDomainId}/block`;
    return api.create(url, data);
}

export const patchBlockService = (data) => {
    const {blockId, companyId, domainId} = data;
    const url = `${companyId}/domain/${domainId}/block/${blockId}`;
    return api.update(url, data);
}

export const deleteBlockService = (id) => {
    const url = `blocks/${id}` 
    return api.del(url);
}
