import React from 'react';
import { PropTypes } from 'prop-types';

const Logo = ({ className, src, alt }) => (
  <img className={className} src={src} alt={alt} />
);

Logo.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
};

Logo.defaultProps = {
  className: null,
  src: null,
  alt: null,
};

export default Logo;
