import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';
const PATCH = 'PATCH';
const DELETE = 'DELETE';

export const COMPANY = createRequestTypes('COMPANY', [FETCH, POST, PATCH, DELETE]);
export const CURRENT_COMPANY = createRequestTypes('CURRENT_COMPANY', [FETCH]);

export const getCompaniesAction = {
  request: payload => action(COMPANY.FETCH.REQUEST, payload),
  success: data => action(COMPANY.FETCH.SUCCESS, data),
  failure: error => action(COMPANY.FETCH.FAILURE, error),
};
export const getCurrentCompanyAction = {
  request: payload => action(CURRENT_COMPANY.FETCH.REQUEST, payload),
  success: data => action(CURRENT_COMPANY.FETCH.SUCCESS, data),
  failure: error => action(CURRENT_COMPANY.FETCH.FAILURE, error),
};

export const postCompanyAction = {
  request: (payload) => action(COMPANY.POST.REQUEST, payload),
  success: (data) => action(COMPANY.POST.SUCCESS, data),
  failure: (error) => action(COMPANY.POST.FAILURE, error),
};
export const patchCompanyAction = {
  request: (payload) => action(COMPANY.PATCH.REQUEST, payload),
  success: (data) => action(COMPANY.PATCH.SUCCESS, data),
  failure: (error) => action(COMPANY.PATCH.FAILURE, error),
};
export const deleteCompanyAction = {
  request: (id) => action(COMPANY.DELETE.REQUEST, id),
  success: (data) => action(COMPANY.DELETE.SUCCESS, data),
  failure: (error) => action(COMPANY.DELETE.FAILURE, error),
};
