import React, { useEffect, useContext, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { getDomainsAction } from 'common/business/actions/domainsAction';
import { getBlocksAction, getCurrentBlockAction } from 'common/business/actions/blocksAction';
import { getSentinellesAction } from 'common/business/actions/sentinellesAction';
import { getProductsAction } from 'common/business/actions/productsAction';
import { getServiceProviderAction } from 'common/business/actions/serviceProviderAction';
import { getTypeTasksAction } from 'common/business/actions/typeTaskAction';
import { getTasksAction } from 'common/business/actions/tasksAction';
// import { getLastImageAction } from 'common/business/actions/imagesAction';
// import { getTracesAction } from 'common/business/actions/tracesAction';
// import { getTypeTasksAction } from 'common/business/actions/typeTaskAction';
import { getVehicleAction } from 'common/business/actions/vehiclesAction';
import { getCompaniesAction } from 'common/business/actions/companyAction';
import { getUserAction } from 'common/business/actions/usersAction';
import { getWindAction, getWindSerialsAction } from 'common/business/actions/windsAction';
import { getSentPositionsAction } from 'common/business/actions/sentPositionsAction';
import { getWarningAction } from 'common/business/actions/warningAction';
import { SUPER_ADMIN } from 'common/utils/utils';
import { getPicassoGlobalAction } from 'common/business/actions/picassoAction';
import BlocksList from './BlocksList';
import VehiclesList from './VehiclesList';
import SentinellesList from './SentinellesList';
import ProductsList from './ProductsList';
import TasksList from './TasksList';
import { AuthContext } from '../../utils/context/authContext';
import ServiceProvidersList from './ServiceProvidersList';
import CompaniesList from './CompaniesList';
import { AGS, ALL_ADMIN } from '../../utils/utils';
import UsersList from './UsersList';
import WindsList from './WindsList';
import SentinelPositionList from './SentinelPositionList';
import WarningList from './WarningList';
import InfoNotification from './InfoNotification';
import GlobalMap from './GlobalMap';
// import LastImage from './LastImage';
// import AddTrace from './AddTrace';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { currentDomain } = useSelector((state) => state.domainsReducer);
  const { blocks } = useSelector((state) => state.blocksReducer);
  const { userInfos } = useSelector((state) => state.usersReducer);
  const { warning } = useSelector((state) => state.warningReducer);
  const { currentCompany } = useSelector((state) => state.companiesReducer);
  const { winds } = useSelector((state) => state.windsReducer);
  const { picassoGlobal } = useSelector((state) => state.picassoReducer);
  const { company, roleCode } = userInfos;
  const authCtx = useContext(AuthContext);
  const { token } = authCtx;
  const TOKEN = process.env.REACT_APP_API_AEOLUS_TOKEN;
  const isAGS = AGS.includes(roleCode);
  const isADMIN = ALL_ADMIN.includes(roleCode);

  useLayoutEffect(() => {
    if (isAGS) {
      dispatch(getSentinellesAction.request({ token }));
      dispatch(getBlocksAction.request({ token }));
    }
  }, []);

  useEffect(() => {
    if (!isAGS) {
      dispatch(getBlocksAction.request(
        {
          companyId: company,
          currentDomainId: currentDomain?.id,
          token,
        },
      ));
      dispatch(getWindSerialsAction.request({ companyId: company, token: TOKEN }));
      dispatch(getWindAction.request({ companyId: company, token: TOKEN }));
      if (currentDomain) {
        dispatch(getWarningAction.request({
          token,
          companyId: currentDomain.companyId,
          currentDomainId: currentDomain.id,
        }));
      }
    }
    if (isAGS) {
      dispatch(getCompaniesAction.request({ token }));
      dispatch(getProductsAction.request({ token }));
      dispatch(getWindAction.request({ token: TOKEN }));
      if (currentCompany) {
        dispatch(getWindAction.request({ companyId: currentCompany, token: TOKEN }));
      } else {
        dispatch(getWindSerialsAction.request({ token: TOKEN }));
      }
      if (currentDomain) {
        dispatch(getBlocksAction.request(
          {
            companyId: currentDomain?.companyId,
            currentDomainId: currentDomain?.id,
            token,
          },
        ));
      }
    }
    if (isADMIN) {
      dispatch(getUserAction.request({ token, companyId: company, domainId: currentDomain.id }));
    }
    dispatch(getVehicleAction.request({ token, companyId: company }));

    // dispatch(getSentinellesAction.request());
    // dispatch(getLastImageAction.request());
    dispatch(getTypeTasksAction.request({ token }));
    dispatch(getTasksAction.request({
      token,
      domainId: currentDomain?.id,
      companyId: company,
    }));
    // dispatch(getTracesAction.request());
  }, []);

  useEffect(() => {
    if (blocks) {
      dispatch(getSentPositionsAction.request({ blocks, token }));
    }
    if (blocks && currentDomain) {
      dispatch(getSentinellesAction.request({
        companyId: company,
        currentDomainId: currentDomain?.id,
        token,
      }));
      dispatch(getCurrentBlockAction.request(blocks[0]));
      dispatch(getServiceProviderAction.request({
        token,
        currentDomainId: currentDomain?.id,
        companyId: company,
      }));
    }
    if (blocks && !currentDomain && !isAGS) {
      dispatch(getSentinellesAction.request({
        companyId: company,
        token,
      }));
    }
    if (currentDomain) {
      dispatch(getPicassoGlobalAction.request({
        domainId: currentDomain?.id, date: Date.now(), token,
      }));
    }
  }, [blocks, currentDomain]);

  return (
    <>
      <div className="container">
        <InfoNotification />
        {picassoGlobal && currentDomain && <GlobalMap />}
        {isAGS && (
          <>
            {!currentDomain && <CompaniesList />}
          </>
        )}
        {(isAGS || roleCode === SUPER_ADMIN)
          && (
            <UsersList isAGS={isAGS} />
          )}
        {!isAGS && warning && (
        <WarningList />
        )}
        <SentinelPositionList isAGS={isAGS} isADMIN={isADMIN} />
        {blocks && <BlocksList isAGS={isAGS} isADMIN={isADMIN} />}
        {winds && <WindsList roleCode={roleCode} isAGS={isAGS} isADMIN={isADMIN} />}
        <SentinellesList />
        {currentDomain && <TasksList isAGS={isAGS} isADMIN={isADMIN} />}
        <ProductsList isAGS={isAGS} isADMIN={isADMIN} />
        {currentDomain && <VehiclesList />}
        {currentDomain && <ServiceProvidersList />}
        {/* <LastImage />
        <AddTrace /> */}
      </div>
    </>
  );
};

export default Dashboard;
