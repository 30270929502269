import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';
const PATCH = 'PATCH';
const DELETE = 'DELETE';

export const SENTINELLES = createRequestTypes('SENTINELLES', [FETCH, POST, PATCH, DELETE]);
export const POSITION = createRequestTypes('POSITION', [POST]);
export const CURRENTSENT = createRequestTypes('CURRENTSENT', [FETCH]);
export const SENTBYBLOCK = createRequestTypes('SENTBYBLOCK', [FETCH]);

export const getSentinellesAction = {
  request: payload => action(SENTINELLES.FETCH.REQUEST, payload),
  success: data => action(SENTINELLES.FETCH.SUCCESS, data),
  failure: error => action(SENTINELLES.FETCH.FAILURE, error),
};
export const getCurrentSentAction = {
  request: payload => action(CURRENTSENT.FETCH.REQUEST, payload),
  success: data => action(CURRENTSENT.FETCH.SUCCESS, data),
  failure: error => action(CURRENTSENT.FETCH.FAILURE, error),
}
export const getSentinellesByBlockAction = {
  request: payload => action(SENTBYBLOCK.FETCH.REQUEST, payload),
  success: data => action(SENTBYBLOCK.FETCH.SUCCESS, data),
  failure: error => action(SENTBYBLOCK.FETCH.FAILURE, error),
}
export const postSentinelleAction = {
  request: (payload) => action(SENTINELLES.POST.REQUEST, payload),
  success: (data) => action(SENTINELLES.POST.SUCCESS, data),
  failure: (error) => action(SENTINELLES.POST.FAILURE, error),
};
export const postPositionAction = {
  request: (payload) => action(POSITION.POST.REQUEST, payload),
  success: (data) => action(POSITION.POST.SUCCESS, data),
  failure: (error) => action(POSITION.POST.FAILURE, error),
};
export const patchSentinelleAction = {
  request: (payload) => action(SENTINELLES.PATCH.REQUEST, payload),
  success: (data) => action(SENTINELLES.PATCH.SUCCESS, data),
  failure: (error) => action(SENTINELLES.PATCH.FAILURE, error),
};
export const deleteSentinelleAction = {
  request: (id) => action(SENTINELLES.DELETE.REQUEST, id),
  success: (data) => action(SENTINELLES.DELETE.SUCCESS, data),
  failure: (error) => action(SENTINELLES.DELETE.FAILURE, error),
};
