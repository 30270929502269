import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Layout, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import logoSent from 'common/assets/images/SENTINELLE-VERT.png';
import logoAlcom from 'common/assets/images/ALCOM.png';
import Logo from '../../shared/Logo';
import SignIn from './SignIn';

const Home = (props) => {
  const { isLogged } = props;
  const [isSignin, setIsSignin] = useState(false);
  const { t } = useTranslation();

  const handleClick = (event) => {
    event.preventDefault();
    setIsSignin(true);
  };

  return (
    <Layout className="home-bg">
      <Logo className="logo-img" src={logoSent} alt="sentinelle" />
      {!isSignin
        ? (
          <article className="align-center">
            <p className="grey-text large-font-text">{t('home.msg')}</p>
            {isLogged
              ? <Link to="/dashboard">Accéder au tableau de bord</Link>
              : <Button className="button-link" type="primary" onClick={(event) => handleClick(event)}>Connectez-vous</Button>}
          </article>
        )
        : <SignIn />}
      <div className="footer-logo">
        <Logo src={logoAlcom} alt="Alcom technologies" />
      </div>
    </Layout>
  );
};

Home.propTypes = {
  isLogged: PropTypes.bool,
};

Home.defaultProps = {
  isLogged: null,
};

export default Home;
