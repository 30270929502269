import { DiffOutlined, EditOutlined } from '@ant-design/icons';
import {
  Divider, Drawer, Space,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PositionForm from '../../shared/forms/PositionForm';
import TableTemplate from '../../shared/TableTemplate';
import DashboardList from './DashboardStyle';

const SentinelPositionList = ({ isAGS, isADMIN }) => {
  const [visible, setVisible] = useState(false);
  const [currentPosition, setCurrentPosition] = useState();
  const [update, setUpdate] = useState(false);
  const { sentPositions } = useSelector((state) => state.sentPositionsReducer);
  const { blocks } = useSelector((state) => state.blocksReducer);
  const { domains } = useSelector((state) => state.domainsReducer);

  const { t } = useTranslation();

  const renderBlockName = (text) => {
    const currentBlock = blocks?.filter((block) => block.id === text);
    return <span>{currentBlock[0]?.name}</span>;
  };

  const renderCurrentSentinelPositionInfos = (record) => {
    const currentBlock = blocks.filter((block) => block.id === record.blockId);
    const currentDomain = domains.filter((domain) => domain.id === currentBlock[0].domainId);
    const infos = {
      blockId: currentBlock[0].id,
      domainId: currentDomain[0].id,
      companyId: currentDomain[0].companyId,
      ...record,
    };
    setCurrentPosition(infos);
  };

  const onClose = () => {
    setVisible(false);
    setUpdate(false);
  };

  const sentinelPositionData = sentPositions?.map((position) => ({
    key: position.id,
    name: position.name,
    blockId: position.blockId,
    sentinel: position.sentinel,
    latitude: position.latitude,
    longitude: position.longitude,
    altitude: position.altitude,
  }));

  const columns = [
    {
      title: t('module.sentinel.title'),
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      width: 70,
      hidden: false,
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: t('module.block.title'),
      dataIndex: 'blockId',
      key: 'blockId',
      render: (text) => renderBlockName(text),
      responsive: ['md', 'lg'],
      width: 90,
      hidden: false,
    },
    {
      title: t('module.actions'),
      dataIndex: t('module.actions'),
      key: t('module.actions'),
      align: 'right',
      hidden: !(isAGS || isADMIN),
      render: (text, record) => (
        <Space size="middle">
          <EditOutlined
            className="iconAction"
            onClick={() => {
              setVisible(!visible);
              renderCurrentSentinelPositionInfos(record);
              setUpdate(true);
            }}
          />
        </Space>
      ),
      responsive: ['md', 'lg'],
      width: 50,
    },
  ];

  const renderColumns = columns.filter((col) => col.hidden === false);

  return (
    <>
      <DashboardList width="900">
        <div className="header-list">
          <h3>{t('module.sentinel.position')}</h3>
          {(isAGS || isADMIN) && (
          <DiffOutlined onClick={() => setVisible(!visible)} />
          )}
        </div>
        <Divider />
        <TableTemplate columns={renderColumns} data={sentinelPositionData || []} />
      </DashboardList>
      <Drawer
        title={update ? t('module.sentinel.update') : t('module.sentinel.create')}
        placement="right"
        closable
        onClose={onClose}
        open={visible}
        width={500}
      >
        <PositionForm
          update={!!update}
          onClose={onClose}
          currentPosition={currentPosition}
        />
      </Drawer>
    </>
  );
};

SentinelPositionList.propTypes = {
  isADMIN: PropTypes.bool.isRequired,
  isAGS: PropTypes.bool.isRequired,
};

export default SentinelPositionList;
