import { PICASSO, PICASSOGLOBAL } from '../actions/picassoAction';

const INITIAL_STATE = { picasso: null, picassoGlobal: null,loading: false };

const picassoReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case PICASSO.FETCH.REQUEST:
      return {...state, loading: true};
    case PICASSO.FETCH.SUCCESS:
        return {...state, picasso: payload, loading: false}; // si trop lourd retirer du store le geoJSON (payload)
    case PICASSO.FETCH.FAILURE:
        return {...state, picasso: null, loading: false};
    case PICASSOGLOBAL.FETCH.REQUEST:
          return {...state, loading: true};
    case PICASSOGLOBAL.FETCH.SUCCESS:
          return {...state, picassoGlobal: payload, loading: false}; 
    case PICASSOGLOBAL.FETCH.FAILURE:
          return {...state, picassoGlobal: null, loading: false};
    default:
      return state;
  }
};

export default picassoReducer;
