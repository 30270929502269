import { put, takeEvery, call } from 'redux-saga/effects';
import { getCurrentCompanyAction } from '../actions/companyAction';
import { getDomainsAction } from '../actions/domainsAction';
import { getLoginAction } from '../actions/loginAction';
import { getUserAction, USER, postUserAction, deleteUserAction, patchUserAction, getUserInfosAction, USERINFOS } from '../actions/usersAction';
import { deleteUsersService, getUserInfosService, getUsersService, patchUserService, postUsersService } from '../api/usersService';

export function* getUser({ payload }) {
  const { id, source } = payload;
  try {
    const users = yield call(getUsersService, payload)
    yield put(getUserAction.success(users.data));
  } catch (error) {
    yield put(getUserAction.failure(error));
  }
}

export function* getUserInfos({ payload }) {
  try {
    const userInfos = yield call(getUserInfosService, payload);
    const { roleCode, domains, company } = userInfos.data;

    if (roleCode === 'USER' || roleCode === 'CONSULTANT' || roleCode === 'ADMIN') {
      yield put (getDomainsAction.success(domains))
      yield put (getCurrentCompanyAction.success(company));
    }
    yield put(getUserInfosAction.success(userInfos.data))
  }
  catch (err) {
    yield put(getUserInfosAction.failure(err));
  }
}

export function* postUser({ payload }) {
  const { token, companyId } = payload
  try {
    yield call(postUsersService, payload);
    const users = yield call(getUsersService, { token, companyId });
    yield put(getUserAction.success(users.data))
    yield put(postUserAction.success("user registered"));
  } catch (error) {
    yield put(postUserAction.failure(error));
  }
}

export function* patchUser({ payload }) {
  const { companyId, token } = payload;
  try {
    yield call(patchUserService, payload);
    yield put(patchUserAction.success('user updated'));
   const users = yield call(getUsersService, {token: token, companyId: companyId})
   yield put(getUserAction.success(users.data));
  } catch (err) {
    yield put(patchUserAction.failure(err));
  }
}

export function* deleteUser({ payload }) {
  const { companyId, token } = payload;
  try {
    yield call(deleteUsersService, payload);
    yield put(deleteUserAction.success('user has just been deleted'))
    yield put(getUserAction.request({token: token, companyId: companyId}));
  }
  catch (err) {
    yield put(deleteUserAction.failure(err));
  }
}

export const sagas = {
  deleteUserSaga: takeEvery(USER.DELETE.REQUEST, deleteUser),
  patchUserSaga: takeEvery(USER.PATCH.REQUEST, patchUser),
  postUserSaga: takeEvery(USER.POST.REQUEST, postUser),
  getUserSaga: takeEvery(USER.FETCH.REQUEST, getUser),
  getUserInfoSaga: takeEvery(USERINFOS.FETCH.REQUEST, getUserInfos),
}
