import { useTranslation } from 'react-i18next';

const EmptyData = () => {
  const { t } = useTranslation();

  return (
    <article>
      <h1>
        {t('mapBoxView.emptyData')}
      </h1>
    </article>

  );
};

export default EmptyData;
