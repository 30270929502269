import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'common/assets/style/webApp.scss';
import { Provider } from 'react-redux';
import { store } from 'common/business/store';
import PublicRoute from './shared/PublicRoute';
import Home from './components/home';
import SignIn from './components/home/SignIn';
import Traces from './components/traces/Index';
import Settings from './components/settings/Index';
import Domains from './components/domains/Index';
import Alerts from './components/alerts/Index';
import Vignes from './components/vigne/Index';
import Product from './components/settings/Products';
import Vehicle from './components/settings/Vehicles';
import PrivateRoute from './shared/PrivateRoute';
import Dashboard from './components/dashboard/Index';
import IntlProviderWrapper from './utils/context/intlContext';
import UserSettings from './components/settings/UserSettings';
import Winds from './components/winds';
import TracesSettings from './components/settings/TracesSettings';
import Gallery from './components/gallery/index';
import AuthContextProvider from './utils/context/authContext';
import Callback from './components/home/Callback';
import DomainSettings from './components/settings/DomainSettings';
import {
  AGS_ADMIN, AGS_USER, SUPER_ADMIN, USER, CONSULTANT, ADMIN,
} from './utils/utils';
import CompanySettings from './components/settings/CompanySettings';
import BlockSettings from './components/settings/BlockSettings';
import ServiceProvider from './components/settings/ServiceProvider';
import TasksSettings from './components/settings/Tasks';
import SentinelSettings from './components/settings/SentinelSettings';
import ErrorComponent from './shared/ErrorComponent';
import PositionSettings from './components/settings/PositionSettings';
import WindSettings from './components/settings/WindSettings';
import Statistics from './components/statistics';

const ALL = [AGS_ADMIN, AGS_USER, SUPER_ADMIN, USER, CONSULTANT, ADMIN];

const App = () => (
  <Router>
    <Switch>
      <Route path="/token" component={Callback} />
      <PrivateRoute restricted permission={ALL} exact path="/domains" component={Domains} />
      <PrivateRoute restricted permission={ALL} exact path="/dashboard" component={Dashboard} />
      <PrivateRoute restricted permission={ALL} exact path="/traces" component={Traces} />
      <PrivateRoute restricted permission={ALL} exact path="/settings" component={Settings} />
      <PrivateRoute restricted permission={ALL} exact path="/alerts" component={Alerts} />
      <PrivateRoute restricted permission={ALL} exact path="/vignes" component={Vignes} />
      <PrivateRoute restricted permission={ALL} exact path="/statistics" component={Statistics} />
      <PrivateRoute restricted permission={ALL} exact path="/gallery" component={Gallery} />
      <PrivateRoute restricted permission={ALL} exact path="/winds" component={Winds} />
      <PrivateRoute restricted permission={[AGS_ADMIN, AGS_USER, SUPER_ADMIN, ADMIN]} exact path="/tasksSettings" component={TasksSettings} />
      <PrivateRoute restricted permission={ALL} exact path="/tracesSettings" component={TracesSettings} />
      <PrivateRoute restricted permission={[AGS_ADMIN, AGS_USER]} exact path="/productsSettings" component={Product} />
      <PrivateRoute restricted permission={[AGS_ADMIN, AGS_USER]} exact path="/vehiclesSettings" component={Vehicle} />
      <PrivateRoute restricted permission={[AGS_ADMIN, AGS_USER]} exact path="/serviceProvider" component={ServiceProvider} />
      <PrivateRoute restricted permission={[AGS_ADMIN, AGS_USER, SUPER_ADMIN]} exact path="/usersSettings" component={UserSettings} />
      <PrivateRoute restricted permission={[AGS_ADMIN, AGS_USER, SUPER_ADMIN]} exact path="/companySettings" component={CompanySettings} />
      <PrivateRoute restricted permission={[AGS_ADMIN, AGS_USER, SUPER_ADMIN]} exact path="/blockSettings" component={BlockSettings} />
      <PrivateRoute restricted permission={[AGS_ADMIN, AGS_USER, SUPER_ADMIN]} exact path="/domainSettings" component={DomainSettings} />
      <PrivateRoute restricted permission={[AGS_ADMIN, AGS_USER]} exact path="/sentinelSettings" component={SentinelSettings} />
      <PrivateRoute restricted permission={[AGS_ADMIN, AGS_USER]} exact path="/windSettings" component={WindSettings} />
      <PrivateRoute restricted permission={[AGS_ADMIN, AGS_USER]} exact path="/positionSettings" component={PositionSettings} />
      <PublicRoute exact path="/signin" component={SignIn} />
      <PublicRoute exact path="/error" component={ErrorComponent} />
      <PublicRoute exact path="/signin" component={SignIn} />
      <PublicRoute exact path="/" component={Home} />
    </Switch>
  </Router>
);

const AppWrapper = () => (
  <Suspense fallback="loading">
    <Provider store={store}>
      <AuthContextProvider>
        <IntlProviderWrapper>
          <App />
        </IntlProviderWrapper>
      </AuthContextProvider>
    </Provider>
  </Suspense>
);

export default AppWrapper;
