import { Card, Space, Tabs } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const InfoNotification = () => {
  const [itemKey, setItemKey] = useState('1');
  const { t } = useTranslation();
  const onChangeTabs = (key) => {
    setItemKey(key);
  };
  const renderAlertContent = () => (
    <>
      <h3>{t('infoCard.alert.title')}</h3>
      <p>{t('infoCard.alert.p1')}</p>
      <p>{t('infoCard.alert.p2')}</p>
      <p>{t('infoCard.alert.p3')}</p>
      <p>{t('infoCard.alert.p4')}</p>
      <p>{t('infoCard.alert.p5')}</p>
    </>
  );
  const itemsTabs = [
    {
      key: '1',
      label: 'Alertes',
      children: renderAlertContent(),
    },
    {
      key: '2',
      label: 'Positions',
      children: t('infoCard.pos'),
    },
    {
      key: '3',
      label: 'Parcelles',
      children: t('infoCard.blocks'),
    },
    {
      key: '4',
      label: 'Sentinelles',
      children: t('infoCard.sent'),
    },
    {
      key: '5',
      label: 'Travaux',
      children: t('infoCard.task'),
    },
    {
      key: '6',
      label: 'Carte',
      children: t('infoCard.map'),
    },
  ];
  return (
    <Space direction="horizontal" size={32}>
      <Card title="Informations sur les éléments du tableau de bord" style={{ width: 750 }}>
        <Tabs defaultActiveKey={itemKey} items={itemsTabs} tabPosition="left" onChange={onChangeTabs} />
      </Card>
    </Space>
  );
};

export default InfoNotification;
