import { CAM0F, IMG, CAM1F, CAM0W, CAM1W, LASTIMG } from '../actions/imagesAction';

const INITIAL_STATE = { images: null, cam0f: [], cam1f: [], cam0w: [], cam1w: [], loading: false };

const imagesReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case IMG.FETCH.REQUEST:
      return {...state, loading: true};
    case IMG.FETCH.SUCCESS:
        return {...state, images: payload, loading: false};
    case IMG.FETCH.FAILURE:
        return {...state, loading: false};  
    case CAM0F.FETCH.SUCCESS:
        return {...state, cam0f: payload};
    case CAM0F.FETCH.FAILURE:
        return {...state, cam0f: payload};
    case CAM1F.FETCH.SUCCESS:
        return {...state, cam1f: payload}; 
    case CAM1F.FETCH.FAILURE:
        return {...state, cam1f: payload};
    case CAM0W.FETCH.SUCCESS:
        return {...state, cam0w: payload};
    case CAM0W.FETCH.FAILURE:
        return {...state, cam0w: payload};
    case CAM1W.FETCH.SUCCESS:
        return {...state, cam1w: payload}; 
    case CAM1W.FETCH.FAILURE:
        return {...state, cam1w: payload};
    case LASTIMG.FETCH.REQUEST:
        return {...state, loading: true};
    case LASTIMG.FETCH.SUCCESS:
        return {...state, images: payload, loading: false};
    case LASTIMG.FETCH.FAILURE:
        return {...state, loading: false};
    default:
      return state;
  }
};

export default imagesReducer;
