import { Divider, PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import PositionForm from '../../shared/forms/PositionForm';

const PositionSettings = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader onBack={() => window.history.back()} title={t('settings.addPos')} />
      <Divider />
      <PositionForm />
    </>
  );
};

export default PositionSettings;
