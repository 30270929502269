import { put, takeEvery, call, all } from 'redux-saga/effects';
import { getSentPositionsToGeojsonAction, GEOSENTPOSITIONS, patchSentPositionsAction, SENTPOSITIONS, postSentPositionsAction, getSentPositionsAction } from '../actions/sentPositionsAction';
import { getSentPositionsService, patchSentPositionService, postPositionService } from '../api/sentPositionsService';

export const createGeoJson = (block, sentPositions) => {
  if (!sentPositions) return {};
  const features = sentPositions.map(sentPosition => {
      const properties = {
        "sent_loc": sentPosition.name,
        "block_name": block.name,
        "block_id": block.id,
        "sent_id": sentPosition.sentinel.serial
      };
      let geometry = {
        "type": "Point",
        "coordinates": [sentPosition.longitude, sentPosition.latitude]
      };
  
      return {
        "type": "Feature",
        "properties": properties,
        "geometry": geometry
      };
    });
  
    return {
      "type": "FeatureCollection",
      "features": features
    };
  }

  export function* getAllPositionsByBlock2(payload) {
    const { block, token } = payload;
    const test = yield call(getSentPositionsService, {blockID: block.id, token});
    return test.data;
  }

  export function* getSentPosition({ payload }) {
    const { blocks, token } = payload;
    try {
      const posArray= [];
      const positions = yield all(blocks.map((block) => getAllPositionsByBlock2({block, token})));
      positions.forEach(element => {
        element.forEach((pos) => posArray.push(pos))
      });        
      yield put(getSentPositionsAction.success(posArray))

    }
    catch (err) {
      yield put(getSentPositionsAction.failure(err));
    }
  }

export function* getSentPositionsToGeojson({ payload }) {
    const { blocks, blockID, token } = payload
    const filteredBlocks = blocks.filter(block => block.id === blockID);
    const blockData = filteredBlocks[0];
    try {
        const data = { blockID: blockData.id, token};
        const sentPositions = yield call(getSentPositionsService, data);  
        const geoJson = createGeoJson(blockData, sentPositions.data)
        yield put(getSentPositionsToGeojsonAction.success(geoJson))
    }
    catch (err) {
        yield put(getSentPositionsToGeojsonAction.failure(err))
    }
};

export function* postPosition({ payload }) {
  try {
    yield call(postPositionService, payload);
    yield put(postSentPositionsAction.success("Position registered"));
  } catch (error) {
    yield put(postSentPositionsAction.failure(error));
  }
}

export function* patchSentPositions({ payload }) {
  const { token, blocks } = payload;
  try {
    yield call(patchSentPositionService, payload);
    yield put(patchSentPositionsAction.success('position updated'));
    yield put(getSentPositionsAction.request({ blocks, token }))

  }
  catch (err) {
    yield put(patchSentPositionsAction.failure(err));
  }
}

export const sagas = {
  getSentPositionsToGeojsonSaga: takeEvery(GEOSENTPOSITIONS.FETCH.REQUEST, getSentPositionsToGeojson),
  patchSentPositionsSaga: takeEvery(SENTPOSITIONS.PATCH.REQUEST, patchSentPositions),
  postPositionSaga: takeEvery(SENTPOSITIONS.POST.REQUEST, postPosition),
  getSentPositionSaga: takeEvery(SENTPOSITIONS.FETCH.REQUEST, getSentPosition),
}