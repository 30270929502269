import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';

import fr from 'common/lang/fr.json';
import en from 'common/lang/en.json';

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    lng: 'fr',
    resources: {
      fr,
      en,
    },
  });

export default i18n;
