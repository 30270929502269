import { Divider, PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import BlockForm from '../../shared/forms/BlockForm';

const BlockSettings = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader onBack={() => window.history.back()} title={t('settings.addBlock')} />
      <Divider />
      <BlockForm />
    </>
  );
};

export default BlockSettings;
