import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';
const PATCH = 'PATCH';
const POST = 'POST';

export const SENTPOSITIONS = createRequestTypes('SENTPOSITIONS', [FETCH, PATCH, POST]);
export const GEOSENTPOSITIONS = createRequestTypes('GEOSENTPOSITIONS', [FETCH]);

export const getSentPositionsAction = {
  request: payload => action(SENTPOSITIONS.FETCH.REQUEST, payload),
  success: data => action(SENTPOSITIONS.FETCH.SUCCESS, data),
  failure: error => action(SENTPOSITIONS.FETCH.FAILURE, error),
};
export const patchSentPositionsAction = {
  request: payload => action(SENTPOSITIONS.PATCH.REQUEST, payload),
  success: data => action(SENTPOSITIONS.PATCH.SUCCESS, data),
  failure: error => action(SENTPOSITIONS.PATCH.FAILURE, error),
};
export const postSentPositionsAction = {
  request: payload => action(SENTPOSITIONS.POST.REQUEST, payload),
  success: data => action(SENTPOSITIONS.POST.SUCCESS, data),
  failure: error => action(SENTPOSITIONS.POST.FAILURE, error),
};
export const getSentPositionsToGeojsonAction = {
  request: payload => action(GEOSENTPOSITIONS.FETCH.REQUEST, payload),
  success: data => action(GEOSENTPOSITIONS.FETCH.SUCCESS, data),
  failure: error => action(GEOSENTPOSITIONS.FETCH.FAILURE, error),
};
