import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import Chart from './Chart';
import ObservationList from './ObservationList';
import BlockCharts from './StatisticStyle';

const ObservationStatistic = ({
  handleSwitchFormAndObservations,
  extractMethod,
  blockNameValue,
  domainNameValue,
  startDateValue,
  endDateValue,
}) => {
  const { payloads: payloadsBlock } = useSelector((state) => state.blocksReducer);
  const { payloads: payloadsDomain } = useSelector((state) => state.domainsReducer);
  const { t } = useTranslation();

  return (
    <>
      <Button onClick={handleSwitchFormAndObservations} className="btn-back">
        <ArrowLeftOutlined />
        {t('module.statistics.backToForm')}
      </Button>
      <section className="container">
        <ObservationList
          extractMethod={extractMethod}
          startDateValue={startDateValue}
          endDateValue={endDateValue}
          blockNameValue={blockNameValue}
          domainNameValue={domainNameValue}
        />
        <BlockCharts>
          <Chart
            data={extractMethod === 'block' ? payloadsBlock : payloadsDomain}
            startDateValue={startDateValue}
            endDateValue={endDateValue}
            typeGraph="temperature"
          />
          <Chart
            data={extractMethod === 'block' ? payloadsBlock : payloadsDomain}
            startDateValue={startDateValue}
            endDateValue={endDateValue}
            typeGraph="sentinelsTemp"
          />
          <Chart
            data={extractMethod === 'block' ? payloadsBlock : payloadsDomain}
            startDateValue={startDateValue}
            endDateValue={endDateValue}
            typeGraph="humidity"
          />
        </BlockCharts>
      </section>
    </>
  );
};

ObservationStatistic.propTypes = {
  handleSwitchFormAndObservations: PropTypes.func.isRequired,
  extractMethod: PropTypes.string.isRequired,
  blockNameValue: PropTypes.string,
  domainNameValue: PropTypes.string,
  startDateValue: PropTypes.number.isRequired,
  endDateValue: PropTypes.number.isRequired,
};

ObservationStatistic.defaultProps = {
  blockNameValue: null,
  domainNameValue: null,
};

export default ObservationStatistic;
