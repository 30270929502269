import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST= "POST"

export const TRACES = createRequestTypes('TRACES', [FETCH, POST]);

export const getTracesAction = {
  request: payload => action(TRACES.FETCH.REQUEST, payload),
  success: data => action(TRACES.FETCH.SUCCESS, data),
  failure: error => action(TRACES.FETCH.FAILURE, error),
};

export const postTracesAction = {
  request: payload => action(TRACES.POST.REQUEST, payload),
  success: data => action(TRACES.POST.SUCCESS, data),
  failure: error => action(TRACES.POST.FAILURE, error),
};
