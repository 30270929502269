import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';
const PATCH = 'PATCH';
const DELETE = 'DELETE';

export const TASKS = createRequestTypes('TASKS', [FETCH, POST, PATCH, DELETE]);

export const getTasksAction = {
  request: payload => action(TASKS.FETCH.REQUEST, payload),
  success: data => action(TASKS.FETCH.SUCCESS, data),
  failure: error => action(TASKS.FETCH.FAILURE, error),
};
export const postTasksAction = {
  request: (payload) => action(TASKS.POST.REQUEST, payload),
  success: (data) => action(TASKS.POST.SUCCESS, data),
  failure: (error) => action(TASKS.POST.FAILURE, error),
};
export const patchTasksAction = {
  request: (payload) => action(TASKS.PATCH.REQUEST, payload),
  success: (data) => action(TASKS.PATCH.SUCCESS, data),
  failure: (error) => action(TASKS.PATCH.FAILURE, error),
};
export const deleteTasksAction = {
  request: (id) => action(TASKS.DELETE.REQUEST, id),
  success: (data) => action(TASKS.DELETE.SUCCESS, data),
  failure: (error) => action(TASKS.DELETE.FAILURE, error),
};
