import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';
const PATCH = 'PATCH';
const DELETE = 'DELETE';

export const PRODUCTS = createRequestTypes('PRODUCTS', [FETCH, POST, PATCH, DELETE]);

export const getProductsAction = {
  request: payload => action(PRODUCTS.FETCH.REQUEST, payload),
  success: data => action(PRODUCTS.FETCH.SUCCESS, data),
  failure: error => action(PRODUCTS.FETCH.FAILURE, error),
};
export const postProductsAction = {
    request: (payload) => action(PRODUCTS.POST.REQUEST, payload),
    success: (data) => action(PRODUCTS.POST.SUCCESS, data),
    failure: (error) => action(PRODUCTS.POST.FAILURE, error),
  };
  export const patchProductsAction = {
    request: (payload) => action(PRODUCTS.PATCH.REQUEST, payload),
    success: (data) => action(PRODUCTS.PATCH.SUCCESS, data),
    failure: (error) => action(PRODUCTS.PATCH.FAILURE, error),
  };
export const deleteProductsAction = {
    request: (id) => action(PRODUCTS.DELETE.REQUEST, id),
    success: (data) => action(PRODUCTS.DELETE.SUCCESS, data),
    failure: (error) => action(PRODUCTS.DELETE.FAILURE, error),
  };