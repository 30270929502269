import React from 'react';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
// import Register from '../users/Register';

const VehiclesList = () => {
  const { vehicle } = useSelector((state) => state.vehicleReducer);

  const RenderList = () => (
    <>
      { vehicle?.map((item) => (
        <li key={item.id}>
          {item.name}
        </li>
      ))}
    </>
  );

  return (
    <article>
      <h3>Liste des véhicules</h3>
      <Divider />
      <ul className="scrollable"><RenderList /></ul>
      <Divider />
    </article>
  );
};

export default VehiclesList;
