import React, { useState } from 'react';
import {
  Button, Divider, Drawer, Popconfirm,
} from 'antd';
import { deleteServiceProviderAction } from 'common/business/actions/serviceProviderAction';
import { useDispatch, useSelector } from 'react-redux';
import ServiceProviderForm from '../../shared/forms/ServiceProviderForm';

const ServiceProvidersList = () => {
  const [visible, setVisible] = useState(false);
  const [currentServiceProvider, setCurrentServiceProvider] = useState();
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch();
  const { serviceProvider } = useSelector((state) => state.serviceProviderReducer);

  const onClose = () => {
    setVisible(false);
    setUpdate(false);
  };

  const handleDelete = (event, id) => {
    event.preventDefault();
    dispatch(deleteServiceProviderAction.request(id));
  };

  const confirm = (event, id) => {
    handleDelete(event, id);
  };

  const RenderList = () => (
    <>
      { serviceProvider?.map((item) => (
        <li key={item.id}>
          {item.name}
          {' '}
          ||
          {' '}
          <Popconfirm
            title="Voulez vous vraiment supprimer cet élément?"
            onConfirm={(event) => confirm(event, item.id)}
          >
            <Button type="primary">delete</Button>
          </Popconfirm>
          <Button
            type="primary"
            onClick={() => {
              setVisible(!visible);
              setUpdate(true);
              setCurrentServiceProvider(item);
            }}
          >
            Modify
          </Button>
        </li>
      ))}
    </>
  );

  return (
    <>
      <article>
        <h3>Liste des Prestataires</h3>
        <Divider />
        <ul className="scrollable"><RenderList /></ul>
        <Divider />
        <Drawer
          title={update ? 'Modifier le prestataire' : 'Créer un prestataire'}
          placement="right"
          closable
          onClose={onClose}
          open={visible}
          width={500}
        >
          <ServiceProviderForm
            update={!!update}
            onClose={onClose}
            serviceProvider={currentServiceProvider}
          />
        </Drawer>
      </article>
    </>
  );
};

export default ServiceProvidersList;
