import { put, takeEvery, call } from 'redux-saga/effects';
import { getVehicleAction, VEHICLE, deleteVehicleAction, postVehicleAction, patchVehicleAction } from '../actions/vehiclesAction';
import { getVehicleService, deleteVehiclesService, postVehiclesService, patchVehiclesService } from '../api/vehiclesService';

export function* getVehicle({payload}) {
  try {
      const vehicle = yield call(getVehicleService, payload);
      yield put(getVehicleAction.success(vehicle.data))
  }
  catch (err) {
      yield put(getVehicleAction.failure(err))
  }
};

export function* postVehicle({ payload }) {
  const { token, companyId } = payload;
  try {
    yield call(postVehiclesService, payload);
    yield put(postVehicleAction.success("vehicle registered"));
    const vehicle = yield call(getVehicleService, {token, companyId});
    yield put(getVehicleAction.success(vehicle.data))
  } catch (error) {
    yield put(postVehicleAction.failure(error));
  }
}

export function* patchVehicle({ payload }) {
  const { token } = payload;
  try {
    yield call(patchVehiclesService, payload);
    yield put(patchVehicleAction.success('vehicle updated'));
    const vehicle = yield call(getVehicleService, {token: token});
    yield put(getVehicleAction.success(vehicle.data))
  } catch (err) {
    yield put(patchVehicleAction.failure(err));
  }
}

export function* deleteVehicle({ payload }) {
  const { token } = payload;
  try {
    yield call(deleteVehiclesService, payload);
    yield put(deleteVehicleAction.success('vehicle deleted'));
    const vehicle = yield call(getVehicleService, {token: token});
    yield put(getVehicleAction.success(vehicle.data));
  }
  catch (err) {
    yield put(deleteVehicleAction.failure(err));
  }
}

export const sagas = {
  getVehicleSaga: takeEvery(VEHICLE.FETCH.REQUEST, getVehicle),
  postVehicleSaga: takeEvery(VEHICLE.POST.REQUEST, postVehicle),
  patchVehicleSaga: takeEvery(VEHICLE.PATCH.REQUEST, patchVehicle),
  deleteVehickeSaga: takeEvery(VEHICLE.DELETE.REQUEST, deleteVehicle),
}
