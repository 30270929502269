import { deleteUserAction, getUserAction } from 'common/business/actions/usersAction';
import React, { useEffect, useState, useContext } from 'react';
import {
  Divider,
  Drawer,
  Popconfirm,
  Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, DiffOutlined, EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import TableTemplate from '../../shared/TableTemplate';
import { AuthContext } from '../../utils/context/authContext';
import UserForm from '../../shared/forms/UserForm';

const UsersList = ({ isAGS }) => {
  const dispatch = useDispatch();
  const { token } = useContext(AuthContext);
  const { users } = useSelector((state) => state.usersReducer);
  const { currentDomain } = useSelector((state) => state.domainsReducer);
  const { currentCompany } = useSelector((state) => state.companiesReducer);
  const [currentUser, setCurrentUser] = useState();
  const [tableData, setTableData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [update, setUpdate] = useState(false);
  const { t } = useTranslation();

  const renderData = () => {
    const data = [];
    users?.map((elt) => data.push({
      key: elt.id,
      name: elt.email,
      firstName: elt.firstName,
      lastName: elt.lastName,
      phone: elt.phone,
      language: elt.language,
    }));
    setTableData(data);
  };
  useEffect(() => {
    if (currentDomain) {
      dispatch(getUserAction.request({
        companyId: currentCompany,
        domainId: currentDomain.id,
        token,
      }));
    } else {
      dispatch(getUserAction.request({ token, companyId: currentCompany }));
    }
  }, [currentDomain]);

  useEffect(() => {
    renderData();
  }, [users]);

  const handleDelete = (event, userId) => {
    event.preventDefault();
    dispatch(deleteUserAction.request({ companyId: currentCompany, currentUserId: userId, token }));
  };
  const confirm = (event, userId) => {
    handleDelete(event, userId);
  };

  const onClose = () => {
    setVisible(false);
    setUpdate(false);
    setCurrentUser(null);
  };

  const columns = [
    {
      title: t('module.user.email'),
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
    },
    {
      title: t('module.user.firstname'),
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
    },
    {
      title: t('module.user.lastname'),
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
    },
    {
      title: t('module.user.phone'),
      dataIndex: 'phone',
      key: 'phone',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
    },
    {
      title: t('module.user.language'),
      dataIndex: 'language',
      key: 'language',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
    },
    // {
    //   title: 'accès',
    //   dataIndex: 'tags',
    //   key: 'tags',
    //   render: (tag) => {
    //     let color = tag.length > 5 ? 'blue' : 'green';
    //     if (tag === 'loser') {
    //       color = 'volcano';
    //     }
    //     return (
    //       <Tag color={color} key={tag}>
    //         {tag.toUppercase()}
    //       </Tag>
    //     );
    //   },
    // },
    {
      title: t('module.actions'),
      dataIndex: t('module.actions'),
      key: t('module.actions'),
      align: 'right',
      render: (text, record) => {
        if (record.key) {
          return (
            <Space size="middle">
              <EditOutlined
                className="iconAction"
                onClick={() => {
                  setVisible(!visible);
                  setCurrentUser(record);
                  setUpdate(true);
                }}
              />
              { isAGS && (
              <Popconfirm
                title={t('module.delete')}
                onConfirm={(event) => confirm(event, record.key)}
              >
                <DeleteOutlined className="iconAction" />
              </Popconfirm>
              )}
            </Space>
          );
        }
        return null;
      },
      responsive: ['md', 'lg'],
      width: 70,
    },
  ];

  return (
    <article className="dashboard-bloc">
      <div className="header-list">
        <h3>{t('module.user.list')}</h3>
        <DiffOutlined onClick={() => setVisible(!visible)} />
      </div>
      <Divider />
      <TableTemplate columns={columns} data={tableData} />
      <Drawer
        title={update ? 'Modifier la task' : 'Créer une task'}
        placement="right"
        closable
        onClose={onClose}
        open={visible}
        width={500}
      >
        <UserForm update={!!update} onClose={onClose} currentUser={currentUser} token={token} />
      </Drawer>
    </article>
  );
};

UsersList.propTypes = {
  isAGS: PropTypes.bool.isRequired,
};

export default UsersList;
