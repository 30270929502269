import { Divider } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'common/utils/utils';
import { CSVLink } from 'react-csv';
import TableTemplate from '../../shared/TableTemplate';

const ObservationList = ({
  extractMethod,
  startDateValue,
  endDateValue,
  blockNameValue,
  domainNameValue,
}) => {
  const { payloads: payloadsBlock } = useSelector((state) => state.blocksReducer);
  const { payloads: payloadsDomain } = useSelector((state) => state.domainsReducer);
  const [payloadsData, setPayloadsData] = useState([]);
  const { t } = useTranslation();

  const getDataPeriodTime = () => {
    const dateRange = `du ${formatDate({ unixDate: startDateValue })} au ${formatDate({ unixDate: endDateValue })}`;
    if (extractMethod === 'block') {
      return `${t('module.statistics.dataForBlock')} "${blockNameValue}" ${dateRange}`;
    }
    return `${t('module.statistics.domainForDomain')} "${domainNameValue}" ${dateRange}`;
  };

  const getCsvFileName = () => {
    const dateRange = `${formatDate({ unixDate: startDateValue })}-au-${formatDate({ unixDate: endDateValue })}`;
    if (extractMethod === 'block') {
      return `${blockNameValue}-${dateRange}.csv`;
    }
    return `${domainNameValue}-${dateRange}.csv`;
  };

  const getData = (payloads) => {
    if (payloads) {
      const data = payloads.map((payload) => {
        const rowData = {
          key: payload.payloadId,
          sentinel: payload.sentinelPositionName,
          date: payload.timestamp,
        };

        payload.sensors.forEach((sensor) => {
          rowData[sensor.type] = sensor.value.toFixed(2);
        });

        return rowData;
      });
      setPayloadsData(data);
    }
  };

  useEffect(() => {
    getData(extractMethod === 'block' ? payloadsBlock && payloadsBlock : payloadsDomain && payloadsDomain);
  }, [extractMethod, startDateValue, endDateValue]);

  const columns = [
    {
      title: t('module.statistics.sentinel'),
      dataIndex: 'sentinel',
      key: 'sentinel',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      align: 'center',
      width: 50,
    },
    {
      title: t('module.statistics.date'),
      dataIndex: 'date',
      key: 'date',
      render: (text) => <span>{formatDate({ unixDate: text }).concat(' à ', formatDate({ unixHour: text }))}</span>,
      responsive: ['md', 'lg'],
      width: 50,
      align: 'center',
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: t('module.statistics.tempSoil'),
      dataIndex: 'soil_temperature',
      key: 'soil_temperature',
      width: 30,
      align: 'center',
      responsive: ['md', 'lg'],
    },
    {
      title: t('module.statistics.humiditySoil'),
      dataIndex: 'soil_relative_humidity',
      key: 'soil_relative_humidity',
      width: 30,
      align: 'center',
      responsive: ['md', 'lg'],
    },
    {
      title: t('module.statistics.tempAir'),
      dataIndex: 'air_temperature',
      key: 'air_temperature',
      width: 30,
      align: 'center',
      responsive: ['md', 'lg'],
    },
    {
      title: t('module.statistics.humidityAir'),
      dataIndex: 'air_relative_humidity',
      key: 'air_relative_humidity',
      width: 30,
      align: 'center',
      responsive: ['md', 'lg'],
    },
    {
      title: t('module.statistics.foliarSensor'),
      children: [
        {
          title: t('module.statistics.foliarTop'),
          dataIndex: 'foliar_top',
          key: 'foliar_top',
          width: 30,
          align: 'center',
          responsive: ['md', 'lg'],
        },
        {
          title: t('module.statistics.foliarBottom'),
          dataIndex: 'foliar_bottom',
          key: 'foliar_bottom',
          width: 30,
          align: 'center',
          responsive: ['md', 'lg'],
        },
      ],
    },
  ];
  const csvData = useMemo(() => [
    ['Sentinelle', 'Date', 'T° sol', 'Humidité du sol', 'T° air', 'Humidité air', 'Capteur foliaire haut', 'Capteur foliaire bas'],
    ...payloadsData.map((payload) => [
      payload.sentinel,
      `${formatDate({ unixDate: payload.date })} à ${formatDate({ unixHour: payload.date })}`,
      payload.soil_temperature, payload.soil_relative_humidity,
      payload.air_temperature, payload.air_relative_humidity, payload.foliar_top,
      payload.foliar_bottom]),
  ], [payloadsData]);

  return (
    <section className="list">
      <div className="list-header">
        <span>
          {getDataPeriodTime()}
        </span>
        <span>
          <CSVLink className="link-csv" data={csvData} filename={getCsvFileName()}>{t('module.statistics.downloadCSV')}</CSVLink>
        </span>
      </div>
      <Divider />
      <TableTemplate columns={columns} data={payloadsData} height={600} />
    </section>
  );
};

ObservationList.propTypes = {
  blockNameValue: PropTypes.string,
  domainNameValue: PropTypes.string,
  extractMethod: PropTypes.string.isRequired,
  startDateValue: PropTypes.number.isRequired,
  endDateValue: PropTypes.number.isRequired,
};

ObservationList.defaultProps = {
  blockNameValue: null,
  domainNameValue: null,
};

export default ObservationList;
