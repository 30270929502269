import { PRODUCTS } from '../actions/productsAction';

const INITIAL_STATE = { products: null, loading: false };

const productsReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case PRODUCTS.FETCH.REQUEST:
      return {...state, loading: true};
    case PRODUCTS.FETCH.SUCCESS:
        return {...state, products: payload, error: null, loading: false};
    case PRODUCTS.FETCH.FAILURE:
        return {...state, products: null, error: payload, loading: false};
    case PRODUCTS.POST.REQUEST:
        return { ...state, loading: true };
    case PRODUCTS.POST.SUCCESS:
        return { ...state, loading: false };
    case PRODUCTS.POST.FAILURE:
        return { ...state, loading: false };
    case PRODUCTS.PATCH.REQUEST:
        return { ...state, loading: true };
    case PRODUCTS.PATCH.SUCCESS:
        return { ...state, loading: false };
    case PRODUCTS.PATCH.FAILURE:
        return { ...state, loading: false };
    case PRODUCTS.DELETE.REQUEST:
        return { ...state, loading: true };
    case PRODUCTS.DELETE.SUCCESS:
        return { ...state, productsdeleted: payload, loading: false};
    case PRODUCTS.DELETE.FAILURE:
        return { ...state, loading: false};
    default:
      return state;
  }
};

export default productsReducer;
