/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form, notification } from 'antd';
import { postTracesAction } from 'common/business/actions/tracesAction';
import { useDispatch, useSelector } from 'react-redux';
import { getTypeTasksAction } from 'common/business/actions/typeTaskAction';
import { PropTypes } from 'prop-types';
import FormTemplate from './formTemplate';

const TracesForm = ({ update }) => {
  const { typeTask } = useSelector((state) => state.typeTaskReducer);
  const { blocks } = useSelector((state) => state.blocksReducer);
  const { traces } = useSelector((state) => state.tracesReducer);
  const { products } = useSelector((state) => state.productsReducer);
  const servicesProvider = useSelector((state) => state.serviceProviderReducer.serviceProvider);
  const vehicles = useSelector((state) => state.vehicleReducer.vehicle);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const lastTrace = traces[0];

  useEffect(() => {
    dispatch(getTypeTasksAction.request({ source: 'web' }));
    const {
      name, blockName, presta, vehicle, product, quantity,
    } = lastTrace;
    if (update) {
      form.setFieldsValue({
        name,
        blockName,
        presta,
        vehicle,
        product,
        quantity,
      });
    } else {
      form.resetFields();
    }
  }, []);

  const renderSelectValues = (typeSelect) => {
    const arrayOptions = [];
    typeSelect?.map((elt) => arrayOptions.push({ value: elt.id, text: elt.name }));
    return arrayOptions;
  };

  const variables = [
    {
      name: 'name',
      label: t('traces.type'),
      type: 'select',
      selectValues: renderSelectValues(typeTask),
      rules: [
        {
          required: true,
        },
      ],
      placeholder: t('traces.type'),
    },
    {
      name: 'blockName',
      label: t('task.landlot'),
      type: 'select',
      selectValues: renderSelectValues(blocks),
      rules: [
        {
          required: true,
        },
      ],
      placeholder: t('traces.landlot'),
    },
    {
      name: 'presta',
      label: t('traces.presta'),
      type: 'select',
      selectValues: renderSelectValues(servicesProvider),
      rules: [
        {
          required: true,
        },
      ],
      placeholder: t('traces.presta'),
    },
    {
      name: 'vehicle',
      label: t('traces.vehicle'),
      type: 'select',
      selectValues: renderSelectValues(vehicles),
      rules: [
        {
          required: true,
        },
      ],
      placeholder: t('traces.vehicle'),
    },
    {
      name: 'product',
      label: t('traces.product'),
      type: 'select',
      selectValues: renderSelectValues(products),
      rules: [
        {
          required: true,
        },
      ],
      placeholder: t('traces.product'),
    },
    {
      name: 'quantity',
      label: t('traces.quantity'),
      rules: [
        {
          required: true,
          min: 1,
          whitespace: true,
        },
      ],
      placeholder: t('traces.form.quantity'),
    },
    {
      name: 'date',
      label: t('traces.date'),
      type: 'date',
      rules: [
        {
          required: true,
        },
      ],
      placeholder: t('traces.form.date'),
    },
  ];

  const onFinish = ({
    name,
    blockName,
    presta,
    vehicle,
    product,
  }) => {
    const resultPromise = [];
    resultPromise.push(dispatch(postTracesAction.request({
      name,
      blockName,
      presta,
      vehicle,
      product,
    })));
    return Promise.allSettled(resultPromise);
  };

  const onSuccess = () => {
    notification.success({
      message: <Trans i18nKey="traces.success.title" />,
      description: <Trans i18nKey="traces.success.description" />,
    });
  };

  const onError = () => {
    notification.error({
      message: <Trans i18nKey="traces.error.title" />,
      description: <Trans i18nKey="traces.error.description" />,
    });
  };

  return (
    <FormTemplate
      className="form"
      nameForm="register"
      useForm={form}
      variables={variables}
      onSuccess={onSuccess}
      onError={onError}
      onFinishApiCall={onFinish}
      buttonValidText="form.valid"
    />
  );
};

TracesForm.propTypes = {
  update: PropTypes.bool,
};

TracesForm.defaultProps = {
  update: false,
};

export default TracesForm;
