import React, { useEffect, useContext } from 'react';
// import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Form, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { patchVehicleAction, postVehicleAction } from 'common/business/actions/vehiclesAction';
// import FormGenerator from '../../shared/formGenerator';
import { PropTypes } from 'prop-types';
import FormTemplate from './formTemplate';
import { AuthContext } from '../../utils/context/authContext';
import { renderSelectOptions } from '../../utils/utils';

const VehicleForm = ({
  update, onClose, vehicle,
}) => {
  // const history = useHistory();
  const { currentCompany } = useSelector((state) => state.companiesReducer);
  const { domains, currentDomain } = useSelector((state) => state.domainsReducer);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = vehicle;
  const { t } = useTranslation();
  const authCtx = useContext(AuthContext);
  const { token } = authCtx;

  useEffect(() => {
    if (update) {
      if (vehicle) {
        const {
          name, type,
        } = vehicle;
        form.setFieldsValue({
          name,
          type,
        });
      }
    } else {
      form.resetFields();
    }
  }, [vehicle, update]);

  const onSuccess = () => {
    notification.success({
      message: <Trans i18nKey="register.success.title" />,
      description: <Trans i18nKey="register.success.description.vehicle" />,
    });
    if (update) {
      onClose();
      form.resetFields();
    } else {
      form.resetFields();
    }
  };

  const onFinish = (fields) => {
    const { name, type, company } = fields;
    const resultPromise = [];
    if (update) {
      resultPromise.push(dispatch(patchVehicleAction.request({
        id, name, type, source: 'web', token,
      })));
    } else {
      resultPromise.push(dispatch(postVehicleAction.request({
        name, type, companies: company, token,
      })));
    }
    return Promise.allSettled(resultPromise);
  };

  const onError = () => {
    notification.error({
      message: <Trans i18nKey="register.error.title" />,
      description: <Trans i18nKey="register.error.description" />,
    });
  };

  const onCancel = () => {
    form.resetFields();
  };

  const variables = [
    {
      name: 'name',
      label: t('vehicle.name'),
      rules: [
        {
          required: true,
          min: 2,
          whitespace: true,
        },
      ],
      placeholder: t('vehicle.form.name'),
    },
    {
      name: 'type',
      label: 'Type',
      rules: [
        {
          required: true,
          min: 2,
          whitespace: true,
        },
      ],
      placeholder: t('vehicle.form.type'),
    },
    {
      name: 'company',
      label: 'Sociétés',
      type: 'select',
      mode: currentCompany ? 'simple' : 'multiple',
      selectValues: renderSelectOptions(domains, currentDomain, 'blocks'),
      rules: [
        {
          required: true,
        },
      ],
    },
  ];

  const variablesItems = () => {
    if (update) {
      return variables.filter((item) => item.name !== 'password');
    }
    return variables;
  };
  return (
    <>
      <p>
        Les véhicules seront enregistrés dans une base commune,
        utilisez des noms génériques ou un nom associé à un modèle.
      </p>
      <FormTemplate
        className="form"
        nameForm="register"
        useForm={form}
        variables={variablesItems()}
        onSuccess={onSuccess}
        onFinishApiCall={onFinish}
        onError={onError}
        buttonValidText="form.valid"
        buttonCancelText="form.cancel"
        onCancel={onCancel}
      />
    </>
  );
};

VehicleForm.propTypes = {
  update: PropTypes.bool,
  onClose: PropTypes.func,
  vehicle: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number,
  }),
};

VehicleForm.defaultProps = {
  update: false,
  onClose: () => {},
  vehicle: {},
};

export default VehicleForm;
