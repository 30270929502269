import api from '../../utils/query';

export const getServiceProviderService = (data) => {
    const { source, currentDomainId, token, companyId } = data
    const url = `${companyId}/domain/${currentDomainId}/service-provider`;
  
    return api.get({url, source, token});
}
export const postServiceProviderService = (data) => {
    const url = "service-provider"
    return api.create(url, data);
}
export const patchServiceProviderService = (data) => {
    const url = "service-provider";
    return api.update(url, data);
}
export const deleteServiceProviderService = (id) => {
    return api.del("serviceProvider",id);
}
