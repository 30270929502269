import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form, message, notification } from 'antd';
import {
  MailOutlined, UserOutlined, LockOutlined, MobileOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAction, patchUserAction, postUserAction } from 'common/business/actions/usersAction';
import { regexEmail, regexMDP, AGS } from 'common/utils/utils';
import { PropTypes } from 'prop-types';
import FormTemplate from './formTemplate';
import { renderDomainsCheckbox, renderSelectOptions } from '../../utils/utils';
import { Context } from '../../utils/context/intlContext';
import { AuthContext } from '../../utils/context/authContext';

const UserForm = ({ update, onClose, currentUser }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { domains, currentDomain } = useSelector((state) => state.domainsReducer);
  const { users, userInfos } = useSelector((state) => state.usersReducer);
  const { roleCode } = userInfos;
  const { companies, currentCompany } = useSelector((state) => state.companiesReducer);
  const [currentCompanyState, setCurrentCompany] = useState(currentCompany);
  const context = useContext(Context);
  const { token } = useContext(AuthContext);
  const lang = context?.locale.locale;
  const { t } = useTranslation();
  const activeDomains = domains.filter((domain) => domain.active);
  const isAGS = AGS.includes(roleCode);

  useEffect(() => {
    if (currentCompany) {
      if (currentDomain) {
        dispatch(getUserAction.request({
          companyId: currentCompany,
          currentDomainId: currentDomain.id,
          token,
        }));
      } else {
        dispatch(getUserAction.request({ companyId: currentCompany, token }));
      }
    }
  }, [currentCompanyState]);

  useEffect(() => {
    if (update) {
      if (currentUser) {
        const {
          firstName, lastName, name, language, phone,
        } = currentUser;
        form.setFieldsValue({
          firstName,
          lastName,
          name,
          language,
          phone,
        });
      }
    }
    if (currentDomain) {
      const { name } = currentDomain;
      form.setFieldsValue({
        companyId: name,
      });
    }
  }, [currentUser]);

  const onSuccess = () => {
    notification.success({
      message: <Trans i18nKey="register.success.title" />,
      description: <Trans i18nKey="register.success.description" />,
    });
    if (update) {
      onClose();
    } else {
      form.resetFields();
    }
  };

  const onFinish = ({
    name, firstName, lastName, password, language, phone, role, domain, companyId,
  }) => {
    const resultPromise = [];
    const { key } = currentUser;
    if (update) {
      resultPromise.push(dispatch(patchUserAction.request({
        currentUserId: key, email: name, firstname: firstName, lastname: lastName, language, phone, role, source: 'web', token, companyId: currentDomain?.companyId, domainId: currentDomain?.id,
      })));
    } else {
      resultPromise.push(dispatch(postUserAction.request({
        email: name,
        firstname: firstName,
        lastname: lastName,
        password,
        language,
        phone,
        role,
        domains: domain,
        token,
        companyId: currentDomain?.companyId || companyId,
      })));
    }
    return Promise.allSettled(resultPromise);
  };

  const onError = () => {
    notification.error({
      message: <Trans i18nKey="register.error.title" />,
      description: <Trans i18nKey="register.error.description" />,
    });
  };

  const onCancel = () => {
    form.resetFields();
  };

  const userRoleValue = () => {
    if (isAGS) {
      return [
        { value: 'AGS_ADMIN', text: 'AGS administrateur' },
        { value: 'AGS_USER', text: 'AGS utilisateur' },
        { value: 'SUPER_ADMIN', text: 'Super administrateur' },
        { value: 'ADMIN', text: 'Administrateur' },
        { value: 'CONSULTANT', text: 'Consultant' },
        { value: 'USER', text: 'Utilisateur' },
      ];
    }
    return [
      { value: 'ADMIN', text: 'Administrateur' },
      { value: 'CONSULTANT', text: 'Consultant' },
      { value: 'USER', text: 'Utilisateur' },
    ];
  };

  const variables = [
    {
      name: 'companyId',
      label: 'user.form.company',
      type: 'select',
      change: true,
      noUpdate: update || currentDomain,
      initialValue: currentCompany,
      selectValues: renderSelectOptions(companies, currentCompany),
      rules: [
        {
          required: true,
          min: 2,
          message: t('form.inputRequired'),
        },
      ],
      placeholder: 'user.form.companyPH',
    },
    {
      name: 'domain',
      label: 'form.domainAccess',
      type: 'switchGroup',
      noUpdate: update,
      checkOptions: renderDomainsCheckbox({ currentCompanyState, activeDomains }),
      rules: [
        {
          required: true,
          message: t('form.inputRequired'),
        },
      ],
    },
    {
      name: 'name',
      label: 'form.mail',
      type: 'email',
      rules: [
        {
          required: true,
          message: t('form.inputRequired'),
          pattern: regexEmail,
        },
        () => ({
          validator(rule, value) {
            const userEmailExist = users?.some((elt) => elt.email === value);
            if (!userEmailExist || update) {
              return Promise.resolve();
            }
            message.error(t('form.inputMailError'));
            return Promise.reject();
          },
        }),
      ],
      prefix: <MailOutlined />,
      placeholder: 'form.mailPH',
    },
    {
      name: 'firstName',
      label: 'form.firstName',
      rules: [
        {
          required: true,
          min: 2,
          whitespace: true,
          message: t('form.invalidFirstName'),
        },
      ],
      prefix: <UserOutlined />,
      placeholder: 'form.firstNamePH',
    },
    {
      name: 'lastName',
      label: 'form.lastName',
      rules: [
        {
          required: true,
          min: 2,
          whitespace: true,
          message: t('form.invalidLastName'),
        },
      ],
      prefix: <UserOutlined />,
      placeholder: 'form.lastNamePH',
    },
    {
      name: 'password',
      label: 'form.password',
      rules: [
        {
          required: true,
          pattern: regexMDP,
          message: t('form.inputRequired'),
        },
      ],
      prefix: <LockOutlined />,
      placeholder: 'form.passwordPH',
      help: t('form.inputPasswordHelp'),
    },
    {
      name: 'language',
      label: 'form.language',
      type: 'select',
      initialValue: lang,
      selectValues: [
        { value: 'fr', text: 'form.fr' },
        { value: 'en', text: 'form.en' },
      ],
      rules: [
        {
          required: true,
          message: t('form.inputLanguageError'),
        },
      ],
      placeholder: 'form.languagePH',
    },
    {
      name: 'phone',
      label: 'form.phone',
      disabled: true,
      rules: [
        {
          required: false,
          message: t('form.inputRequired'),
        },
      ],
      prefix: <MobileOutlined />,
      placeholder: 'form.phonePH',
    },
    {
      name: 'role',
      label: 'form.role',
      type: 'select',
      noUpdate: update,
      initialValue: 'USER',
      selectValues: userRoleValue(),
      placeholder: 'user.form.rolePH',
    },
  ];

  const variablesItems = () => {
    if (update) {
      return variables.filter((item) => item.name !== 'password');
    }
    return variables;
  };

  return (
    <FormTemplate
      className="form"
      nameForm="register"
      useForm={form}
      variables={variablesItems()}
      onFinishApiCall={onFinish}
      onSuccess={onSuccess}
      onError={onError}
      buttonValidText="form.valid"
      buttonCancelText="form.cancel"
      onCancel={onCancel}
      setSelectedValue={setCurrentCompany}
    />
  );
};

UserForm.propTypes = {
  update: PropTypes.bool,
  onClose: PropTypes.func,
  currentUser: PropTypes.shape({
    key: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    language: PropTypes.string,
    phone: PropTypes.string,
  }),
};

UserForm.defaultProps = {
  update: false,
  onClose: () => {},
  currentUser: {
    key: '',
    firstName: '',
    lastName: '',
    name: '',
    language: '',
    phone: '',
  },
};

export default UserForm;
