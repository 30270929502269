import api from '../../utils/query';

export const getPicassoService = (data) => {
    const { companyId, currentDomainId, currentBlockId, timestamp, token, type } = data;
    const urlTemp = `${companyId}/domain/${currentDomainId}/block/${currentBlockId}/frost-threshold/2/date/${timestamp}/temperature-map`;
    const urlMildiou = `domain/${currentDomainId}/block/${currentBlockId}/date/${timestamp}/mildew-map`;
    const url = type === 4 ? urlTemp : urlMildiou ;
    return api.get({url, token});
}

export const getPicassoGlobalService = (data) => {
    const { domainId, date, token } = data;
    const url = `domain/${domainId}/frost-threshold/2/date/${date}/temperature-map`;
    return api.get({url, token});
}