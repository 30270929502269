import { Divider, PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import CompanyForm from '../../shared/forms/CompanyForm';

const CompanySettings = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader onBack={() => window.history.back()} title={t('settings.addCompany')} />
      <Divider />
      <CompanyForm />
    </>
  );
};

export default CompanySettings;
