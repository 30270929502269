import { put, takeEvery, call } from 'redux-saga/effects';
import { getWeatherForecastAction, FORECAST } from '../actions/weatherAction';
import { getWeatherService } from '../api/weatherService';

export function* getweatherForecast({ payload }) {
    try {
        const weather = yield call(getWeatherService, payload);
        yield put(getWeatherForecastAction.success(weather.data))
    }
    catch (err) {
        yield put(getWeatherForecastAction.failure(err))
    }
};

export function* getWeatherForecastSaga() {
    yield takeEvery(FORECAST.FETCH.REQUEST, getweatherForecast)
}
