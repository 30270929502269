import React from 'react';

const PanelControl = () => (
  <section className="control-panel">
    <h2>Date et heure des travaux</h2>
    <p>
      <span>
        Date des travaux :
        <strong className="red-color">11</strong>
      </span>
    </p>
    <p>
      <span>
        heure de début :
        <strong className="red-color">11</strong>
      </span>
      <br />
      <span>
        heure de fin:
        <strong className="red-color">11</strong>
      </span>
    </p>
    <h3>Voir les traces des autres parcelles</h3>
  </section>
);

export default PanelControl;
