import api from '../../utils/query';

export const postAuthService = (data) => {
    const res = api.auth("user/authenticate", data);
    return res;
}

export const postRefreshService = (data) => {
    const res = api.auth("user/refresh", data);
    return res;
}
