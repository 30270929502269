import { USER, USERINFOS } from '../actions/usersAction';

const INITIAL_STATE = { users: null, userInfos: null, loading: false };

const usersReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER.FETCH.REQUEST:
      return { ...state, loading: true };
    case USER.FETCH.SUCCESS:
      return { ...state, users: payload, loading: false };
    case USER.FETCH.FAILURE:
      return { ...state, loading: false };
    case USERINFOS.FETCH.REQUEST:
      return { ...state, loading: true };
    case USERINFOS.FETCH.SUCCESS:
      return { ...state, userInfos: payload, loading: false };
    case USERINFOS.FETCH.FAILURE:
      return { ...state, loading: false };
    case USER.POST.REQUEST:
      return { ...state, loading: true };
    case USER.POST.SUCCESS:
      return { ...state, loading: false };
    case USER.POST.FAILURE:
      return { ...state, loading: false };
    case USER.PATCH.REQUEST:
      return { ...state, loading: true };
    case USER.PATCH.SUCCESS:
      return { ...state, loading: false };
    case USER.PATCH.FAILURE:
      return { ...state, loading: false };
    case USER.DELETE.REQUEST:
      return { ...state, loading: true };
    case USER.DELETE.SUCCESS:
      return { ...state, userdeleted: payload, loading: false};
    case USER.DELETE.FAILURE:
      return { ...state, loading: false};
    default:
      return state;
  }
};

export default usersReducer;
