import { colors, layers } from 'common/utils/utils';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ColorPanel, LayerPanel } from './PanelControlStyle';

const PanelTemp = ({ filterValue }) => {
  const { t } = useTranslation();
  const renderSicknessLayer = () => {
    const sicknessLayers = [];
    for (let i = 0; i < 6; i += 1) {
      sicknessLayers.push(<LayerPanel key={i}>{i}</LayerPanel>);
    }
    return sicknessLayers;
  };
  const renderColorsTab = () => {
    if (filterValue < 3) {
      return colors.slice(-6).map((color) => <ColorPanel key={color} color={color} />);
    }
    return colors.map((color) => <ColorPanel key={color} color={color} />);
  };

  const renderLayersTab = () => {
    if (filterValue < 3) {
      return renderSicknessLayer();
    }
    return layers.map((layer) => <LayerPanel key={layer}>{layer}</LayerPanel>);
  };

  return (
    <div className="panel-temp">
      <h2>
        {filterValue < 3 ? t('vines.panel.sicknessIndicator') : t('vines.panel.tempIndicator')}
      </h2>
      <ul className="color-panel">
        {renderColorsTab()}
      </ul>
      <ul className="layer-panel">
        {renderLayersTab()}
      </ul>
    </div>
  );
};

PanelTemp.propTypes = {
  filterValue: PropTypes.number.isRequired,
};

export default PanelTemp;
