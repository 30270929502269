import { put, takeEvery, call } from 'redux-saga/effects';
import { deleteServiceProviderAction, getServiceProviderAction, patchServiceProviderAction, postServiceProviderAction, SERVICEPROVIDER } from '../actions/serviceProviderAction';
import { deleteServiceProviderService, getServiceProviderService, patchServiceProviderService, postServiceProviderService } from '../api/serviceProviderService';

export function* getServiceProvider({payload}) {
    try {
        const provider = yield call(getServiceProviderService, payload);
        yield put(getServiceProviderAction.success(provider.data))
    }
    catch (err) {
        yield put(getServiceProviderAction.failure(err))
    }
};

export function* postServiceProvider({ payload }) {
  const { token, currentDomainId, companyId } = payload;
    try {
      yield call(postServiceProviderService, payload);
      yield put(postServiceProviderAction.success("service provider registered"));
      yield put(getServiceProviderAction.request({ token, currentDomainId, companyId }))
    } catch (error) {
      yield put(postServiceProviderAction.failure(error));
    }
  }
  
  export function* patchServiceProvider({ payload }) {
    const { token } = payload;
    try {
      yield call(patchServiceProviderService, payload);
      yield put(patchServiceProviderAction.success('service provider updated'));
      yield put(getServiceProviderAction.request({token: token}))
    } catch (err) {
      yield put(patchServiceProviderAction.failure(err));
    }
  }
  
export function* deleteServiceProvider({ payload }) {
    try {
      yield call(deleteServiceProviderService, payload);
      yield put(deleteServiceProviderAction.success("service provider deleted"));
      yield put(getServiceProviderAction.request());
    }
    catch (err) {
      yield put(deleteServiceProviderAction.failure(err));
    }
  }

  export const sagas = {
    getServiceProviderSaga: takeEvery(SERVICEPROVIDER.FETCH.REQUEST, getServiceProvider),
    postServiceProviderSaga: takeEvery(SERVICEPROVIDER.POST.REQUEST, postServiceProvider),
    patchServiceProviderSaga: takeEvery(SERVICEPROVIDER.PATCH.REQUEST, patchServiceProvider),
    deleteServiceProviderSaga: takeEvery(SERVICEPROVIDER.DELETE.REQUEST, deleteServiceProvider),
  }
