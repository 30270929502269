import React, {
  useEffect, useContext, useLayoutEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Form, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { patchDomainAction, postDomainAction } from 'common/business/actions/domainsAction';
import { PropTypes } from 'prop-types';
import { getCompaniesAction } from 'common/business/actions/companyAction';
import { normalizeKey } from 'common/utils/utils';
import FormTemplate from './formTemplate';
import { renderSelectOptions } from '../../utils/utils';
import { AuthContext } from '../../utils/context/authContext';
import { onCancel, onError, onSuccess } from '../../utils/formFx';

const DomainForm = ({ update, onClose, domain }) => {
  const { domains, loading } = useSelector((state) => state.domainsReducer);
  const { companies, currentCompany } = useSelector((state) => state.companiesReducer);
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = domain;
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (!companies || !currentCompany) {
      dispatch(getCompaniesAction.request({ token }));
    }
  }, []);

  useEffect(() => {
    if (update) {
      if (domain) {
        const {
          name,
        } = domain;
        form.setFieldsValue({
          name,
        });
      }
    }
  }, [domain, update]);

  const onFinish = (fields) => {
    const { companyId, Name, Logo } = fields;
    const { file } = Logo;
    const { thumbUrl } = file;
    const splitThumbUrl = thumbUrl.split(',');
    const b64Image = splitThumbUrl[1];

    const resultPromise = [];
    if (update) {
      resultPromise.push(dispatch(patchDomainAction.request({
        id, companyId, Name, Logo: b64Image, source: 'web',
      })));
    } else {
      resultPromise.push(dispatch(postDomainAction.request({
        companyId, Name, Logo: b64Image, token,
      })));
    }
    return Promise.allSettled(resultPromise);
  };

  const variables = [
    {
      name: 'companyId',
      label: t('company.form.label'),
      type: 'select',
      change: true,
      defaultValue: currentCompany,
      selectValues: renderSelectOptions(companies, currentCompany),
      rules: [
        {
          required: true,
        },
      ],
      placeholder: t('domain.form.company'),
    },
    {
      name: 'Name',
      label: t('domain.name'),
      rules: [
        {
          required: true,
          min: 2,
          whitespace: true,
        },
        () => ({
          validator(rule, value) {
            const domainExists = domains.find((elt) => normalizeKey(elt?.name.toLowerCase())
            === normalizeKey(value?.toLowerCase()) && elt.active);
            if (!domainExists) {
              return Promise.resolve();
            }
            message.error(t('domain.alreadyExist'));
            return Promise.reject();
          },
        }),
      ],
      placeholder: t('domain.form.name'),
    },
    {
      name: 'Logo',
      label: t('domain.UploadImage'),
      type: 'uploadImage',
    },
  ];

  return (
    <FormTemplate
      className="form"
      nameForm="register"
      useForm={form}
      variables={variables}
      onSuccess={() => onSuccess({
        loading, update, form, onclose: onClose, messageTxt: 'domain.success.title', descriptionTxt: 'domain.success.description',
      })}
      onFinishApiCall={onFinish}
      onError={() => onError({ messageTxt: 'domain.error.title', descriptionTxt: 'domain.error.description' })}
      buttonValidText="form.valid"
      buttonCancelText="form.cancel"
      onCancel={() => onCancel(form)}
      loading={loading}
    />
  );
};

DomainForm.propTypes = {
  update: PropTypes.bool,
  onClose: PropTypes.func,
  domain: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
};

DomainForm.defaultProps = {
  update: false,
  onClose: () => {},
  domain: {},
};

export default DomainForm;
