export const greenPrimary = '#35ab82';
export const greenSecondary = '#7ae192';
export const greenTertiary = '#299e73';
export const greenTextColor = '#12711b';
export const redPrimary = '#c02424';
export const greyPrimary = '#7f8281';
export const borderColor = '#efefef';
export const whiteColor = '#fff';
export const neutralColor = '#e1e1e1';
export const startGradient = '#299e73';
export const endGradient = '#7ae192';
export const orangeColor = '#fda33a';
export const chimicViolet = '#8365e5';
export const bluePrimary = '#1285fd';
export const greenMechanic = '#0b5508';
export const greenManual = greenSecondary;
export const yellowNotif = '#ded24c';