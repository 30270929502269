import React, {
  useState, useLayoutEffect, useEffect, useContext, useCallback, useRef,
} from 'react';
import Map, { Marker, Source } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentBlockAction } from 'common/business/actions/blocksAction';
import { getPicassoAction } from 'common/business/actions/picassoAction';
import { getSentPositionsToGeojsonAction } from 'common/business/actions/sentPositionsAction';
import markerImage from 'common/assets/images/GLOBE-SENTINELLE.png';
import { Spin } from 'antd';
import { currentDate, findMinMax } from 'common/utils/utils';
import {
  buildValuesColor, buildValuesColorSickness, showLayers,
} from '../../utils/layersStyle';
import PanelControl from './PanelControl';
import HoverInfos from '../../shared/HoverInfos';
import PanelTemp from './PanelTemp';
import { AuthContext } from '../../utils/context/authContext';
import EmptyData from '../../shared/EmptyData';

const MBToken = process.env.REACT_APP_MB_TOKEN;
const MAPSTYLE = process.env.REACT_APP_MB_MAPSTYLE;

const Vignes = () => {
  const { blocks, currentBlock } = useSelector((state) => state.blocksReducer);
  const { userInfos } = useSelector((state) => state.usersReducer);
  const { company } = userInfos;
  const [hoverInfos, setHoverInfos] = useState(false);
  const [offsetMap, setOffsetMap] = useState(null);
  const [filterValue, setFilterValue] = useState(4);
  const [valuesColor, setValuesColor] = useState([]);
  const [timestamp, setTimestamp] = useState(currentDate);
  const { loading, picasso } = useSelector((state) => state.picassoReducer);
  const { geojson } = useSelector((state) => state.sentPositionsReducer);
  const { sentinelles } = useSelector((state) => state.sentinellesReducer);
  const { weather } = useSelector((state) => state.weatherReducer);
  const [long, setLong] = useState(null);
  const [lat, setLat] = useState(null);
  const dispatch = useDispatch();
  const { token } = useContext(AuthContext);
  const mapRef = useRef();

  useLayoutEffect(() => {
    dispatch(getCurrentBlockAction.request(blocks[0]));
  }, []);

  useLayoutEffect(() => {
    if (picasso && Object.keys(picasso).length > 0) {
      const { features } = picasso;
      const allLat = features.map((elt) => elt.properties.latitude);
      const allLong = features.map((elt) => elt.properties.longitude);
      const latitude = findMinMax(allLat);
      const longitude = findMinMax(allLong);

      if (filterValue > 3) {
        buildValuesColor({ elt: features, setValuesColor });
      } else {
        buildValuesColorSickness({ elt: features, setValuesColor });
      }
      setLong(longitude);
      setLat(latitude);
    }
    // setSentDatas(null);
  }, [picasso, currentBlock, timestamp, filterValue]);

  useEffect(() => {
    if (currentBlock && timestamp) {
      dispatch(getPicassoAction.request({
        companyId: company,
        currentDomainId: currentBlock.domainId,
        currentBlockId: currentBlock.id,
        timestamp: (timestamp * 1000) - ((timestamp * 1000) % 3600000),
        token,
        type: filterValue,
      }));
      if (filterValue < 4) {
        dispatch(getSentPositionsToGeojsonAction.request({
          blocks,
          blockID: currentBlock.id,
          token,
        }));
      }
    }
  }, [currentBlock, timestamp, filterValue]);

  const onHover = useCallback((event) => {
    const { features, srcEvent: { offsetX, offsetY } } = event;
    const hoveredFeature = features && features[0];
    setOffsetMap(hoveredFeature ? { feature: hoveredFeature, x: offsetX, y: offsetY } : null);
  }, []);
  const handleHover = (marker) => {
    const { sentinelID, temperature, humidity } = marker;
    const sentId = sentinelles.filter((sentinel) => sentinel.id === sentinelID);
    setHoverInfos({
      sentinelId: sentId[0].serial, temperature, humidity, offsetMap,
    });
  };

  const onSelectBlock = useCallback(() => {
    mapRef.current?.flyTo({ center: [long, lat], duration: 200 });
  });

  return (
    <section className="map-content">
      {picasso && Object.keys(picasso).length > 0 && long && lat && geojson ? (
        <Map
          ref={mapRef}
          viewState={{
            longitude: long,
            latitude: lat,
            zoom: 17.5,
            pitch: 55,
            bearing: 0,
            width: 200,
            height: 200,
          }}
          interactive
          pitchWithRotate
          antialias
          preserveDrawingBuffer
          optimizeForTerrain
          dragPan
          scrollZoom={false}
          doubleClickZoom={false}
          mapboxAccessToken={MBToken}
          mapStyle={MAPSTYLE}
          style={{
            width: '100vw',
            height: '100vh',
          }}
          onMouseEnter={onHover}
          interactiveLayersIds={['sentinelle']}
          reuseMaps
        >
          <PanelControl
            setTimestamp={setTimestamp}
            weatherData={weather || {}}
            setFilterValue={setFilterValue}
            filterValue={filterValue}
            onSelectCity={onSelectBlock}
          />
          <PanelTemp filterValue={filterValue} />
          {picasso && !loading ? (
            <Source id="heatmap-data" type="geojson" data={picasso}>
              {showLayers(valuesColor)}
            </Source>
          ) : (
            <Spin />
          )}
          {geojson && geojson?.features.map((marker) => {
            const { geometry, properties } = marker;
            const { coordinates } = geometry;
            const nothing = null;
            return (
              <Marker
                key={marker.id}
                id="sentinelles"
                latitude={coordinates[1]}
                longitude={coordinates[0]}
                offsetTop={-20}
                offsetLeft={-10}
              >
                <img
                  src={markerImage}
                  alt="test"
                  width={20}
                  onMouseEnter={() => filterValue === 4 && handleHover(properties)}
                  onMouseLeave={() => filterValue === 4 && setHoverInfos(null)}
                  onFocus={() => nothing}
                />
              </Marker>
            );
          })}
          {hoverInfos
            && <HoverInfos hoverInfos={hoverInfos} />}
        </Map>
      )
        : (
          <EmptyData />
        )}
    </section>
  );
};

export default Vignes;
