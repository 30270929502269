import { put, takeEvery, call } from 'redux-saga/effects';
import { setUniqueList } from '../../utils/utils';
import { getImagesAction, getLastImageAction, getImagesCam0FAction, getImagesCam1FAction, IMG, LASTIMG } from '../actions/imagesAction';
import { getCurrentSentAction } from '../actions/sentinellesAction';
import { getImagesService } from '../api/imagesService';

export function* getImages({payload}) {
    try {
        let cam0f = [];
        let cam1f = [];
        let sentinelles = [];
        
        const images = yield call(getImagesService, payload);
        const listImages = images.data;
        
        if(images) {
            listImages.map(item => {
            sentinelles.push({'name': item.positionName})
        });
        const sentArray = setUniqueList(sentinelles, 'name');
        yield put(getCurrentSentAction.success(sentArray))
        
        listImages.map(item => {
            sentArray.map(sent => {
                if (item.positionName === sent.name) {
                    cam0f.push({'sentId': item.positionName, 'cam': item.cam0f, 'date': item.date, 'rain': item.precipit, 'rel_hum': item.rel_hum, 'sw_id': item.sw_id, 'temp': item.temp, 'wm_rel_hum': item.wm_rel_hum, 'wm_temp': item.wm_temp });
                    cam1f.push({'sentId': item.positionName, 'cam': item.cam1f, 'date': item.date, 'rain': item.precipit, 'rel_hum': item.rel_hum, 'sw_id': item.sw_id, 'temp': item.temp, 'wm_rel_hum': item.wm_rel_hum, 'wm_temp': item.wm_temp });
                }
                return  null;
            })
        });
        }
        if (listImages.length > 0) {
            yield put(getImagesCam0FAction.success(cam0f))
            yield put(getImagesCam1FAction.success(cam1f))
            yield put(getImagesAction.success(listImages))
        }
        
    }
    catch (err) {
        yield put (getImagesCam0FAction.failure([]));
        yield put (getImagesCam1FAction.failure([]));
        yield put(getCurrentSentAction.failure([]))
        yield put(getImagesAction.failure(err));
    }
};


export const sagas = {
    getImagesSaga: takeEvery(IMG.FETCH.REQUEST, getImages),
}
