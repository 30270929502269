import React, { useContext, useEffect, useState } from 'react';
import {
  Divider, Drawer, Popconfirm, Space,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, DiffOutlined, EditOutlined } from '@ant-design/icons';
import { deleteBlockAction, getBlocksAction } from 'common/business/actions/blocksAction';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import BlockForm from '../../shared/forms/BlockForm';
import TableTemplate from '../../shared/TableTemplate';
import DashboardList from './DashboardStyle';
import { AuthContext } from '../../utils/context/authContext';

const BlocksList = ({ isAGS, isADMIN }) => {
  const [visible, setVisible] = useState(false);
  const { blocks, currentBlock } = useSelector((state) => state.blocksReducer);
  const { domains, currentDomain } = useSelector((state) => state.domainsReducer);
  const [update, setUpdate] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const renderData = () => {
    const data = [];
    blocks?.map((block) => data.push({
      key: block.id, ...block,
    }));
    setTableData(data);
  };
  useEffect(() => {
    renderData();
  }, [blocks]);

  const onClose = () => {
    setVisible(false);
    setUpdate(false);
  };

  const confirm = (id) => {
    dispatch(deleteBlockAction.request({ id, token }));
  };

  const handleClickBlock = (record) => {
    const { domainId, id } = record;
    let currentDomainSearch;
    if (currentDomain) {
      currentDomainSearch = domains.find((domain) => domain.id === domainId);
    }
    dispatch(getBlocksAction.request({
      currentDomainId: domainId,
      blockId: id,
      companyId: currentDomain?.companyId || currentDomainSearch,
      token,
    }));
    setVisible(!visible);
    setUpdate(true);
  };

  const columns = [
    {
      title: t('module.block.title'),
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      width: 90,
      hidden: false,
    },
    {
      title: t('module.actions'),
      dataIndex: t('module.actions'),
      key: t('module.actions'),
      align: 'right',
      width: 60,
      hidden: !(isAGS || isADMIN),
      render: (event, record) => (
        <Space size="middle">
          <EditOutlined
            className="iconAction"
            onClick={() => handleClickBlock(record)}
          />
          {isAGS && (
          <Popconfirm
            title={t('module.delete')}
            onConfirm={() => confirm(record.id)}
          >
            <DeleteOutlined className="iconAction" />
          </Popconfirm>
          )}
        </Space>
      ),
      responsive: ['md', 'lg'],
    },
  ];

  const renderColumns = columns.filter((col) => col.hidden === false);

  return (
    <>
      <DashboardList width="500">
        <div className="header-list">
          <h3>
            {t('module.block.list')}
          </h3>
          {isAGS && <DiffOutlined onClick={() => setVisible(!visible)} />}
        </div>
        <Divider />
        <TableTemplate columns={renderColumns} data={tableData} />
      </DashboardList>
      <Drawer
        title={update ? t('module.block.update') : t('module.block.create')}
        placement="right"
        closable
        onClose={onClose}
        open={visible}
        width={500}
      >
        <BlockForm
          update={!!update}
          onClose={onClose}
          block={currentBlock}
        />
      </Drawer>
    </>
  );
};

BlocksList.propTypes = {
  isADMIN: PropTypes.bool.isRequired,
  isAGS: PropTypes.bool.isRequired,
};

export default BlocksList;
