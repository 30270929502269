// import { Slide } from 'react-slideshow-image';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Slide } from 'react-slideshow-image';

const SlideImages = ({
  slideKey,
  defaultbreakpointIndex,
  imagesList,
  defaultIndex,
  setVisibleModal,
  setCurrentList,
  setCurrentImage,
}) => {
  const handleClickImage = (payload) => {
    const { event, index } = payload;
    event.preventDefault();
    setCurrentImage(index);
    setCurrentList(imagesList);
    setVisibleModal(true);
  };

  const renderImages = () => imagesList.map((image, index) => {
    const { cam, date } = image;
    const uri = `https://app.sentinelle-vignes.fr/assets/images/sentinelles/${cam}`;

    return (
      <React.Fragment key={slideKey}>
        {cam ? (
          <div
            className="thumb-slide"
            role="button"
            tabIndex={0}
            onClick={(event) => handleClickImage({ event, imagesList, index })}
            onKeyPress={(event) => handleClickImage({ event, imagesList, index })}
          >
            <img
              src={uri}
              alt={date}
              loading="lazy"
            />

          </div>
        )
          : (
            <div />
          )}
      </React.Fragment>
    );
  });

  return (
    <Slide
      duration={600}
      slidesToShow={defaultbreakpointIndex()}
      slidesToScroll={defaultbreakpointIndex()}
      indicators={false}
      autoplay={false}
      canSwipe
      defaultIndex={defaultIndex()}
      infinite={false}
      cssClass="slide-cam"
      responsive={[
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 980,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ]}
    >
      {renderImages()}
    </Slide>
  );
};

SlideImages.propTypes = {
  imagesList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  slideKey: PropTypes.number.isRequired,
  defaultbreakpointIndex: PropTypes.func.isRequired,
  defaultIndex: PropTypes.func,
  setCurrentList: PropTypes.func.isRequired,
  setCurrentImage: PropTypes.func.isRequired,
  setVisibleModal: PropTypes.func.isRequired,
};
SlideImages.defaultProps = {
  defaultIndex: () => {},
};

export default SlideImages;
