import api from '../../utils/query';

export const getTracesService = (data) => {
    const {id, source, currentDomainId} = data
    const url = `traces?domainID=${currentDomainId}&_embed=domains`
    if (!!id) {
        return api.get({url, id, source});
    }
    return api.get({url, source});
}
export const postTracesService = (data) => {
    const {
        id: typeTaskId,
        name,
        category,
        selectedBlockLabel : blockName,
        selectedDate : date,
        selectedServProviderLabel : presta,
        selectedVehicleLabel : vehicle,
        selectedProductLabel : product,
        quantity: quantity,
        date: selectedDate,
    } = data;
    return api.create("traces/", {typeTaskId, category, blockName, date, name, presta, vehicle, product, quantity});
}
