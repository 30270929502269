import { TASKS } from '../actions/tasksAction';

const INITIAL_STATE = { tasks: null, loading: false };

const tasksReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case TASKS.FETCH.REQUEST:
      return {...state, loading: true};
    case TASKS.FETCH.SUCCESS:
        return {...state, tasks: payload, loading: false};
    case TASKS.FETCH.FAILURE:
        return {...state, tasks: null, loading: false};    
    case TASKS.POST.REQUEST:
        return { ...state, loading: true };
    case TASKS.POST.SUCCESS:
        return { ...state, loading: false };
    case TASKS.POST.FAILURE:
        return { ...state, loading: false };
    case TASKS.PATCH.REQUEST:
        return { ...state, loading: true };
    case TASKS.PATCH.SUCCESS:
        return { ...state, loading: false };
    case TASKS.PATCH.FAILURE:
        return { ...state, loading: false };
    case TASKS.DELETE.REQUEST:
        return { ...state, loading: true };
    case TASKS.DELETE.SUCCESS:
        return { ...state, tasksdeleted: payload, loading: false};
    case TASKS.DELETE.FAILURE:
        return { ...state, loading: false};
    default:
      return state;
  }
};

export default tasksReducer;
