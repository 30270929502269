import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, notification } from 'antd';
import { LockOutlined, UserOutlined, EyeTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { postAuthAction } from 'common/business/actions/authAction';
import { getUserInfosAction } from 'common/business/actions/usersAction';
import FormTemplate from '../../shared/forms/formTemplate';
import { isLoggedin } from '../../utils/utils';
import { AuthContext } from '../../utils/context/authContext';

const SignIn = () => {
  const { loading } = useSelector((state) => state.authReducer);
  const { loading: userLoading } = useSelector((state) => state.usersReducer);
  const { msg } = useSelector((state) => state.errorReducer);
  const [userInfoState, setUserInfoState] = useState('no-infos');
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const { t } = useTranslation();

  const onSuccess = () => {
    if (!loading && token && msg === null && !userLoading) {
      notification.success({
        message: 'Vous êtes connecté',
        description: 'Vous allez être redirigé vers votre tableau de bord',
      });
    }
  };

  useEffect(() => {
    if (token) {
      setUserInfoState('in-progress');
      onSuccess();
      dispatch(getUserInfosAction.request(token));
    }
  }, [token]);

  useEffect(() => {
    if (userLoading) {
      setUserInfoState('infos-request');
    }
    if (msg && token) {
      setTimeout(() => {
        history.push('/error');
      }, 1000);
      if (userInfoState === 'in-progress') {
        setUserInfoState('error');
      }
    } else if (userInfoState === 'infos-request') {
      setUserInfoState('ok');
      const isAuth = isLoggedin(token);
      if (isAuth) {
        setTimeout(() => {
          history.push('/token');
          form.resetFields();
        }, 1000);
      }
    }
  }, [msg, userLoading]);

  const variables = [
    {
      name: 'login',
      label: 'signin.login',
      placeholder: 'signin.loginPH',
      prefix: <UserOutlined />,
      rules: [
        {
          required: true,
          type: 'email',
          message: 'form.inputRequired',
        },
      ],
    },
    {
      name: 'password',
      label: 'form.password',
      placeholder: 'form.passwordPH',
      prefix: <LockOutlined />,
      sufix: <EyeTwoTone />,
      help: t('form.inputPasswordHelp'),
      rules: [
        {
          required: true,
          message: 'form.inputPasswordFailed',
        },
      ],
    },
  ];
  const onError = () => {
    notification.error({
      message: 'oops',
      description: 'une erreur dans le login password est survenue',
    });
  };

  const onFinish = ({ login, password }) => {
    const resultPromise = [];
    resultPromise.push(dispatch(postAuthAction.request({ email: login, password, source: 'web' })));
    return Promise.allSettled(resultPromise);
  };

  return (
    <article className="article-form">
      <FormTemplate
        className="form"
        nameForm="signin"
        useForm={form}
        variables={variables}
        onFinishApiCall={onFinish}
        onSuccess={() => onSuccess}
        onError={onError}
        buttonValidText="form.valid"
        loading={loading}
      />

    </article>
  );
};

export default SignIn;
