import * as React from 'react';
import { PropTypes } from 'prop-types';
import { customIcon } from '../utils/Icons';

const WeatherPanel = ({ styleClass, data }) => (
  <div className={`weather-panel ${styleClass}`}>
    <div className="weather-panel-temp weather-padding">
      {customIcon.thermometer(40)}
      <span>
        {Math.round(data.temp)}
        °C
      </span>
    </div>
    <div className="weather-panel-humidity weather-padding">
      <ul>
        <li>
          {customIcon.water(35)}
          <span>
            {Math.round(data.rel_hum)}
            %
          </span>
        </li>
        <li>
          {customIcon.rain(35)}
          <span>
            {Math.round(data.rain)}
            mm/h
          </span>
        </li>
      </ul>
    </div>
    {/* <div className="weather-panel-wind weather-padding">
      <ul>
        <li>
          <CopyOutlined />
          <span>N/E</span>
        </li>
        <li>
          <CopyOutlined />
          <span>2 m/s</span>
        </li>
      </ul>
    </div> */}

  </div>
);
WeatherPanel.propTypes = {
  styleClass: PropTypes.string,
  data: PropTypes.shape().isRequired,
};

WeatherPanel.defaultProps = {
  styleClass: '',
};

export default WeatherPanel;
