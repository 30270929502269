import { put, takeEvery, call } from 'redux-saga/effects';
import { getTasksAction, deleteTasksAction,  TASKS, patchTasksAction, postTasksAction } from '../actions/tasksAction';
import { deleteTypeTasksAction, getTypeTasksAction, patchTypeTasksAction, postTypeTasksAction, TYPE_TASKS } from '../actions/typeTaskAction';
import { getTasksService, deleteTasksService, patchTasksService, postTasksService } from '../api/tasksService';
import { deleteTypeTasksService, getTypeTasksService, patchTypeTasksService, postTypeTasksService } from '../api/typeTaskService';
import { typesTasks } from '../../utils/utils';

export function* getTypeTask() {
    try {
        yield put(getTypeTasksAction.success(typesTasks))
        
    }
    catch (err) {
        yield put(getTypeTasksAction.failure(err))
    }
};

// function* postTypeTask({ payload }) {
//   const { token } = payload;
//     try {
//       yield call(postTypeTasksService, payload);
//       yield put(postTypeTasksAction.success("type task registered"));
//       yield put(getTypeTasksAction.request(token))
//     } catch (error) {
//       yield put(postTypeTasksAction.failure(error));
//     }
//   }
  
//   function* patchTypeTask({ payload }) {
//     const { token } = payload;
//     try {
//       yield call(patchTypeTasksService, payload);
//       yield put(patchTypeTasksAction.success('type task updated'));
//       yield put(getTypeTasksAction.request(token));

//     } catch (err) {
//       yield put(patchTypeTasksAction.failure(err));
//     }
//   }

// function* deleteTypeTask({ payload }) {
//   const { token } = payload;
//     try {
//       yield call(deleteTypeTasksService, payload);
//       yield put(deleteTypeTasksAction.success('type task deleted'));
//       yield put(getTypeTasksAction.request(token));
//     }
//     catch (err) {
//       yield put(deleteTypeTasksAction.failure(err));
//     }
//   }
  
  export const sagas = {
    // deleteTypeTaskSaga: takeEvery(TYPE_TASKS.DELETE.REQUEST, deleteTypeTask),
    // patchTypeTaskSaga: takeEvery(TYPE_TASKS.PATCH.REQUEST, patchTypeTask),
    // postTypeTaskSaga: takeEvery(TYPE_TASKS.POST.REQUEST, postTypeTask),
    getTypeTaskSaga: takeEvery(TYPE_TASKS.FETCH.REQUEST, getTypeTask),
  }
  