// Regex
export const regexName = RegExp("^[a-zA-Zà-ù,. '-]+$", "i");
export const regexMDP = /^(?=.*[~@!%=+\-*?&[\]{}()'\\|`_^])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
export const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const regexAlphaNum = RegExp("^[w &-_@*/#]+$", "i");
export const regexMultipleSpace = RegExp(" {1,}", "g");

// style
import { chimicViolet, greenManual, greenMechanic, yellowNotif } from '../assets/style/defaultStyle';

export const setUniqueList = (arr, key) => [...new Map(arr && arr.map(item => [item[key], item])).values()];

export const normalizeKey = (key) => {
  if (key) {
    const normalizeKey = key.replace(/[^\w]/g, '');
    return normalizeKey;
  }
  return null;
};

export const AGS_ADMIN = 'AGS_ADMIN';
export const AGS_USER = 'AGS_USER';
export const SUPER_ADMIN = 'SUPER_ADMIN';
export const ADMIN = 'ADMIN';
export const USER = 'USER';
export const CONSULTANT = 'CONSULTANT';
export const AGS = 'AGS_ADMIN' || 'AGS_USER';

export const sentinelleImage = (imageId) => {
    let sentinellePictureName;
    sentinellesPictures.forEach((item) => {
        Object.keys(item).forEach(() => {
            if (item.imageId) {
            sentinellePictureName = Object.values(item)[0];
            }
        })
    });
    return sentinellePictureName;
}

export const typesTasks = [
  {"id": 0, "name": "Rognage", "category": "mechanic"},
  {"id": 1, "name": "Ecimage", "category": "mechanic"},
  {"id": 2, "name": "Traitement", "category": "chimic"}, 
  {"id": 3, "name": "Désherbage", "category": "chimic"},
  {"id": 4, "name": "Vendanges", "category": "manual"}
]

export const cToF = (temperature, lang) => {
    if (lang === 'en') {
      return (temperature * 9) / 5 + 32;
    } else {
      return temperature;
    }
  };

  //Mapbox layout

export const layers = ['-10', '-5', '0', '1', '5', '10', '15', '20', '25', '28', '30', '35', '40', '43', '45'];
export const colors = ['#0021FA', '#0058F5', '#0190F5', '#00CEF5', '#01F5EA', '#99F500', '#54F000', '#23E800', '#08E300', '#00DE0F', '#F5D600', '#F5AB01', '#F07D01', '#F54900', '#FA1200'];
export const heatmapRadius = ['interpolate', ['linear'], ['zoom'], 10, 1, 17, 42];
export const heatmapOpacity = ['interpolate', ['linear'], ['zoom'], 5, 1, 55, 0];

export const fresh5HC = [
  'interpolate',
  ['linear'],
  ['heatmap-density'],
  0,
  'rgba(0,0,0,0.05)',
  0.1,
  'rgba(1,245,234,.05)',
  0.9,
  'rgba(1,245,234,.9)',
];
export const fresh5Filter = ['in', 'value', 0, 1, 2, 3, 4, 5];
export const freshNative5Filter = ['all', ['>=', 'value', 0], ['<', 'value', 6]];
export const fresh4HC = [
  'interpolate',
  ['linear'],
  ['heatmap-density'],
  0,
  'rgba(0,0,0,0.05)',
  0.1,
  'rgba(0,206,245,.05)',
  0.9,
  'rgba(0,206,245,.9)',
];
export const fresh4Filter = ['in', 'value', 6, 7, 8, 9, 10];
export const freshNative4Filter = ['all', ['>', 'value', 5], ['<', 'value', 11]];
export const fresh3HC = [
  'interpolate',
  ['linear'],
  ['heatmap-density'],
  0,
  'rgba(0,0,0,0.05)',
  0.1,
  'rgba(1,144,245,.05)',
  0.9,
  'rgba(1,144,245,.9)',
];
export const fresh3Filter = ['in', 'value', 11, 12, 13, 14, 15];
export const freshNative3Filter = ['all', ['>', 'value', 10], ['<', 'value', 16]];
export const fresh2HC = [
  'interpolate',
  ['linear'],
  ['heatmap-density'],
  0,
  'rgba(0,0,0,0.01)',
  0.1,
  'rgba(0,88,245,.05)',
  0.9,
  'rgba(0,88,245,.9)',
];
export const fresh2Filter = ['in', 'value', 16, 17, 18, 19, 20];
export const freshNative2Filter = ['all', ['>', 'value', 15], ['<', 'value', 21]];
export const fresh1HC = [
  'interpolate',
  ['linear'],
  ['heatmap-density'],
  0,
    'rgba(0,0,0,0.01)',
    0.1,
    'rgba(0,33,250,.05)',
    0.9,
    'rgba(0,33,250,.9)',
];
export const fresh1Filter = ['in', 'value', 21, 22, 23, 24, 25, 26];
export const freshNative1Filter = ['all', ['>', 'value', 20], ['<', 'value', 27]];
export const median5HC = [
  'interpolate',
  ['linear'],
  ['heatmap-density'],
  0,
    'rgba(0,0,0,0.01)',
    0.1,
    'rgba(0,222,15,.05)',
    0.9,
    'rgba(0,222,15,.9)',
];
export const median5Filter = ['in', 'value', 27, 28, 29, 30, 31, 32, 33, 34, 35];
export const medianNative5Filter = ['all', ['>', 'value', 26], ['<', 'value', 36]];
export const median4HC = [
  'interpolate',
  ['linear'],
  ['heatmap-density'],
  0,
    'rgba(0,0,0,0.01)',
    0.1,
    'rgba(8,227,0,.05)',
    0.9,
    'rgba(8,227,0,.9)',
];

export const median4Filter = ['in', 'value', 36, 37, 38, 39];
export const medianNative4Filter = ['all', ['>', 'value', 35], ['<', 'value', 40]];
export const median3HC = [
    'interpolate',
    ['linear'],
    ['heatmap-density'],
  0,
    'rgba(0,0,0,0.01)',
    0.1,
    'rgba(35,232,0,.05)',
    0.9,
    'rgba(35,232,0,.9)',
  ];
export const median3Filter = ['in', 'value', 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55];
export const medianNative3Filter = ['all', ['>', 'value', 39], ['<', 'value', 56]];
export const median2HC = [
  'interpolate',
  ['linear'],
  ['heatmap-density'],
  0,
  'rgba(0,0,0,0.01)',
  0.1,
  'rgba(84,240,0,.05)',
  0.9,
  'rgba(84,240,0,.9)',
];
export const median2Filter = ['in', 'value', 56, 57, 58, 59, 60, 61, 62, 63, 64, 65];
export const medianNative2Filter = ['all', ['>', 'value', 55], ['<', 'value', 66]];
export const median1HC = [
  'interpolate',
  ['linear'],
  ['heatmap-density'],
  0,
  'rgba(0,0,0,0.01)',
    0.1,
    'rgba(153,245,0,.05)',
    0.9,
    'rgba(153,245,0,.9)',
]; 
export const sickness1Filter = ['in', 'value', 0];
export const sicknessNative1Filter = ['all', ['<', 'value', 1]];
export const median1Filter = ['in', 'value', 66, 67, 68];
export const medianNative1Filter = ['all', ['>', 'value', 65], ['<', 'value', 69]];
export const hot1HC = [
  'interpolate',
  ['linear'],
  ['heatmap-density'],
  0,
    'rgba(0,0,0,0.01)',
    0.1,
    'rgba(245,214,0,.05)',
    0.9,
    'rgba(245,214,0,.9)',
];
export const sickness2Filter = ['in', 'value', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const sicknessNative2Filter = ['all', ['>', 'value', 0], ['<', 'value', 11]];
export const hot1Filter = ['in', 'value', 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80];
export const hotNative1Filter = ['all', ['>', 'value', 68], ['<', 'value', 81]];
export const hot2HC = [
    'interpolate',
    ['linear'],
    ['heatmap-density'],
    0,
      'rgba(0,0,0,0.01)',
      0.1,
      'rgba(245,171,1,.05)',
      0.9,
      'rgba(245,171,1,.9)',
  ];
export const sickness3Filter = ['in', 'value', 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
export const sicknessNative3Filter = ['all', ['>', 'value', 10], ['<', 'value', 21]];
export const hot2Filter = ['in', 'value', 81, 82, 83, 84, 85];
export const hotNative2Filter = ['all', ['>', 'value', 80], ['<', 'value', 86]];
export const hot3HC = [
    'interpolate',
    ['linear'],
    ['heatmap-density'],
    0,
    'rgba(0,0,0,0.01)',
    0.1,
    'rgba(240,125,1,.05)',
    0.9,
    'rgba(240,125,1,.9)',
  ];
export const sickness4Filter = ['in', 'value', 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
export const sicknessNative4Filter = ['all', ['>', 'value', 20], ['<', 'value', 31]];
export const hot3Filter = ['in', 'value', 86, 87, 88, 89, 90];
export const hotNative3Filter = ['all', ['>', 'value', 85], ['<', 'value', 91]];
export const hot4HC = [
    'interpolate',
    ['linear'],
    ['heatmap-density'],
    0,
      'rgba(0,0,0,.01)',
      0.1,
      'rgba(245,73,0,.05)',
      0.9,
      'rgba(245,73,0,.9)',
  ];
  export const sickness5Filter = ['in', 'value', 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
  export const sicknessNative5Filter = ['all', [">", "value", 30], ['<', 'value', 41]];
export const hot4Filter = ['in', 'value', 91, 92, 93, 94, 95];
export const hotNative4Filter = ['all', ['>', 'value', 90], ['<', 'value', 96]];
export const hot5HC = [
    'interpolate',
    ['linear'],
    ['heatmap-density'],
    0,
      'rgba(0,0,0,0.01)',
      0.1,
      'rgba(250,18,0,.05)',
      0.9,
      'rgba(250,18,0,.9)',
  ];
  export const sickness6Filter = ['in', 'value', 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];
  export const sicknessNative6Filter = ['all', ['>', 'value', 40], ['<', 'value', 59]];
export const hot5Filter = ['in', 'value', 96, 97, 98, 99, 100];
export const hotNative5Filter = ['all', ['>', 'value', 95], ['<', 'value', 101]];
export const neutralHC = [
    'interpolate',
    ['linear'],
    ['heatmap-density'],
    0,
      'rgba(0,0,0,0.01)',
      0.1,
      'rgba(250,250,250,.05)',
      0.9,
      'rgba(250,250,250,.9)',
  ];
  export const sicknessNeutralFilter = ['in', 'value', 201];
  export const sicknessNativeNeutralFilter = ['all', ['=', 'value', 201]];
export const neutralFilter = ['in', 'value', 101];
export const nativeNeutralFilter = ['all', ['=', 'value', 101]];
// calendar custom fx

export const formatDate = ({unixDate, unixHour, shortDate, lang}) => {
    const date = new Date(unixDate || unixHour || shortDate * 1000);
    const type = unixDate || unixHour || shortDate;
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formatMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const showHours = hours > 9 ? `${hours}h${formatMinutes}` : `0${hours}h${formatMinutes}`;
    const showShortDate = () => {
      const dayFormat = day < 10 ? `0${day}` : day;
      const monthFormat = month < 10 ? `0${month}` : month;

      return `${dayFormat}-${monthFormat}-${year}`
    };

    switch(type) {
        case unixHour:
            return showHours;
        default:
            return showShortDate();
    };
};



export const calendarHours = () => {
    const hourCollection = [];
    for (let i = 0; i < 24; i += 1) {
      let am = '0';
      if (i < 10) {
        am = `0${i}`;
      } else {
        am = `${i}`;
      }
      hourCollection.push(am);
    }
    return hourCollection;
  };
  
  export const splitDate = data => {  
    const numDaySplit = data.split('-');
    const num = numDaySplit[0];
    const month = numDaySplit[1];
    const year = numDaySplit[2];
    return { num, month, year };
  };

  export const roundToNearestHour = (date) => {
    date.setMinutes(0, 0, 0);
    
    return date.getTime() / 1000;
  }

  export const currentDate = new Date().getTime() / 1000;
  
  export const calendarDates = lang => {
    const dateCollection = [];
    const predictDate = currentDate + 7 * 24 * 60 * 60;
    for (let i = 0; i < 15; i += 1) {
      const newDate = formatDate({
        shortDate: predictDate - i * 60 * 60 * 24,
        lang,
      });
      const renderDate = splitDate(newDate);
      dateCollection.push({
        num: renderDate.num,
        month: renderDate.month,
        year: renderDate.year,
      });
    }
    return dateCollection;
  };
  
export const sentinelleData = (bloc) => {
    const normalizeBloc = normalizeKey(bloc.toLowerCase());
    let block;
   interpolates.forEach((item) => {
        Object.keys(item).forEach((val) => {
            if (val === normalizeBloc) {
                block = Object.values(item)[0];
            }
        })
    });
    return block;
};
export const domainsNameData = (name) => {
  if (name) {
    const normalizeName = normalizeKey(name.toLowerCase());
    let domainName;
    domainsArray.forEach((item) => {
      Object.keys(item).forEach((val) => {
            if (val === normalizeName) {
                domainName = Object.values(item)[0];
            }
        })
    });
    return domainName;
  }
};

export const filters = [
    {
      label: 'Oïdium',
      type: 'Oïdium',
      id: 1,
      selected: false,
      disabled: true,
    },
    {
      label: 'Botrytis',
      type: 'Botrytis',
      id: 2,
      selected: false,
      disabled: false,
    },
    {
      label: 'Mildew',
      type: 'Mildew',
      id: 3,
      selected: false,
      disabled: false,
    },
    {
      label: 'sentinel.filter.temperatures',
      type: 'temperature',
      id: 4,
      selected: false,
      disabled: false,
    },
  ];

  export const warningFilters = [
      {
          label: 'notification.filter.diseases',
          id: 1,
          cat: 2000,
          selected: true,
      },
      {
          label: 'notification.filter.weather',
          id: 2,
          cat: 1100,
          selected: true,
      },
      {
          label: 'notification.filter.user',
          id: 3,
          cat: 5000,
          selected: false,
      },
      {
          label: 'notification.filter.equipment',
          id: 4,
          cat: 4000,
          selected: false,
      },
      {
          label: 'notification.filter.works',
          id: 5,
          cat: 3000,
          selected: false,
      },
  ];

  export const typeIcon = (name) => {
    switch (true) {
        case (name === 1000):
            return 'snowflake';
        case (name === 1010):
            return 'cloud-meatball';
        case (name < 2000 && name >= 1000):
            return 'umbrella';
        case (name < 3000):
            return 'medkit';
        case name < 4000:
            return 'tractor';
        case name < 5000:
            return 'podcast';
        case name < 6000:
            return 'user-tag';
        case name < 8000:
            return 'fan';
        case (name === 'manual'):
            return 'human-handsdown';
        case (name === 'chimic'):
            return "flask";
        case (name === 'mechanic'):
            return 'tools';
        default:
            return 'bell';
    }
  }

  export const bgType = (code) => {
      switch (true) {
          case (code === 1000):
              return "#2170f2";
            case (code < 3000 && code >= 2000):
                return "#fd563a";
            case (code < 6000 && code >= 5000):
                return "#35ab82";
            case (code === 'manual'):
                return greenManual;
            case (code === 'chimic'):
                return chimicViolet;
            case (code === 'mechanic'):
                return greenMechanic;
            case (code === 'notification'):
                return yellowNotif;
            default:
                return "#B5B5B5"
      }
  }

  export const weatherPicto = (id) => {
    switch (id) {
      case (61459):
        return 'weather-cloudy';
      case (61457 || 61440):
        return 'weather-windy-variant';
      case (61466):
        return 'weather-partly-rainy';
      case (61463): 
        return 'weather-pouring';
      case (61465):
        return 'weather-lightning';
      case (61621):
        return 'weather-hazy';
      case (61467):
        return 'weather-snowy';
      case (61442):
        return 'weather-partly-cloudy';
      case (61448):
        return 'weather-partly-rainy';
      case (61573):
        return 'weather-windy';
      case (61554):
        return 'weather-sunny-alert';
      default:
        return 'weather-sunny'
    }
 }

 // weather fx

export const direction = [
  'S/SO',
  'SO',
  'N/NO',
  'N',
  'N/NE',
  'E',
  'S/SE',
  'S',
  'S/SO',
];

export const windOrientation = [
  'N',
  'N/NE',
  'E',
  'E/SE',
  'S',
  'S/SO',
  'O',
  'O/NO',
  'N',
];

export const degrees = [
  '0deg',
  '45deg',
  '90deg',
  '135deg',
  '180deg',
  '225deg',
  '270deg',
  '315deg',
  '360deg',
];

export const windDirection = value => {
  const convert = (value * 8) / 360;
  const arround = Math.round(convert, 0);
  const indexDirection = (arround + 8) % 8;

  return indexDirection;
};

export const getDistanceInMiles = elt => {
  return Math.round(elt * 0.621371);
};

export const findMinMax = (source) => {
  const resultmin = Math.min(...source);
  const resultmax = Math.max(...source);
  const coordinate = (resultmax - resultmin) / 2 + resultmin;

  return coordinate;
};