import React, {
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import { Route, useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { postRefreshAction } from 'common/business/actions/authAction';
import { currentDate } from 'common/utils/utils';
import Aside from './Aside';
import HeaderContent from './HeaderContent';
import { AuthContext } from '../utils/context/authContext';
import { isInPermission, showError } from '../utils/utils';
import NotAuthorizedAccess from './NotAuthorizedAccess';

const { Content } = Layout;

const PrivateRoute = ({
  component: Component, restricted, permission, ...rest
}) => {
  const { userInfos } = useSelector((state) => state.usersReducer);
  const { msg } = useSelector((state) => state.errorReducer);
  const { path, location } = rest;
  const [collapsed, setCollapsed] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [roleCodeState, setRoleCodeState] = useState(false);
  const authCtx = useContext(AuthContext);
  const { token, refreshToken, expirDate } = authCtx;
  const dispatch = useDispatch();
  const history = useHistory();

  useLayoutEffect(() => {
    if (userInfos) {
      const { roleCode } = userInfos;
      const authorizedAccess = isInPermission(roleCode, permission);
      setAuthorized(authorizedAccess);
      setRoleCodeState(roleCode);
    }
  }, [userInfos]);

  useEffect(() => {
    if (msg) {
      const { statusCode } = msg;
      if (statusCode === 401) {
        history.push('/');
      }
      showError({ msg, dispatch });
    }
  }, [msg]);

  useEffect(() => {
    const expTokenTime = expirDate - currentDate * 1000;
    const refreshTime = expTokenTime - 30000;
    setTimeout(() => {
      dispatch(postRefreshAction.request({
        accessToken: token,
        refreshToken,
      }));
    }, refreshTime);
  }, [token]);

  const renderClassName = () => {
    const result = collapsed ? 'main-container collapsed' : 'main-container';

    return result;
  };

  const renderLayout = (
    <Layout className="private-layout">
      <Aside
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
      <Layout className="private-content">
        <HeaderContent path={path} />
        <Content className={renderClassName()}>
          <Component roleCode={roleCodeState} />
        </Content>
      </Layout>
    </Layout>
  );

  const renderComponent = token && authorized ? renderLayout : <NotAuthorizedAccess reason={"Vous n'avez pas les droits d'accès ? Connectez-vous"} />;

  return (
    <Route
      path={path}
      location={location}
      render={() => renderComponent}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  restricted: PropTypes.bool.isRequired,
  permission: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PrivateRoute;
