import { Divider, PageHeader } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DomainForm from '../../shared/forms/DomainForm';

const DomainSettings = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader onBack={() => window.history.back()} title={t('settings.addDomain')} />
      <Divider />
      <DomainForm />
    </>
  );
};

export default DomainSettings;
