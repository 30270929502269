import { put, takeEvery, call } from 'redux-saga/effects';
import { BLOCKS, CURRENTBLOCK, getBlocksAction, getCurrentBlockAction, deleteBlockAction, postBlockAction, patchBlockAction, getPayloadsBlockAction, PAYLOADS } from '../actions/blocksAction';
import { getBlocksService,  deleteBlockService, postBlockService, patchBlockService, getPayloadsBlockService } from '../api/blocksService';


export function* getBlocks({ payload }) {
  const { blockId } = payload;
    try {
      if (blockId) {
        const currentBlock = yield call(getBlocksService, payload);
        yield put(getCurrentBlockAction.success(currentBlock.data));
      } else {
        const blocks = yield call(getBlocksService, payload);
        const { data } = blocks;
        yield put(getBlocksAction.success(data));
      }
    }
    catch (err) {
        yield put(getBlocksAction.failure(err));
    }
};

export function* getPayloadsBlock({ payload }) {
  try {
    const payloadsBlock = yield call(getPayloadsBlockService, payload);
    yield put(getPayloadsBlockAction.success(payloadsBlock.data));
  }
  catch (err) {
    yield put(getPayloadsBlockAction.failure(err));
  }
};

export function* getCurrentBlock({payload}) {
    try {
        yield put(getCurrentBlockAction.success(payload));
    }
    catch (err) {
        yield put(getCurrentBlockAction.failure(err));
    }
}

export function* postBlock({ payload }) {
  const { token } = payload;
    try {
      yield call(postBlockService, payload);
      yield put(postBlockAction.success("block has been created"));
      yield put (getBlocksAction.request({token: token}));
    } catch (error) {
      yield put(postBlockAction.failure(error));
    }
  }
  
  export function* patchBlock({ payload }) {
    const { token, domainId, companyId } = payload;
    try {
      yield call(patchBlockService, payload);
      yield put(patchBlockAction.success('block has been updated'));
      yield put(getBlocksAction.request({token: token, currentDomainId: domainId, companyId: companyId}));
    } catch (err) {
      yield put(patchBlockAction.failure(err));
    }
  }
  
  export function* deleteBlock({ payload }) {
    try {
      yield call(deleteBlockService, payload);
      yield put(deleteBlockAction.success('block has been deleted'));
      yield put(getBlocksAction.request());
    }
    catch (err) {
      yield put(deleteBlockAction.failure(err));
    }
  }
  
  export const sagas = {
    getBlocksSaga: takeEvery(BLOCKS.FETCH.REQUEST, getBlocks),
    getPayloadsBlockSaga: takeEvery(PAYLOADS.FETCH.REQUEST, getPayloadsBlock),
    getCurrentBlockSaga: takeEvery(CURRENTBLOCK.FETCH.REQUEST, getCurrentBlock),
    postBlockSaga: takeEvery(BLOCKS.POST.REQUEST, postBlock),
    patchBlockSaga: takeEvery(BLOCKS.PATCH.REQUEST, patchBlock),
    deleteBlockSaga: takeEvery(BLOCKS.DELETE.REQUEST, deleteBlock),
  }
