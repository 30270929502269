import {
  WiCloud,
  WiHumidity, WiStrongWind, WiThermometer, WiUmbrella, WiWindDeg, WiThermometerInternal,
} from 'react-icons/wi';

export const customIcon = {
  windy: (size) => <WiStrongWind size={size} color="#fff" />,
  direction: (size, color = '#fff') => <WiWindDeg size={size} color={color} />,
  thermometer: (size) => <WiThermometer size={size} color="#fff" />,
  thermometerH: (size) => <WiThermometerInternal size={size} color="#fff" />,
  water: (size) => <WiHumidity size={size} color="#fff" />,
  rain: (size) => <WiUmbrella size={size} color="#fff" />,
};

export const weatherIcon = {
  cloudy: (size, color) => <WiCloud size={size} color={color} />,
};
