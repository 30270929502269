import React from 'react';
import { Image, Modal } from 'antd';
import { PropTypes } from 'prop-types';
import { Slide } from 'react-slideshow-image';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'common/utils/utils';
import WeatherPanel from '../../shared/WeatherPanel';

const ModalGallery = ({
  visibleModal,
  setVisibleModal,
  currentList,
  currentImage,
}) => {
  const { t } = useTranslation();

  const renderImages = () => currentList.map((img) => (
    <React.Fragment key={img.cam}>
      <div className="info-bloc">
        <h2 className="modal-title center">
          {t('menu.sentinel')}
          {' '}
          { img.sentId }
        </h2>
        <span>
          le
          {' '}
          {formatDate({ unixDate: img.date })}
          {' '}
        </span>
        <span>
          à
          {' '}
          {formatDate({ unixHour: img.date })}
        </span>
        <WeatherPanel styleClass="weather-panel-modal" data={img} />
      </div>
      <Image
        className="full-slide"
        src={`https://app.sentinelle-vignes.fr/assets/images/sentinelles/${img.cam}`}
        alt={img.date}
        preview={false}
      />
    </React.Fragment>
  ));

  const renderSlideShow = () => (
    <Slide
      duration={100}
      slidesToShow={1}
      slidesToScroll={1}
      defaultIndex={currentImage}
      indicators={false}
      autoplay={false}
      infinite={false}
      cssClass="slide-full"
    >
      {renderImages()}
    </Slide>
  );

  return (
    <Modal
      centered
      visible={visibleModal}
      onCancel={() => setVisibleModal(false)}
      footer={null}
      width="83vw"
      height="100vh"
      className="gallery-modal"
    >
      {currentList.length && (
        <div className="slide-full">
          {renderSlideShow()}
        </div>
      )}
    </Modal>
  );
};

ModalGallery.propTypes = {
  visibleModal: PropTypes.bool.isRequired,
  setVisibleModal: PropTypes.func.isRequired,
  currentList: PropTypes.arrayOf({}).isRequired,
  currentImage: PropTypes.number.isRequired,
};

export default ModalGallery;
