import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Header } from 'antd/lib/layout/layout';
import { formatDate } from 'common/utils/utils';
import { Spin } from 'antd';

const Alerts = () => {
  const { warning } = useSelector((state) => state.warningReducer);
  const [hasWarning, setHasWarning] = useState(false);

  useEffect(() => {
    if (warning !== null) {
      setHasWarning(true);
    }
  }, [hasWarning]);

  const renderWarning = () => hasWarning && warning?.map((item) => (
    <p key={item.id}>
      {item.level}
      {' '}
      |
      {' '}
      {item.type}
      {' '}
      |
      {' '}
      {formatDate({ unixDate: item.date })}
      {' '}
      |
      {' '}
      {item.parcel}
    </p>
  ));

  return (
    <article>
      <Header>
        <span>level</span>
        <span>type</span>
        <span>date</span>
        <span>parcelle</span>
      </Header>
      {!hasWarning
        ? <Spin />
        : <div>{renderWarning()}</div>}
    </article>
  );
};

export default Alerts;
