import { Alert, Layout, Spin } from 'antd';
import { getDomainsAction } from 'common/business/actions/domainsAction';
import { getUserAction } from 'common/business/actions/usersAction';
import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../utils/context/authContext';
import { AGS } from '../../utils/utils';

const Callback = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const { userInfos } = useSelector((state) => state.usersReducer);
  const { company, roleCode } = userInfos;
  const isAGS = AGS.includes(roleCode);
  const OWNER = roleCode === 'SUPER_ADMIN';
  const USERS = roleCode === 'USER' || roleCode === 'CONSULTANT' || roleCode === 'ADMIN';

  useLayoutEffect(() => {
    if (token) {
      if (isAGS) {
        dispatch(getDomainsAction.request({ token }));
      } else if (OWNER) {
        dispatch(getDomainsAction.request({ companyId: company, token }));
      } else if (USERS) {
        console.log('user role');
      }
    }
  }, [token]);

  useEffect(() => {
    if (userInfos) {
      const dataRequest = isAGS ? { token } : { token, companyId: company };
      if (isAGS || OWNER) {
        dispatch(getUserAction.request(dataRequest));
      }
    }
    setTimeout(() => {
      history.push(isAGS ? '/dashboard' : '/domains');
      // TODO: redirect by role
    }, 300);
  }, [token]);

  return (
    <Layout className="home-bg">
      <Spin tip="Loading...">
        <Alert
          message="chargement en cours"
          description="accès à votre tableau de bord sentinelle"
          type="info"
        />
      </Spin>
    </Layout>
  );
};

export default Callback;
