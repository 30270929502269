import {
  AimOutlined,
  AppstoreAddOutlined,
  BarChartOutlined,
  MenuFoldOutlined, MenuUnfoldOutlined,
  PictureOutlined,
  PoweroffOutlined, SettingOutlined,
} from '@ant-design/icons';
import { Divider, Menu } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from 'common/assets/images/GLOBE-SENTINELLE.png';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { postLogoutAction } from 'common/business/actions/authAction';
import { PropTypes } from 'prop-types';
// import LanguageSwitch from './LanguageSwitch';
import useViewport from '../utils/hooks/useViewPort';
import { getBreakPoints } from '../utils/utils';
import IconFont from '../utils/CustomIconsAd';

const Aside = ({ collapsed, setCollapsed }) => {
  const { currentDomain } = useSelector((state) => state.domainsReducer);
  const [selectedKeys, setSelectedKeys] = useState(['1']);
  const { winds } = useSelector((state) => state.windsReducer);
  const { width } = useViewport();
  const breakPoint = getBreakPoints(width);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (breakPoint === 'tablet' || breakPoint === 'mobile') {
      setCollapsed(true);
    }
    if (breakPoint === 'mediumDesk' || breakPoint === 'largeDesk') {
      setCollapsed(false);
    }
  }, [width, setCollapsed]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleOff = () => {
    dispatch(postLogoutAction.request());
    localStorage.clear();
    history.push('/');
  };

  const getItem = (label, key, icon, path) => {
    if ((key === '2' || key === '3') && !currentDomain) {
      return null;
    }
    if (key === '4' && !winds) {
      return null;
    }
    return {
      key,
      icon,
      label: (
        <Link to={path}>
          {label}
        </Link>
      ),
    };
  };

  const topMenuItems = [
    getItem(t('menu.dashboard'), '1', <AppstoreAddOutlined />, '/dashboard'),
    getItem(t('menu.vines'), '2', <AimOutlined />, '/vignes'),
    getItem(t('menu.gallery'), '3', <PictureOutlined />, '/gallery'),
    getItem(t('menu.winds'), '4', <IconFont type="icon-si-glyph-wind-turbines" />, '/winds'),
    getItem(t('menu.statistics'), '6', <BarChartOutlined />, '/statistics'),
  ];

  const bottomMenuItems = [
    getItem(t('menu.settings'), '5', <SettingOutlined />, '/settings'),
  ];

  return (
    <Sider
      className="aside"
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
      collapsedWidth={80}
      width={200}
    >
      <div className="aside-header">
        <Link to="/">
          <img className="aside-logo" src={logo} alt="sentinelle logo" />
        </Link>
        {/* <LanguageSwitch className="menu-lang" /> */}
      </div>
      <Menu
        className="menu margin-top-high"
        mode="inline"
        inlineCollapsed={collapsed}
        items={topMenuItems}
        onSelect={(item) => setSelectedKeys([item.key])}
        selectedKeys={selectedKeys}
      />
      <div className="aside-footer">
        <Divider className="bottom-menu-separator" />
        <Menu
          className="menu margin-vertical-small"
          mode="inline"
          inlineCollapsed={collapsed}
          items={bottomMenuItems}
          onSelect={(item) => setSelectedKeys([item.key])}
          selectedKeys={selectedKeys}
        />
        <div className={`footer-pictos ${collapsed ? 'collapsed' : ''}`}>
          <PoweroffOutlined onClick={handleOff} />
          {collapsed
            ? <MenuUnfoldOutlined onClick={toggleCollapsed} />
            : <MenuFoldOutlined onClick={toggleCollapsed} />}
        </div>
      </div>
    </Sider>
  );
};

Aside.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
};

export default Aside;
