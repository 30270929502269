import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { patchTasksAction, postTasksAction } from 'common/business/actions/tasksAction';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import FormTemplate from './formTemplate';
import { renderDomainsCheckbox, renderSelectOptions, typesCategorieTask } from '../../utils/utils';
import { AuthContext } from '../../utils/context/authContext';

const TaskForm = ({ update, onClose, task }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = task;
  const vehicles = useSelector((state) => state.vehicleReducer.vehicle);
  const { blocks } = useSelector((state) => state.blocksReducer);
  const { typeTask } = useSelector((state) => state.typeTaskReducer);
  const { domains, currentDomain } = useSelector((state) => state.domainsReducer);
  const { products } = useSelector((state) => state.productsReducer);
  const { serviceProvider } = useSelector((state) => state.serviceProviderReducer);
  const { companies, currentCompany } = useSelector((state) => state.companiesReducer);
  const [currentDomainState, setCurrentDomainState] = useState(currentDomain?.id);
  const [currentCompanyState, setCurrentCompanyState] = useState(currentCompany);

  const { token } = useContext(AuthContext);
  const { t } = useTranslation();

  const activeDomains = domains.filter((domain) => domain.active);

  useEffect(() => {
    if (update) {
      if (task) {
        const {
          category,
          type,
          domainId,
          blockIds,
          presta,
          product,
          quantity,
          vehicle,
        } = task;
        form.setFieldsValue({
          category,
          type,
          blockIds,
          presta,
          product,
          quantity,
          vehicle,
          domainId,
        });
      }
    } else {
      form.resetFields();
      form.setFieldsValue({
        companyId: currentCompany,
        domainId: currentDomain.id,
      });
    }
  }, [task, update]);

  const onSuccess = () => {
    notification.success({
      message: <Trans i18nKey="task.success.title" />,
      description: <Trans i18nKey="task.success.description" />,
    });
    if (update) {
      onClose();
    } else {
      form.resetFields();
      form.setFieldsValue({
        companyId: currentCompany,
        domainId: currentDomain.id,
      });
    }
  };

  const onFinish = (event) => {
    const {
      companyId,
      domainId,
      blockIds,
      typeTaskId,
      category,
      type,
      date,
      presta,
      treatment,
      vehicle,
    } = event;
    const resultPromise = [];
    const unixDate = moment(date).unix() * 1000;
    if (update) {
      resultPromise.push(dispatch(patchTasksAction.request({
        id,
        category,
        timestamp: unixDate,
        blockIds,
        type,
        serviceProviderId: presta,
        taskType: typeTaskId,
        treatments: treatment,
        vehicleId: vehicle,
        domainId,
        source: 'web',
      })));
    } else {
      resultPromise.push(dispatch(postTasksAction.request({
        companyId,
        domainId,
        blockIds,
        taskType: typeTaskId,
        category,
        timestamp: unixDate,
        serviceProviderId: presta,
        vehicleId: vehicle,
        treatments: treatment,
        token,
      })));
    }
    return Promise.allSettled(resultPromise);
  };

  const onError = () => {
    notification.error({
      message: <Trans i18nKey="register.error.title" />,
      description: <Trans i18nKey="register.error.description.Task" />,
    });
  };

  const onCancel = () => {
    form.resetFields();
  };

  const onChangeOption = (event) => {
    setCurrentDomainState(event.target.value);
  };

  const variables = [
    {
      name: 'companyId',
      label: t('form.company'),
      type: 'select',
      change: true,
      defaultValue: currentCompany,
      selectValues: renderSelectOptions(companies, currentCompany),
      disabled: !!currentCompany,
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'form.companyPH',
    },
    {
      name: 'domainId',
      label: t('form.domains'),
      type: 'radioGroup',
      radioOptions: renderDomainsCheckbox({ currentCompanyState, activeDomains }),
      defaultValue: currentDomain?.id,
      disabled: !!currentDomain?.id,
      rules: [
        {
          required: true,
        },
      ],
      onChangeOption: (event) => onChangeOption(event),
    },
    {
      name: 'blockIds',
      label: t('task.landlot'),
      type: 'select',
      mode: 'multiple',
      selectValues: renderSelectOptions(blocks, currentDomainState, 'blocks'),
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'form.blockPH',
    },
    {
      name: 'typeTaskId',
      label: t('task.type'),
      type: 'select',
      selectValues: renderSelectOptions(typeTask),
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'form.taskPH',
    },
    {
      name: 'category',
      label: t('task.category'),
      type: 'select',
      selectValues: renderSelectOptions(typesCategorieTask),
      placeholder: 'form.categoryPH',
    },
    {
      name: 'date',
      label: t('traces.date'),
      type: 'date',
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'form.datePH',
    },
    {
      name: 'presta',
      label: t('task.presta'),
      type: 'select',
      selectValues: renderSelectOptions(serviceProvider),
      placeholder: 'form.prestaPH',
    },
    {
      name: 'vehicle',
      label: t('task.vehicle'),
      type: 'select',
      selectValues: renderSelectOptions(vehicles),
      placeholder: 'form.vehiclePH',
    },
    {
      name: 'treatment',
      label: 'task.treatment',
      type: 'groupInput',
      selectValues: renderSelectOptions(products),
    },
  ];

  const variablesItems = () => {
    if (update) {
      return variables.filter((item) => item.name !== 'password');
    }
    return variables;
  };
  return (
    <FormTemplate
      className="form"
      nameForm="register"
      useForm={form}
      variables={variablesItems()}
      onSuccess={onSuccess}
      onFinishApiCall={onFinish}
      onError={onError}
      buttonValidText="form.valid"
      buttonCancelText="form.cancel"
      onCancel={onCancel}
      setSelectedValue={setCurrentCompanyState}
    />
  );
};

TaskForm.propTypes = {
  update: PropTypes.bool,
  onClose: PropTypes.func,
  task: PropTypes.shape({
    category: PropTypes.string,
    type: PropTypes.string,
    landlot: PropTypes.string,
    presta: PropTypes.string,
    product: PropTypes.string,
    quantity: PropTypes.string,
    vehicle: PropTypes.string,
    id: PropTypes.number,
    blockIds: PropTypes.number,
    domainId: PropTypes.string,
  }),
};

TaskForm.defaultProps = {
  update: false,
  onClose: () => {},
  task: {},
};

export default TaskForm;
