import { put, takeEvery, call } from 'redux-saga/effects';
import { getWindAction, getWindPayloadsAction, getWindSerialsAction, patchWindAction, postWindAction, WIND, WINDPAYLOADS, WINDSERIALS } from '../actions/windsAction';
import { getWindPayloadsService, getWindSerialsService, getWindService, patchWindService, postWindService } from '../api/windService';

export function* getWind({ payload }) {
	try {
		const winds = yield call(getWindService, payload);
		if (winds === "No Content") {
			yield put(getWindAction.error([]));
		} else {
			yield put(getWindAction.success(winds));
		}
	} catch (err) {
		yield put(getWindAction.failure(err));
	}
}

function* getWindPayloads({ payload }) {
    const { winds } = payload;
    try {
        const windPayloads = yield call(getWindPayloadsService, payload);
        const combinedData = windPayloads.map(windPayload => {
            const wind = winds && winds.find(item => {
				return item.serial === windPayload.serial;
            });
			
            const filteredObject = {};
            for (const key in wind) {
                if (key !== 'id') {
                    filteredObject[key] = wind[key];
                }
            }
            return Object.assign(windPayload, filteredObject);
        });
        yield put(getWindPayloadsAction.success(combinedData));
    } catch (err) {
        yield put(getWindPayloadsAction.failure(err));
    }
}

export function* getWindSerials({ payload }) {
	try {
		const serials = yield call(getWindSerialsService, payload);
		yield put(getWindSerialsAction.success(serials));

	} catch (err) {
		yield put(getWindSerialsAction.failure(err))
	}
}

export function* patchWind({ payload }) {
	const { token, companyId } = payload;
	try {
		yield call(patchWindService, payload);
		const winds = yield call(getWindService, { companyId, token });
		yield put(getWindAction.success(winds));
		yield put(patchWindAction.success('updated'));
	} catch (err) {
		yield put(patchWindAction.failure(err));
	}
}
export function* postWind({ payload }) {
	try {
		yield call(postWindService, payload);
		const winds = yield call(getWindService, payload);
		yield put(getWindAction.success(winds));
		yield put(postWindAction.success('created'));
	} catch (err) {
		yield put(postWindAction.failure(err));
	}
}

export const sagas = {
	getWindSaga: takeEvery(WIND.FETCH.REQUEST, getWind),
	getWindPayloadsSaga: takeEvery(WINDPAYLOADS.FETCH.REQUEST, getWindPayloads),
	getWindSerialSaga: takeEvery(WINDSERIALS.FETCH.REQUEST, getWindSerials),
	patchWindSaga: takeEvery(WIND.PATCH.REQUEST, patchWind),
	postWindSaga: takeEvery(WIND.POST.REQUEST, postWind)
}
