import { WIND, WINDPAYLOADS, WINDSERIALS } from '../actions/windsAction';

const INITIAL_STATE = { winds: null, windpayloads: null, windState: null, state: null, error: null, serials: null,  loading: false };

const windsReducer = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;

	switch (type) {
		case WIND.FETCH.REQUEST:
			return { ...state, loading: true };
		case WIND.FETCH.SUCCESS:
			return { ...state, winds: payload, loading: false };
		case WIND.FETCH.ERROR:
			return { ...state, winds: payload, loading: false };
		case WIND.FETCH.FAILURE:
			return { ...state, loading: false };
		case WINDPAYLOADS.FETCH.REQUEST:
			return { ...state, loading: true };
		case WINDPAYLOADS.FETCH.SUCCESS:
			return { ...state, windPayloads: payload, loading: false };
		case WINDPAYLOADS.FETCH.FAILURE:
			return { ...state, loading: false };
		case WINDPAYLOADS.FETCH.ERROR:
			return { ...state, error: payload, loading: false };
		case WINDSERIALS.FETCH.REQUEST:
				return { ...state, loading: true };
		case WINDSERIALS.FETCH.SUCCESS:
				return { ...state, serials: payload, loading: false };
		case WINDSERIALS.FETCH.FAILURE:
				return { ...state, loading: false };
		case WIND.POST.REQUEST:
			return { ...state, loading: true };
		case WIND.POST.SUCCESS:
			return { ...state, windState: payload, loading: false };
		case WIND.POST.FAILURE:
			return { ...state, loading: false };
		case WIND.PATCH.REQUEST:
			return { ...state, loading: true };
		case WIND.PATCH.SUCCESS:
			return { ...state, windState: payload, loading: false };
		case WIND.PATCH.FAILURE:
			return { ...state, loading: false };
		default:
			return state;
	}
};

export default windsReducer;
