import { WARNING } from '../actions/warningAction';

const INITIAL_STATE = { warning: null, loading: false };

const warningReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case WARNING.FETCH.REQUEST:
      return {...state, loading: true};
    case WARNING.FETCH.SUCCESS:
        return {...state, warning: payload, loading: false};
    case WARNING.FETCH.FAILURE:
        return {...state, loading: false};    
    default:
      return state;
  }
};

export default warningReducer;
