/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import ReactMapGL, { Layer, Source } from 'react-map-gl';
import { lineLayer } from '../../utils/layersStyle';
import PanelControl from './PanelControl';

const MBToken = process.env.REACT_APP_MB_TOKEN;

const Traces = () => {
  const [traces, setTraces] = useState();
  const [lat, setLat] = useState(44.915761333333336);
  const [long, setLong] = useState(-0.1067966666666666);
  const [viewport, setViewport] = useState({
    pitch: 25,
    minPitch: 2,
    maxPitch: 50,
    longitude: long,
    latitude: lat,
    zoom: 17.5,
  });
  const {
    pitch, zoom, longitude, latitude,
    minPitch, maxPitch,
  } = viewport;

  const { id, type, paint } = lineLayer;

  useEffect(() => {
    setTraces(traces);
  }, [traces]);

  return (
    <section className="map-content">
      <h1>Traces</h1>
      <ReactMapGL
        asyncRender
        onViewportChange={setViewport}
        dragPan
        dragRotate
        mapboxAccessToken={MBToken}
        mapStyle={process.env.REACT_APP_MB_MAPSTYLE}
        width="100vw"
        height="100vh"
        longitude={longitude}
        latitude={latitude}
        pitch={pitch}
        maxPitch={maxPitch}
        minPitch={minPitch}
        zoom={zoom}
        maxZoom={zoom}
        minZoom={12.5}
      >
        <PanelControl
          setLat={setLat}
          setLong={setLong}
          setTraces={setTraces}
        />
        <Source id="traces" type="geojson" data={traces}>
          <Layer key="key123" beforeId="waterway-label" id={id} type={type} paint={paint} />
        </Source>
      </ReactMapGL>
    </section>
  );
};

export default Traces;
