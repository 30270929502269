import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';
const DELETE = 'DELETE';

export const LOGIN = createRequestTypes('LOGIN', [FETCH, POST, DELETE]);

export const getLoginAction = {
  request: (payload) => action(LOGIN.FETCH.REQUEST, payload),
  success: (data) => action(LOGIN.FETCH.SUCCESS, data),
  failure: (error) => action(LOGIN.FETCH.FAILURE, error),
};

export const getLogoutAction = {
  request: () => action(LOGIN.DELETE.REQUEST),
  success: (data) => action(LOGIN.DELETE.SUCCESS, data),
  failure: (error) => action(LOGIN.DELETE.FAILURE, error),
};
