import { put, takeEvery, call } from 'redux-saga/effects';
import { getCurrentCompanyAction } from '../actions/companyAction';
import { DOMAIN, CURRENTDOMAIN, getDomainsAction, getCurrentDomainAction,deleteDomainAction, postDomainAction, patchDomainAction, PAYLOADSDOMAIN, getPayloadsDomainAction } from '../actions/domainsAction';
import { getDomainsService, deleteDomainService, postDomainService, patchDomainService, getPayloadsDomainService } from '../api/domainsService';

export function* getDomains({ payload }) {
    try {
        const domains = yield call(getDomainsService, payload);
        const { data: domainsData } = domains
        if (domainsData && domainsData.length < 2 && domainsData > 0) {
          yield put (getCurrentDomainAction.success(domainsData[0]))
        }
        yield put(getDomainsAction.success(domainsData));
        
    }
    catch (err) {
        yield put(getDomainsAction.failure(err));
    }
};

export function* getCurrentDomain({ payload }) {

    try {
        yield put(getCurrentDomainAction.success(payload));

        yield put(getCurrentCompanyAction.success(payload ? payload.companyId : payload));
    }
    catch (err) {
        yield put(getCurrentDomainAction.failure(err));
    }
}

export function* getPayloadsDomain({ payload }) {
  try {
    const payloadsDomain = yield call(getPayloadsDomainService, payload);
    yield put(getPayloadsDomainAction.success(payloadsDomain.data));
  }
  catch (err) {
    yield put(getPayloadsDomainAction.failure(err));
  }
};

export function* postDomain({ payload }) {
  const { token } = payload;

  try {
    const res = yield call(postDomainService, payload);
    if (res.data) {
      yield put(postDomainAction.success("domain registered"));
      const domains = yield call(getDomainsService, {token: token});
      yield put(getDomainsAction.success(domains.data))
    }
  } catch (err) {
    yield put(postDomainAction.failure('une erreur est survenue lors de la création du domaine'));
  }
}

  export function* patchDomain({ payload }) {
    try {
      yield call(patchDomainService, payload);
      yield put(patchDomainAction.success('domain updated'));
      const domain = yield call(getDomainsService, {});
      yield put(getDomainsAction.success(domain))
    } catch (err) {
      yield put(patchDomainAction.failure(err));
    }
  }
  
  export function* deleteDomain({ payload }) {
    try {
      yield call(deleteDomainService, payload);
      yield put(deleteDomainAction.success('domain deleted'));
      const domain = yield call(getDomainsService, {});
      yield put(getDomainsAction.success(domain))
    } catch (err) {
      yield put(deleteDomainAction.failure(err));
    }
  }
  
  export const sagas = {
    getDomainsSaga: takeEvery(DOMAIN.FETCH.REQUEST, getDomains),
    getCurrentDomainSaga: takeEvery(CURRENTDOMAIN.FETCH.REQUEST, getCurrentDomain),
    getPayloadsDomainSaga: takeEvery(PAYLOADSDOMAIN.FETCH.REQUEST, getPayloadsDomain),
    postDomainSaga: takeEvery(DOMAIN.POST.REQUEST, postDomain),
    patchDomainSaga: takeEvery(DOMAIN.PATCH.REQUEST, patchDomain),
    deleteDomain: takeEvery(DOMAIN.DELETE.REQUEST, deleteDomain),
  }
