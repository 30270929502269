import api from '../../utils/query';

export const getUserInfosService = (token) => {
    const url = 'token/domains';
    
    return api.get({url, token});
}

export const getUsersService = (data) => {
    const { companyId, token, userId, domainId } = data;
    const urlDefault = domainId ? `${companyId}/domain/${domainId}/user` : companyId ? `${companyId}/user` : 'users';
    const urlUserId = `${companyId}/domain/${domainId}/user/${userId}`;
    const url = userId ? urlUserId : urlDefault;
    return api.get({url, token});
}

export const postUsersService = (data) => {
    const { companyId } = data;
    const url = `${companyId}/user`;

    return api.create(url, data);
}

export const patchUserService = (data) => {
    const { companyId, currentUserId, token } = data;
    const url = `${companyId}/user/${currentUserId}`;
    return api.update(url, data);
}

export const deleteUsersService = (data) => {
    const { companyId, currentUserId, token } = data;
    const url = `${companyId}/user/${currentUserId}`;

    return api.del(url,token);
}
