import React, {
  createContext, useState, useLayoutEffect, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import en from 'common/lang/en.json';
import fr from 'common/lang/fr.json';
import frFR from 'antd/lib/locale/fr_FR';
import enUS from 'antd/lib/locale/en_US';
import { ConfigProvider } from 'antd';
import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n';

export const Context = createContext();
const loc = frFR;
const intlLang = i18n.language;

const IntlProviderWrapper = ({ children }) => {
  const [locale, setLocale] = useState(loc);
  const storeLang = useSelector((state) => state.loginReducer.currentUser?.language);
  const [currentLang, setCurrentLang] = useState(storeLang ? `${storeLang}` : intlLang);
  const [messages, setMessages] = useState(currentLang);

  useLayoutEffect(() => {
    if (loc !== frFR) {
      setCurrentLang(en);
    } else {
      setCurrentLang(fr);
    }
  }, []);

  useEffect(() => {
    if (storeLang) {
      setCurrentLang(storeLang);
      if (storeLang === 'fr') {
        setMessages(fr);
        setLocale(frFR);
      } else {
        setMessages(en);
        setLocale(enUS);
      }
    }
  }, [storeLang]);

  const selectLanguage = (element) => {
    const newLocale = element;
    setLocale(newLocale);
    if (newLocale === enUS) {
      setMessages(en);
      setCurrentLang('en');
    } else {
      setMessages(fr);
      setCurrentLang('fr');
    }
  };

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <ConfigProvider locale={locale}>
        <I18nextProvider locale={currentLang} messages={messages}>
          {children}
        </I18nextProvider>
      </ConfigProvider>
    </Context.Provider>
  );
};

IntlProviderWrapper.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default IntlProviderWrapper;
