import { AUTH, REFRESH, LOGOUT } from '../actions/authAction';

const INITIAL_STATE = { token: null, error: null, loading: false };

const authReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH.POST.REQUEST:
      return { ...state, error: null, loading: true };
    case AUTH.POST.SUCCESS:
      return { ...state, token: payload, loading: false };
    case AUTH.POST.FAILURE:
      return { ...state, loading: false};
    case AUTH.POST.ERROR:
      return { ...state, error: 'login error',loading: false};
    case REFRESH.POST.REQUEST:
      return { ...state, loading: true };
    case REFRESH.POST.SUCCESS:
      return { ...state, token: payload, loading: false };
    case REFRESH.POST.FAILURE:
      return { ...state, loading: false};
    case LOGOUT:
      return { ...state, token: null };
    default:
      return state;
  }
};

export default authReducer;
