import { put, takeEvery, call } from 'redux-saga/effects';
import { SENTINELLES, CURRENTSENT, getSentinellesAction, getCurrentSentAction, deleteSentinelleAction, postSentinelleAction, patchSentinelleAction, getSentinellesByBlockAction, SENTBYBLOCK } from '../actions/sentinellesAction';
import { getSentinellesService, deleteSentinelleService, postSentinelleService, patchSentinelleService, getSentinellesByBlockService } from '../api/sentinellesService';

export function* getSentinelles({ payload }) {
    try {
        const sentinelles = yield call(getSentinellesService, payload);
        
        yield put(getSentinellesAction.success(sentinelles.data));
        
    }
    catch (err) {
        yield put(getSentinellesAction.failure(err));
    }
}

export function* getSentinellesByBlock({ payload }) {
  try {
    const sentinellesByBlock = yield call(getSentinellesByBlockService, payload);

    yield put(getSentinellesByBlockAction.success(sentinellesByBlock.data));
  }
  catch (err) {

  }
}

export function* getCurrentSent({ payload }) {
    try {
        yield put(getCurrentSentAction.success(payload));
    }
    catch (err) {
        yield put(getCurrentSentAction.failure(err));
    }
}

export function* postSentinelle({ payload }) {
  const { companyId, domainId, token } = payload;
    try {
      yield call(postSentinelleService, payload);
      yield put(postSentinelleAction.success("sentinelle registered"));
      yield put(getSentinellesAction.request({ companyId, domainId, token }));
    } catch (error) {
      yield put(postSentinelleAction.failure(error));
    }
  }
  
  export function* patchSentinelle({ payload }) {
    try {
      yield call(patchSentinelleService, payload);
      yield put(patchSentinelleAction.success('sentinelle updated'));
      yield put(getSentinellesAction.request());
    } catch (err) {
      yield put(patchSentinelleAction.failure(err));
    }
  }
  
  export function* deleteSentinelle({ payload }) {
    try {
      yield call(deleteSentinelleService, payload);
      yield put(deleteSentinelleAction.success('sentinelle deleted'));
      yield put(getSentinellesAction.request());
    }
    catch (err) {
      yield put(deleteSentinelleAction.failure(err));
    }
  }

  export const sagas = {
    getSentinellesSaga: takeEvery(SENTINELLES.FETCH.REQUEST, getSentinelles),
    getSentinelleByBlockSaga: takeEvery(SENTBYBLOCK.FETCH.REQUEST, getSentinellesByBlock),
    getCurrentSentSaga: takeEvery(CURRENTSENT.FETCH.REQUEST, getCurrentSent),
    postSentinelleSaga: takeEvery(SENTINELLES.POST.REQUEST, postSentinelle),
    patchSentinelleSaga: takeEvery(SENTINELLES.PATCH.REQUEST, patchSentinelle),
    deleteSentinelleSaga: takeEvery(SENTINELLES.DELETE.REQUEST, deleteSentinelle),
  }
