import { Divider, PageHeader } from 'antd';
import React from 'react';
import ServiceProviderForm from '../../shared/forms/ServiceProviderForm';

const ServiceProvider = () => (
  <>
    <PageHeader onBack={() => window.history.back()} title="Ajouter un prestataires" />
    <Divider />
    <ServiceProviderForm />
  </>
);

export default ServiceProvider;
