import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatDate, warningFilters } from 'common/utils/utils';
import DashboardList from './DashboardStyle';
import TableTemplate from '../../shared/TableTemplate';

const WarningList = () => {
  const { warning } = useSelector((state) => state.warningReducer);
  const { blocks } = useSelector((state) => state.blocksReducer);
  const { t } = useTranslation();

  const renderCategoryWarning = (text) => {
    const categoryWarning = warningFilters.find((item) => item.cat === text);
    const { label } = categoryWarning;
    const labelText = t(label);
    return labelText;
  };

  const renderBlockName = (text) => {
    const block = blocks.find((item) => item.id === text);
    const { name } = block;
    return name;
  };

  const renderDateHour = (text) => {
    const date = formatDate({ shortDate: text / 1000 });
    const hour = formatDate({ unixHour: text });
    return (
      <>
        <p>{date}</p>
        <span>{hour}</span>
      </>
    );
  };
  const columns = [
    {
      title: 'type',
      dataIndex: 'category',
      key: 'category',
      render: (text) => renderCategoryWarning(text),
      width: 55,
    },
    {
      title: 'niveau',
      dataIndex: 'alertLevel',
      key: 'alertLevel',
      render: (text) => <span>{text}</span>,
      width: 60,
    },
    {
      title: 'prévue le',
      dataIndex: 'predictiveTimestamp',
      key: 'predictiveTimestamp',
      render: (text) => renderDateHour(text),
      width: 100,
      align: 'center',
    },
    {
      title: '°C (humide)',
      dataIndex: 'indic',
      key: 'indic',
      render: (text) => (
        <span>
          {text}
          C
        </span>
      ),
      width: 90,
      align: 'center',
    },
    {
      title: 'parcelle',
      dataIndex: 'blockId',
      key: 'blockId',
      render: (text) => renderBlockName(text),
      width: 150,
      align: 'center',
    },
  ];
  return (
    <>
      <DashboardList width="700">
        <div className="header-list">
          <h3>Alertes/Notifications</h3>
        </div>
        <Divider />
        <TableTemplate columns={columns} data={warning || []} />
      </DashboardList>
    </>
  );
};

export default WarningList;
