import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';

export const PICASSO = createRequestTypes('PICASSO', [FETCH]);
export const PICASSOGLOBAL = createRequestTypes('PICASSOGLOBAL', [FETCH]);

export const getPicassoAction = {
  request: payload => action(PICASSO.FETCH.REQUEST, payload),
  success: data => action(PICASSO.FETCH.SUCCESS, data),
  failure: error => action(PICASSO.FETCH.FAILURE, error),
};

export const getPicassoGlobalAction = {
  request: payload => action(PICASSOGLOBAL.FETCH.REQUEST, payload),
  success: data => action(PICASSOGLOBAL.FETCH.SUCCESS, data),
  failure: error => action(PICASSOGLOBAL.FETCH.FAILURE, error),
};
