import api from '../../utils/query';

export const getAllCompaniesService = (data) => {
    const { token } = data;
    return api.get({ token })
}

export const postCompanyService = (data) => {
    return api.create("owner", data)
};
