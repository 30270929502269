import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';

export const WARNING = createRequestTypes('WARNING', [FETCH]);

export const getWarningAction = {
  request: payload => action(WARNING.FETCH.REQUEST, payload),
  success: data => action(WARNING.FETCH.SUCCESS, data),
  failure: error => action(WARNING.FETCH.FAILURE, error),
};
