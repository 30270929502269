import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import logoSent from 'common/assets/images/SENTINELLE-VERT.png';
import logoAlcom from 'common/assets/images/ALCOM.png';
import Logo from './Logo';
import SignIn from '../components/home/SignIn';

const NotAuthorizedAccess = ({ reason }) => (
  <Layout className="public-layout">
    <Layout className="public-content">
      <Content>
        <Layout className="home-bg">
          <Layout className="home-bg">
            <Logo className="logo-img" src={logoSent} alt="sentinelle" />
            <article className="align-center">
              <h1>Ooooups! Que se passe-t-il ?</h1>
              <p className="grey-text large-font-text">
                {reason}
              </p>
            </article>
            <SignIn />
            <div className="footer-logo">
              <Logo src={logoAlcom} alt="Alcom technologies" />
            </div>
          </Layout>
        </Layout>
      </Content>
    </Layout>
  </Layout>
);
NotAuthorizedAccess.propTypes = {
  reason: PropTypes.string.isRequired,
};

export default NotAuthorizedAccess;
