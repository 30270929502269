import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import Map, { Marker, NavigationControl, Source } from 'react-map-gl';
import markerImage from 'common/assets/images/GLOBE-SENTINELLE.png';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { findMinMax } from 'common/utils/utils';
import { buildValuesColor, showLayers } from '../../utils/layersStyle';
import HoverInfos from '../../shared/HoverInfos';
import EmptyData from '../../shared/EmptyData';

const MBToken = process.env.REACT_APP_MB_TOKEN;
const MAPSTYLE = process.env.REACT_APP_MB_MAPSTYLE;

const GlobalMap = () => {
  const { picassoGlobal, loading } = useSelector((state) => state.picassoReducer);
  const { sentinelles } = useSelector((state) => state.sentinellesReducer);
  const { temperatureGeoJson, sentinelsGeoJson } = picassoGlobal;
  const [hoverInfos, setHoverInfos] = useState(false);
  const [valuesColor, setValuesColor] = useState([]);
  const [offsetMap, setOffsetMap] = useState(null);
  const [long, setLong] = useState(null);
  const [lat, setLat] = useState(null);
  const { t } = useTranslation();
  const mapRef = useRef();

  useEffect(() => {
    if (temperatureGeoJson) {
      const { features } = temperatureGeoJson;
      const allLat = features.map((elt) => elt.properties.latitude);
      const allLong = features.map((elt) => elt.properties.longitude);
      const latitude = findMinMax(allLat);
      const longitude = findMinMax(allLong);

      buildValuesColor({ elt: features, setValuesColor });

      setLong(longitude);
      setLat(latitude);
    }
  }, [temperatureGeoJson]);

  const onHover = useCallback((event) => {
    const { features, srcEvent: { offsetX, offsetY } } = event;
    const hoveredFeature = features && features[0];
    setOffsetMap(hoveredFeature ? { feature: hoveredFeature, x: offsetX, y: offsetY } : null);
  }, []);

  const handleHover = (marker) => {
    const { sentinelID, temperature, humidity } = marker;
    const sentId = sentinelles.filter((sentinel) => sentinel.id === sentinelID);
    setHoverInfos({
      sentinelId: sentId[0].serial, temperature, humidity, offsetMap,
    });
  };

  return (
    <>
      {loading ? (
        <div className="globalMap-spin">
          {t('mapBoxView.loadingMap')}
          <Spin />
        </div>
      ) : (
        <section className="globalMap">
          {temperatureGeoJson?.features && long && lat && sentinelsGeoJson ? (
            <Map
              ref={mapRef}
              initialViewState={{
                zoom: 14.5,
                minZoom: 13,
                maxZoom: 20,
              }}
              pitch={55}
              bearing={0}
              latitude={lat}
              longitude={long}
              interactive
              pitchWithRotate
              antialias
              preserveDrawingBuffer
              optimizeForTerrain
              dragPan
              scrollZoom
              doubleClickZoom
              mapboxAccessToken={MBToken}
              mapStyle={MAPSTYLE}
              style={{
                width: '100%',
                height: '600px',
              }}
              onMouseEnter={onHover}
              interactiveLayersIds={['sentinelle']}
              reuseMaps
            >
              <NavigationControl
                position="top-right"
                showCompass={false}
              />
              <Source id="heatmap-data" type="geojson" data={temperatureGeoJson}>
                {showLayers(valuesColor)}
              </Source>
              {sentinelsGeoJson?.features.map((marker) => {
                const { geometry, properties } = marker;
                const { coordinates } = geometry;
                const nothing = () => null;
                return (
                  <Marker
                    key={marker.id}
                    longitude={coordinates[0]}
                    latitude={coordinates[1]}
                    offsetTop={-20}
                    offsetLeft={-10}
                  >
                    <img
                      src={markerImage}
                      alt="marker"
                      width={10}
                      onMouseEnter={() => handleHover(properties)}
                      onMouseLeave={() => setHoverInfos(null)}
                      onFocus={nothing}
                    />
                  </Marker>
                );
              })}
              {hoverInfos && <HoverInfos hoverInfos={hoverInfos} />}
            </Map>
          ) : (
            <EmptyData />
          )}
        </section>
      )}
    </>
  );
};

export default GlobalMap;
