import { Spin } from 'antd';
import { getCurrentDomainAction } from 'common/business/actions/domainsAction';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

const Domains = () => {
  const { domains, currentDomain } = useSelector((state) => state.domainsReducer);
  const { userInfos } = useSelector((state) => state.usersReducer);

  const numberDomains = domains.length;
  const { roleCode } = userInfos;
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = (item) => {
    dispatch(getCurrentDomainAction.request(item));
  };

  useEffect(() => {
    if (domains.length < 2 && domains.length > 0) {
      handleClick(domains[0]);
      setTimeout(() => {
        history.push('/dashboard');
      }, 2000);
    }
  }, [currentDomain]);

  return (
    <div className="domains">
      {(roleCode === 'AGS_ADMIN' || roleCode === 'AGS_USER' || roleCode === 'SUPER_ADMIN') && domains.length > 1 && (
        <Link to="/dashboard" onClick={() => handleClick(null)}>Tous</Link>
      )}
      {
        domains
          ? domains.map((domain) => {
            if (domain.active) {
              return (
                <Link
                  key={domain?.id}
                  to="/dashboard"
                  onClick={() => handleClick(domain)}
                >
                  <img
                    className={numberDomains > 3 ? 'domain-picture-small' : 'domain-picture-large'}
                    src={`https://app.sentinelle-vignes.fr/assets/images/domains/${domain.logo}`}
                    alt={domain?.name}
                  />
                </Link>
              );
            }
            return null;
          }) : <Spin />
      }
    </div>
  );
};

export default Domains;
