import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { setUniqueList } from 'common/utils/utils';
import Chart from './Chart';
import PredictionList from './PredictionList';

const PredictionStatistic = ({ forecast }) => {
  const { t } = useTranslation();
  const actuallyDate = moment().startOf('day').valueOf();
  const dateIn7Days = moment(actuallyDate).add(6, 'days').valueOf();
  const weatherMeasuresIds = setUniqueList(forecast, 'weatherMeasureId');
  const forecastsList = weatherMeasuresIds.map(
    (item) => forecast.filter((id) => id.weatherMeasureId === item.weatherMeasureId),
  );
  const renderChartByWeatherId = () => forecastsList.map((item) => (
    <Chart
      key={item[0].id}
      data={item}
      startDateValue={actuallyDate}
      endDateValue={dateIn7Days}
      typeGraph="prediction"
    />
  ));

  return (
    <section className="container">
      <PredictionList
        forecast={forecast}
        startDateValue={actuallyDate}
        endDateValue={dateIn7Days}
      />
      <article>
        <h3>{t('module.statistics.predictionCard.title')}</h3>
        <p>{t('module.statistics.predictionCard.firstParag')}</p>
        <p>{t('module.statistics.predictionCard.secondParag')}</p>
      </article>
      {renderChartByWeatherId()}
    </section>
  );
};

PredictionStatistic.propTypes = {
  forecast: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.number.isRequired,
    dewt2m: PropTypes.number.isRequired,
    t2m: PropTypes.number.isRequired,
  })),
};

PredictionStatistic.defaultProps = {
  forecast: [],
};

export default PredictionStatistic;
