import { DOMAIN, CURRENTDOMAIN, PAYLOADSDOMAIN } from '../actions/domainsAction';

const INITIAL_STATE = { domains: null, loading: false, currentDomain: null, payloads: null };

const domainsReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case DOMAIN.FETCH.REQUEST:
      return {...state, loading: true};
    case DOMAIN.FETCH.SUCCESS:
        return {...state, domains: payload, loading: false};
    case DOMAIN.FETCH.FAILURE:
        return {...state, loading: false};
    case CURRENTDOMAIN.FETCH.REQUEST:
      return {...state, loading: true};
    case CURRENTDOMAIN.FETCH.SUCCESS:
        return {...state, currentDomain: payload, loading: false};
    case CURRENTDOMAIN.FETCH.FAILURE:
        return {...state, loading: false};
    case PAYLOADSDOMAIN.FETCH.REQUEST:
          return {...state, loading: true};
    case PAYLOADSDOMAIN.FETCH.SUCCESS:
          return {...state, payloads: payload, loading: false};
    case PAYLOADSDOMAIN.FETCH.FAILURE:
          return {...state, loading: false};
    case DOMAIN.POST.REQUEST:
        return { ...state, loading: true };
    case DOMAIN.POST.SUCCESS:
        return { ...state, loading: false };
    case DOMAIN.POST.FAILURE:
        return { ...state, loading: false };
    case DOMAIN.PATCH.REQUEST:
      return { ...state, loading: true };
    case DOMAIN.PATCH.SUCCESS:
      return { ...state, loading: false };
    case DOMAIN.PATCH.FAILURE:
      return { ...state, loading: false };
    case DOMAIN.DELETE.REQUEST:
      return { ...state, loading: true };
    case DOMAIN.DELETE.SUCCESS:
      return { ...state, domaindeleted: payload, loading: false};
      case DOMAIN.DELETE.FAILURE:
        return { ...state, loading: false};
    default:
      return state;
  }
};

export default domainsReducer;
