import React from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDate, setUniqueList } from 'common/utils/utils';
import {
  bluePrimary, chimicViolet, greyPrimary, yellowNotif, redPrimary, greenMechanic, orangeColor,
} from 'common/assets/style/defaultStyle';

const Chart = ({
  data, typeGraph, startDateValue, endDateValue,
}) => {
  const { t } = useTranslation();

  const getSensorValue = (payload, type) => {
    const { sensors } = payload;
    return sensors.find((sensor) => sensor.type === type).value;
  };

  const lines = {
    temperature: [
      {
        dataKey: 'soilTemperature',
        stroke: '#767872',
        name: t('module.statistics.tempSoil'),
        activeDot: true,
      },
      {
        dataKey: 'airTemperature',
        stroke: '#e7c53a',
        name: t('module.statistics.tempAir'),
        activeDot: true,
      },
    ],
    humidity: [
      {
        dataKey: 'soilHumidity',
        stroke: '#0541dd',
        name: t('module.statistics.humiditySoil'),
        activeDot: true,
      },
      {
        dataKey: 'airHumidity',
        stroke: '#08a2ff',
        name: t('module.statistics.humidityAir'),
        activeDot: true,
      },
    ],
    prediction: [
      {
        dataKey: 't2m',
        stroke: '#767872',
        name: t('module.statistics.dryBulbTemp'),
        activeDot: true,
      },
      {
        dataKey: 'dewt2m',
        stroke: '#964872',
        name: t('module.statistics.tempHumidity'),
        activeDot: true,
      },
      {
        dataKey: 'thresholdTemp',
        stroke: '#08a2ff',
        name: t('module.statistics.thresholdTempHumidity'),
      },
      {
        dataKey: 'thresholdTempHumidity',
        stroke: '#0541dd',
        name: t('module.statistics.thresholdTemp'),
      },
    ],
  };

  const generateSentinelsLines = (datas) => {
    const sentinelsList = setUniqueList(datas, 'sentinelPositionName');
    const sentinelsColors = [
      chimicViolet, orangeColor, greenMechanic,
      redPrimary, yellowNotif, bluePrimary, greyPrimary,
    ];

    const linesSent = sentinelsList.map((sentinel, index) => {
      const { sentinelPositionName } = sentinel;
      return {
        dataKey: `airTemperature_${sentinelPositionName}`,
        stroke: sentinelsColors[index],
        name: `T° air sentinelle ${sentinelPositionName}`,
      };
    });

    return linesSent;
  };

  const renderData = () => {
    if (typeGraph === 'prediction') {
      return data?.map((item) => {
        const { date, t2m, dewt2m } = item;
        return {
          time: date,
          t2m,
          dewt2m,
          thresholdTemp: 1,
          thresholdTempHumidity: 3,
        };
      });
    }
    if (typeGraph === 'sentinelsTemp') {
      const sentPayloads = data.map((payload) => {
        const { timestamp, sentinelPositionName } = payload;
        const payloadData = {
          time: timestamp,
          sentinelPositionName,
          [`airTemperature_${sentinelPositionName}`]: getSensorValue(payload, 'air_temperature'),
        };
        return payloadData;
      });
      lines.sentinelsTemp = generateSentinelsLines(sentPayloads);
      return sentPayloads;
    }
    if (typeGraph === 'temperature') {
      return data?.map((payload) => {
        const { timestamp } = payload;
        const payloadData = {
          time: timestamp,
          soilTemperature: getSensorValue(payload, 'soil_temperature'),
          airTemperature: getSensorValue(payload, 'air_temperature'),
        };
        return payloadData;
      });
    }
    return data?.map((payload) => {
      const { timestamp } = payload;
      const payloadData = {
        time: timestamp,
        soilHumidity: getSensorValue(payload, 'soil_relative_humidity'),
        airHumidity: getSensorValue(payload, 'air_relative_humidity'),
      };
      return payloadData;
    });
  };

  return (
    <LineChart width={700} height={400} data={renderData()}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="time"
        type="number"
        domain={[startDateValue, endDateValue]}
        interval={0}
        tickMargin={5}
        tickFormatter={(time) => moment(time).format('DD/MM')}
      />
      <YAxis />
      <Tooltip
        labelFormatter={(time) => formatDate({ unixDate: time }).concat(' à ', formatDate({ unixHour: time }))}
      />
      <Legend verticalAlign="top" height={45} />
      {lines[typeGraph]?.map((line) => (
        <Line
          type="monotone"
          dataKey={line.dataKey}
          stroke={line.stroke}
          name={line.name}
          key={line.name}
          dot={!!line.activeDot}
          connectNulls
        />
      ))}
    </LineChart>
  );
};

Chart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  startDateValue: PropTypes.number.isRequired,
  endDateValue: PropTypes.number.isRequired,
  typeGraph: PropTypes.string.isRequired,
};

export default Chart;
