import { TRACES } from '../actions/tracesAction';

const INITIAL_STATE = { traces: null, tracesState: null, loading: false };

const tracesReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case TRACES.FETCH.REQUEST:
      return {...state, loading: true};
    case TRACES.FETCH.SUCCESS:
        return {...state, traces: payload, loading: false};
    case TRACES.FETCH.FAILURE:
        return {...state, loading: false};    
    case TRACES.POST.REQUEST:
      return {...state, loading: true};
    case TRACES.POST.SUCCESS:
        return {...state, tracesState: payload, loading: false};
    case TRACES.POST.FAILURE:
        return {...state, loading: false};    
    default:
      return state;
  }
};

export default tracesReducer;
