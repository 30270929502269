import React, { useState } from 'react';
import {
  Button, Form, Input, Select, Checkbox, Switch,
  DatePicker, Space, InputNumber, Upload, Radio,
} from 'antd';
import { LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';
import 'common/assets/style/form.scss';
import { PropTypes } from 'prop-types';
import TextareaForm from './TextareaForm';
import InputPasswordForm from './inputPasswordForm';

const FormTemplate = (props) => {
  const [load, setLoad] = useState(false);
  // const [imageUrl, setImageUrl] = useState();
  const { t } = useTranslation();
  const dateFormat = t('form.formatDate');

  const {
    nameForm,
    className,
    variables,
    useForm,
    buttonValidText,
    onFinishApiCall,
    onSuccess,
    layout,
    onError,
    loading,
    setSelectedValue,
    setSelectedOption,
    setDisabled,
    hiddenButton,
    // buttonCancelText,
    // onCancel,
  } = props;
  const formLayout = layout || 'vertical';
  const { Option } = Select;
  const [success, setSuccess] = useState(true);

  const handleChange = (value) => {
    setSelectedValue(value);
    setSelectedOption(null);
    setDisabled(false);
  };

  const renderInput = (elt) => {
    const {
      placeholder,
      type,
      mode,
      data,
      showSearch,
      selectValues,
      maxLength,
      showCount,
      text,
      prefix,
      checkOptions,
      inputs,
      defaultValue,
      initialValue,
      onChange,
      onChangeOption,
      change,
      disabled,
      defaultActiveFirstOption,
      radioOptions,
      selectedOption,
      hidden,
      active,
      disabledDate,
      //   name,
    } = elt;
    if (type === 'select') {
      return (
        <Select
          mode={mode || null}
          placeholder={t(placeholder)}
          showSearch={!!showSearch}
          optionFilterProp="children"
          defaultValue={defaultValue}
          initialvalue={initialValue}
          defaultActiveFirstOption={defaultActiveFirstOption}
          disabled={disabled}
          onChange={change ? handleChange : null}
          filterOption={(input, option) => option
            .children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) => optionA
            .children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
        >
          {selectValues.map((values) => (
            <Option key={values.value} value={values.value}>
              {data === 'personal'
                ? values.text
                : t(values.text)}
            </Option>
          ))}
        </Select>
      );
    }
    if (type === 'radioGroup') {
      return (
        <Radio.Group
          options={radioOptions}
          onChange={onChangeOption}
          defaultValue={defaultValue}
          value={selectedOption}
          disabled={disabled}
        />
      );
    }
    if (type === 'groupInput') {
      return (
        <Form.List name={elt.name}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...field }) => (
                <Space key={key} style={{ display: 'flex' }} align="baseline">
                  {elt.name === 'treatment' && (
                    <>
                      <Form.Item fieldKey={field.fieldKey} isListField={field.isListField} name={[name, 'product_id']}>
                        <Select placeholder={t('task.form.product')} style={{ width: 200 }}>
                          {selectValues.map((values) => (
                            <Option key={values.value} value={values.value}>
                              {t(values.text)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item fieldKey={field.fieldKey} isListField={field.isListField} name={[name, 'quantity']}>
                        <Input placeholder={t('task.quantity')} />
                      </Form.Item>
                    </>
                  ) }
                  {(elt.name === 'point' || elt.name === 'points') && inputs.map((input) => (
                    <Form.Item key={input} fieldKey={field.fieldKey} isListField={field.isListField} name={[name, input]} rules={[{ required: true, message: `Missing ${input}` }]}>
                      <InputNumber placeholder={t(`block.${input}`)} controls={false} />
                    </Form.Item>
                  ))}
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button className="button-link" type="primary" style={{ width: '180px' }} onClick={() => add()} block icon={<PlusOutlined />}>
                  {elt.name === 'treatment' ? t('task.treatment') : t('block.coordinates') }
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

      );
    }
    if (type === 'uploadImage') {
      const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
      };
      // const beforeUpload = (file) => {
      //   const isLt2M = file.size / 500 / 500 < 2;
      //   if (!isLt2M) {
      //     message.error('L"image doit faire 500 par 500');
      //   }
      //   return isLt2M;
      // };
      const handleChangeImage = (info) => {
        if (info.file.status === 'uploading') {
          setLoad(true);
          return;
        }
        if (info.file.status === 'done') {
          getBase64(info.file.originFileObj, () => {
            setLoad(false);
            // setImageUrl(url);
          });
        }
      };
      const uploadButton = (
        <div>
          {load ? <LoadingOutlined /> : <PlusOutlined />}
          <span>
            Download
          </span>
        </div>
      );
      // const showRemoveIcon = true;
      return (
        <Upload
          name="logo"
          listType="picture"
          // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          className="avatar-uploader"
          // showUploadList={{ showRemoveIcon, showPreviewIcon: false, previewIcon: false }}
          maxCount={1}
          // beforeUpload={beforeUpload}
          onChange={handleChangeImage}
        >
          {/* {imageUrl ?
            <img src={imageUrl} alt="logo" style={{ width: '100%' }} /> : uploadButton} */}
          {uploadButton}
        </Upload>
      );
    }
    if (type === 'textarea') {
      return <TextareaForm maxLength={maxLength} showCount={showCount} text={text} />;
    }
    if (type === 'checkbox') {
      return <Checkbox><Trans i18nKey={text} /></Checkbox>;
    }
    if (type === 'switch') {
      return <Switch checked={active} onChange={onChange} />;
    }
    if (type === 'switchGroup' && !hidden) {
      return <Checkbox.Group options={checkOptions} onChange={onChange} />;
    }
    if (type === 'password') {
      return <InputPasswordForm prefix={prefix} placeholder={placeholder} />;
    }
    if (type === 'date') {
      return <DatePicker className="form-input" disabledDate={disabledDate} format={dateFormat} onChange={onChange} />;
    }
    if (type === 'numeric') {
      return <InputNumber className="form-input" min={1} max={100} controls={false} placeholder={placeholder} />;
    }
    return (
      <Input
        className="form-input"
        prefix={prefix}
        placeholder={t(placeholder)}
        autoComplete="off"
        type={type}
      />
    );
  };

  const handleError = (error) => {
    if (error?.response?.data) {
      const { errors } = error.response.data;

      if (errors) {
        const keys = Object.keys(errors);
        console.log('🚀 ~ file: formTemplate.jsx ~ line 86 ~ handleError ~ keys', keys);
      }
    }
  };

  const onFinish = (values) => {
    const request = {};
    for (let i = 0; i < variables.length; i += 1) {
      request[variables[i].name] = values[variables[i].name];
    }
    onFinishApiCall(request)
      .then((array) => {
        if (array) {
          for (let i = 0; i < array.length; i += 1) {
            if ((array[i].status === 'rejected')) {
              handleError(array[i].reason);
              setSuccess(false);
            }
            if (!loading) {
              setSuccess(true);
            }
          }
        }
        setTimeout(() => {
          if (success) {
            onSuccess();
          }
        }, 1000);
      })
      .catch((error) => {
        handleError(error);
        if (onError) {
          console.log('formTemplate onFinish error : ', error);
        }
      });
  };

  return (
    <div className="form-container">
      <div className="form-block">
        <Form
          layout={formLayout}
          name={nameForm}
          labelAlign="top"
          requiredMark
          colon={false}
          initialvalues={{ remember: true }}
          onFinish={onFinish}
          className={className}
          form={useForm}
        >
          {variables.map((variable) => {
            if (!variable.noUpdate && !variable.hidden) {
              return (
                <Form.Item
                  key={variable.name}
                  label={t(variable.label)}
                  extra={t(variable.extra)}
                  name={variable.name}
                  hasFeedback
                  rules={variable.rules}
                  help={variable.help}
                  validateTrigger={['onChange', 'onBlur']}
                  hidden={variable.hidden}
                >
                  {renderInput(variable)}
                </Form.Item>
              );
            } return null;
          })}
          { !hiddenButton
            ? (
              <Form.Item key="As--32" className="submit" name="validate">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="valid-form-button button-link"
                >
                  {t(buttonValidText)}
                </Button>
              </Form.Item>
            ) : null}
        </Form>
      </div>
    </div>

  );
};

FormTemplate.propTypes = {
  nameForm: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  variables: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  useForm: PropTypes.shape().isRequired,
  buttonValidText: PropTypes.string.isRequired,
  onFinishApiCall: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  layout: PropTypes.string,
  onError: PropTypes.func,
  loading: PropTypes.bool,
  setSelectedValue: PropTypes.func,
  setSelectedOption: PropTypes.func,
  setDisabled: PropTypes.func,
  hiddenButton: PropTypes.bool,
//   buttonCancelText: PropTypes.string.isRequired,
//   onCancel: PropTypes.func,
};

FormTemplate.defaultProps = {
  layout: 'vertical',
  onError: () => {},
  loading: true,
  hiddenButton: false,
  setSelectedValue: () => {},
  setSelectedOption: () => {},
  setDisabled: () => {},
//   onCancel: () => {},
};

export default FormTemplate;
