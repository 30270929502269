import { GEOSENTPOSITIONS, SENTPOSITIONS } from '../actions/sentPositionsAction';

const INITIAL_STATE = { sentPositions: null, loading: false, geojson: null };

const sentPositionsReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case SENTPOSITIONS.FETCH.REQUEST:
      return {...state, loading: true};
    case SENTPOSITIONS.FETCH.SUCCESS:
        return {...state, sentPositions: payload, loading: false};
    case SENTPOSITIONS.FETCH.FAILURE:
        return {...state, loading: false};    
    case SENTPOSITIONS.PATCH.REQUEST:
      return {...state, loading: true};
    case SENTPOSITIONS.PATCH.SUCCESS:
        return {...state, update: payload, loading: false};
    case SENTPOSITIONS.PATCH.FAILURE:
        return {...state, loading: false};    
    case SENTPOSITIONS.POST.REQUEST:
      return {...state, loading: true};
    case SENTPOSITIONS.POST.SUCCESS:
        return {...state, create: payload, loading: false};
    case SENTPOSITIONS.POST.FAILURE:
        return {...state, loading: false};    
    case GEOSENTPOSITIONS.FETCH.REQUEST:
      return {...state, loading: true};
    case GEOSENTPOSITIONS.FETCH.SUCCESS:
        return {...state, geojson: payload, loading: false};
    case GEOSENTPOSITIONS.FETCH.FAILURE:
        return {...state, loading: false};    
    default:
      return state;
  }
};

export default sentPositionsReducer;
