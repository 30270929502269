import React, { useContext, useState } from 'react';
import { Spin, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getWeatherForecastAction } from 'common/business/actions/weatherAction';
import { useTranslation } from 'react-i18next';
import ObservationForm from '../../shared/forms/ObservationForm';
import PredictionStatistic from './PredictionStatistic';
import ObservationStatistic from './ObservationStatistic';
import { AuthContext } from '../../utils/context/authContext';

const Statistics = () => {
  const { currentDomain } = useSelector((state) => state.domainsReducer);
  const { loading: loadingBlock } = useSelector((state) => state.blocksReducer);
  const { loading: loadingDomain } = useSelector((state) => state.domainsReducer);
  const { loading: loadingWeather, forecast } = useSelector((state) => state.weatherReducer);
  const [showObservationForm, setShowObservationForm] = useState(true);
  const [showObservationStatistic, setShowObservationStatistic] = useState(false);
  const [extractMethod, setExtractMethod] = useState(null);
  const [startDateValue, setStartDateValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);
  const [blockNameValue, setBlockNameValue] = useState(null);
  const [domainNameValue, setDomainNameValue] = useState(null);
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSwitchFormAndObservations = () => {
    setShowObservationForm(!showObservationForm);
    setShowObservationStatistic(!showObservationStatistic);
  };

  const handleSubmitAlLPredictions = () => {
    dispatch(getWeatherForecastAction.request({ currentDomain: currentDomain.id, token }));
  };

  const handleTabChange = (key) => {
    if (key === '2' && currentDomain) {
      handleSubmitAlLPredictions();
    }
  };

  const items = [
    {
      key: '1',
      label: 'Observations',
      children: (
        <>
          <p>{t('module.statistics.observationsInfo')}</p>
          {showObservationForm && (
            <ObservationForm
              handleSwitchFormAndObservations={handleSwitchFormAndObservations}
              extractMethod={extractMethod}
              setStartDateValue={setStartDateValue}
              setEndDateValue={setEndDateValue}
              setBlockNameValue={setBlockNameValue}
              setDomainNameValue={setDomainNameValue}
              setExtractMethod={setExtractMethod}
              loadingDomain={loadingDomain}
            />
          )}
          {loadingBlock || loadingDomain ? (
            <Spin className="spin" />
          ) : showObservationStatistic && (
            <ObservationStatistic
              handleSwitchFormAndObservations={handleSwitchFormAndObservations}
              extractMethod={extractMethod}
              startDateValue={startDateValue}
              endDateValue={endDateValue}
              blockNameValue={blockNameValue}
              domainNameValue={domainNameValue}
            />
          )}
        </>
      ),
    },
    {
      key: '2',
      label: 'Prédictions',
      disabled: !currentDomain,
      children: (
        <>
          <p>{t('module.statistics.predictionsInfo')}</p>
          {loadingWeather && !forecast ? (
            <Spin className="spin" />
          ) : (<PredictionStatistic forecast={forecast} />
          )}
        </>
      ),
    },
  ];

  return (
    <Tabs className="statistics" defaultActiveKey="1" onChange={handleTabChange} items={items} />
  );
};

export default Statistics;
