import api from '../../utils/query';

export const getWindService = (data) => {
	const { companyId, token } = data;
	const url = companyId ? `company/${companyId}/winds` : 'wind/all'
	return api.get({ url, endpoint: 'aeolus', token })
}

export const getWindPayloadsService = (data) => {	
	const { companyId, token } = data;	
	const url = `company/${companyId}/wind/payloads`
	return api.get({ url, endpoint: 'aeolus', token });
};

export const getWindSerialsService = (data) => {
	const { token } = data
	const url = 'wind/serials_details';
	return api.get({ url , endpoint: 'aeolus', token })
}

export const postWindService = (data) => {
	const url = 'company/wind';
	return api.createAeolus(url, data)
};

export const patchWindService = data => {
	const { roleCode, serial } = data;
	const url = `company/role/${roleCode}/wind/${serial}`;
	return api.updateAeolus(url, data);
};

