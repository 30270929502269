import { put, takeEvery, call } from 'redux-saga/effects';
import { getProductsAction, PRODUCTS, deleteProductsAction, patchProductsAction, postProductsAction } from '../actions/productsAction';
import { getProductsService, deleteProductsService, patchProductsService, postProductsService } from '../api/productsService';

export function* getProducts({ payload }) {
    try {
        const products = yield call(getProductsService, payload);
        yield put(getProductsAction.success(products.data));
    }
    catch (err) {
        yield put(getProductsAction.failure(err));
    }
};

export function* postProducts({ payload }) {
  const { token } = payload;
    try {
      yield call(postProductsService, payload);
      yield put(postProductsAction.success("product registered"));
      const products = yield call(getProductsService, {token: token});
      yield put(getProductsAction.success(products.data))
    } catch (error) {
      yield put(postProductsAction.failure(error));
    }
  }
  
  export function* patchProducts({ payload }) {
    const { token } = payload;
    try {
      yield call(patchProductsService, payload);
      yield put(patchProductsAction.success('product updated'));
      const products = yield call(getProductsService, {token: token});
      yield put(getProductsAction.success(products.data))
    } catch (err) {
      yield put(patchProductsAction.failure(err));
    }
  }
  
export function* deleteProducts({ payload }) {
  const { token } = payload;
    try {
      yield call(deleteProductsService, payload);
      yield put(deleteProductsAction.success("product delete"));
      const products = yield call(getProductsService, {token});
      yield put(getProductsAction.success(products.data));
    }
    catch (err) {
      yield put(deleteProductsAction.failure(err));
    }
  }

  export const sagas = {
    getProductsSaga: takeEvery(PRODUCTS.FETCH.REQUEST, getProducts),
    postProductsSaga: takeEvery(PRODUCTS.POST.REQUEST, postProducts),
    patchProductsSaga: takeEvery(PRODUCTS.PATCH.REQUEST, patchProducts),
    deleteProductsSaga: takeEvery(PRODUCTS.DELETE.REQUEST, deleteProducts),
  }
