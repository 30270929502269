import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';
const PATCH = 'PATCH';
const DELETE = 'DELETE';

export const BLOCKS = createRequestTypes('BLOCKS', [FETCH, POST, PATCH, DELETE]);
export const CURRENTBLOCK = createRequestTypes('CURRENTBLOCK', [FETCH]);
export const PAYLOADS = createRequestTypes('PAYLOADS', [FETCH]);

export const getBlocksAction = {
  request: payload => action(BLOCKS.FETCH.REQUEST, payload),
  success: data => action(BLOCKS.FETCH.SUCCESS, data),
  failure: error => action(BLOCKS.FETCH.FAILURE, error),
};

export const getCurrentBlockAction = {
  request: payload => action(CURRENTBLOCK.FETCH.REQUEST, payload),
  success: data => action(CURRENTBLOCK.FETCH.SUCCESS, data),
  failure: error => action(CURRENTBLOCK.FETCH.FAILURE, error),
};

export const getPayloadsBlockAction = {
  request: payload => action(PAYLOADS.FETCH.REQUEST, payload),
  success: data => action(PAYLOADS.FETCH.SUCCESS, data),
  failure: error => action(PAYLOADS.FETCH.FAILURE, error),
};

export const postBlockAction = {
  request: (payload) => action(BLOCKS.POST.REQUEST, payload),
  success: (data) => action(BLOCKS.POST.SUCCESS, data),
  failure: (error) => action(BLOCKS.POST.FAILURE, error),
};
export const patchBlockAction = {
  request: (payload) => action(BLOCKS.PATCH.REQUEST, payload),
  success: (data) => action(BLOCKS.PATCH.SUCCESS, data),
  failure: (error) => action(BLOCKS.PATCH.FAILURE, error),
};
export const deleteBlockAction = {
  request: (id) => action(BLOCKS.DELETE.REQUEST, id),
  success: (data) => action(BLOCKS.DELETE.SUCCESS, data),
  failure: (error) => action(BLOCKS.DELETE.FAILURE, error),
};
