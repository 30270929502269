import { put, takeEvery, call } from 'redux-saga/effects';
import { getTracesAction, postTracesAction, TRACES } from '../actions/tracesAction';
import { getTracesService, postTracesService } from '../api/tracesService';

export function* getTraces({ payload }) {
    try {
        const traces = yield call(getTracesService, payload);
        const sortedTraces = traces.sort((a,b) => b.date - a.date )
        yield put(getTracesAction.success(sortedTraces))
        
    }
    catch (err) {
        yield put(getTracesAction.failure(err))
    }
};

export function* postTrace({ payload }) {
    const { currentDomainId, source } = payload;
    try {
        yield call(postTracesService, payload);
        yield put(postTracesAction.success("trace registered"));
        yield put(getTracesAction.request({currentDomainId, source}));
    }
    catch (err) {
        yield put(postTracesAction.failure(err));
    }
}

export const sagas = {
    getTracesSaga: takeEvery(TRACES.FETCH.REQUEST, getTraces),
    postTraceSaga: takeEvery(TRACES.POST.REQUEST, postTrace)
}
