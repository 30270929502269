import api from '../../utils/query';

export const getSentinellesService = (data) => {
    const { companyId, currentDomainId, token } = data;

    const allSent = companyId ? `${companyId}/sentinel` : 'sentinel';
    const urlDomain = `${companyId}/domain/${currentDomainId}/sentinel`;
    const url = currentDomainId ? urlDomain : allSent;

    return api.get({url, token});
}

export const getSentinellesByBlockService = (data) => {
    const { companyId, currentDomainId, currentBlockId, sentinelId, token } = data;

    const url = sentinelId ?
        `${companyId}/domain/${currentDomainId}/block/${currentBlockId}/sentinel/${sentinelId}`
        : `${companyId}/domain/${currentDomainId}/block/${currentBlockId}/sentinel/`;

    return api.get({ url, token });
}

export const postSentinelleService = (data) => {
    const { companyId, domainId, blockId } = data;
    const url = `${companyId}/domain/${domainId}/block/${blockId}/sentinel`;
    return api.create(url, data);
}

export const patchSentinelleService = (data) => {
    const {id, domainID, name, blockID, long, lat, alt} = data;
    const url = "sentinelles";
    return api.update(url, data);
}

export const deleteSentinelleService = (id) => {
    const sentinelles = id.toString();
    return api.del("sentinelles",sentinelles);
}
