import { VEHICLE } from '../actions/vehiclesAction';

const INITIAL_STATE = { vehicle: null, loading: false };

const vehicleReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case VEHICLE.FETCH.REQUEST:
      return {...state, loading: true};
    case VEHICLE.FETCH.SUCCESS:
        return {...state, vehicle: payload, loading: false};
    case VEHICLE.FETCH.FAILURE:
        return {...state, loading: false};
    case VEHICLE.POST.REQUEST:
        return { ...state, loading: true };
    case VEHICLE.POST.SUCCESS:
        return { ...state, loading: false };
    case VEHICLE.POST.FAILURE:
        return { ...state, loading: false };
    case VEHICLE.PATCH.REQUEST:
      return { ...state, loading: true };
    case VEHICLE.PATCH.SUCCESS:
      return { ...state, loading: false };
    case VEHICLE.PATCH.FAILURE:
      return { ...state, loading: false };
    case VEHICLE.DELETE.REQUEST:
      return { ...state, loading: true };
    case VEHICLE.DELETE.SUCCESS:
      return { ...state, vehicledeleted: payload, loading: false};
    case VEHICLE.DELETE.FAILURE:
      return { ...state, loading: false};
    default:
      return state;
  }
};

export default vehicleReducer;

