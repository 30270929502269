import { put, takeEvery, call } from 'redux-saga/effects';
import { getPicassoAction, getPicassoGlobalAction, PICASSO, PICASSOGLOBAL } from '../actions/picassoAction';
import { getSentPositionsToGeojsonAction } from '../actions/sentPositionsAction';
import { getWeatherAction } from '../actions/weatherAction';
import { getPicassoGlobalService, getPicassoService } from '../api/picassoService';

  
export function* getPicasso({ payload }) {
    const { type } = payload;
    try {
        const picasso = yield call(getPicassoService, payload);
        const { weatherMeasureData, temperatureGeoJson, sentinelsGeoJson } = picasso.data;
        if (type === 4) {
            yield put(getPicassoAction.success(temperatureGeoJson))
            yield put(getWeatherAction.success(weatherMeasureData))
            yield put (getSentPositionsToGeojsonAction.success(sentinelsGeoJson));
        }
        if (type === 3) {
            yield put (getPicassoAction.success(picasso.data))
        }
    }
    catch (err) {
        yield put(getPicassoAction.failure(err))
    }
};

export function* getPicassoGlobal({ payload }) {
    const globalGeoJson = {
        type: "FeatureCollection",
        sentinelsGeoJson: {
            type: "FeatureCollection",
            features: []
        },
        temperatureGeoJson: {
            type: "FeatureCollection",
            features: []
        }
    };

    try {
        const picassoGlobal = yield call(getPicassoGlobalService, payload);
        const { data } = picassoGlobal;
        let counterId = 0;

        data.forEach(item => {
            if (item) {
                const { sentinelsGeoJson, temperatureGeoJson } = item;
                sentinelsGeoJson.features.forEach(feature => {
                    feature.id = ++counterId
                    globalGeoJson.sentinelsGeoJson.features.push(feature);
                });
                temperatureGeoJson.features.forEach(feature => {
                    feature.id = ++counterId;
                    globalGeoJson.temperatureGeoJson.features.push(feature);
                });
            }
        });
        yield put(getPicassoGlobalAction.success(globalGeoJson))
    }
    catch (err) {
        yield put(getPicassoGlobalAction.failure(err))
    }
};



export function* getPicassoSaga() {
    yield takeEvery(PICASSO.FETCH.REQUEST, getPicasso),
    yield takeEvery(PICASSOGLOBAL.FETCH.REQUEST, getPicassoGlobal)
}
