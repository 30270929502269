import { LOGIN } from '../actions/loginAction';

const INITIAL_STATE = { currentUser: null, loading: false };

const loginReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN.FETCH.REQUEST:
      return { ...state, loading: true };
    case LOGIN.FETCH.SUCCESS:
      return { ...state, currentUser: payload, loading: false };
    case LOGIN.FETCH.FAILURE:
      return { ...state, loading: false };
    case LOGIN.DELETE.REQUEST:
      return { ...state, loading: true };
    case LOGIN.DELETE.SUCCESS:
      return INITIAL_STATE;
    case LOGIN.DELETE.FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default loginReducer;
