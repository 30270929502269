import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';
const PATCH = 'PATCH';
const POST = 'POST';

export const WIND = createRequestTypes('WIND', [FETCH, PATCH, POST]);
export const WINDPAYLOADS = createRequestTypes('WINDPAYLOADS', [FETCH, POST, PATCH]);
export const WINDSERIALS = createRequestTypes('WINDSERIALS', [FETCH]);

export const getWindAction = {
	request: payload => action(WIND.FETCH.REQUEST, payload),
	success: data => action(WIND.FETCH.SUCCESS, data),
	error: data => action(WIND.FETCH.ERROR, data),
	failure: error => action(WIND.FETCH.FAILURE, error),
};
export const getWindPayloadsAction = {
	request: payload => action(WINDPAYLOADS.FETCH.REQUEST, payload),
	success: data => action(WINDPAYLOADS.FETCH.SUCCESS, data),
	failure: error => action(WINDPAYLOADS.FETCH.FAILURE, error),
	error: error => action(WINDPAYLOADS.FETCH.ERROR, error),
};
export const getWindSerialsAction = {
	request: payload => action(WINDSERIALS.FETCH.REQUEST, payload),
	success: data => action(WINDSERIALS.FETCH.SUCCESS, data),
	failure: error => action(WINDSERIALS.FETCH.FAILURE, error),
};
export const postWindAction = {
	request: payload => action(WIND.POST.REQUEST, payload),
	success: data => action(WIND.POST.SUCCESS, data),
	failure: error => action(WIND.POST.FAILURE, error),
};
export const patchWindAction = {
	request: payload => action(WIND.PATCH.REQUEST, payload),
	success: data => action(WIND.PATCH.SUCCESS, data),
	failure: error => action(WIND.PATCH.FAILURE, error),
};
