import { Divider, PageHeader } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TracesForm from '../../shared/forms/TracesForm';

const TracesSettings = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader onBack={() => window.history.back()} title={t('traces.form.title')} />
      <Divider />
      <TracesForm />
    </>
  );
};

export default TracesSettings;
