const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const ERROR = 'ERROR';

const createRequestTypes = (base, operations) => operations.reduce((acc, op) => {
  acc[op] = [REQUEST, SUCCESS, FAILURE, ERROR].reduce(
    (subacc, type) => ({
      ...subacc,
      [type]: `${base}_${op}_${type}`,
    }),
    {},
  );
  return acc;
}, {});

export default createRequestTypes;
