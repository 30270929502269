import { SERVICEPROVIDER } from '../actions/serviceProviderAction';

const INITIAL_STATE = { serviceProvider: null, serviceProviderState: null, loading: false };

const serviceProviderReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case SERVICEPROVIDER.FETCH.REQUEST:
      return {...state, loading: true};
    case SERVICEPROVIDER.FETCH.SUCCESS:
        return {...state, serviceProvider: payload, loading: false};
    case SERVICEPROVIDER.FETCH.FAILURE:
        return {...state, loading: false};
    case SERVICEPROVIDER.POST.REQUEST:
        return { ...state, loading: true };
    case SERVICEPROVIDER.POST.SUCCESS:
        return { ...state, serviceProviderState: payload, loading: false };
    case SERVICEPROVIDER.POST.FAILURE:
        return { ...state, loading: false };
    case SERVICEPROVIDER.PATCH.REQUEST:
        return { ...state, loading: true };
    case SERVICEPROVIDER.PATCH.SUCCESS:
        return { ...state, serviceProviderState: payload, loading: false };
    case SERVICEPROVIDER.PATCH.FAILURE:
        return { ...state, loading: false };
    case SERVICEPROVIDER.DELETE.REQUEST:
        return { ...state, loading: true };
    case SERVICEPROVIDER.DELETE.SUCCESS:
        return { ...state, serviceProviderState: payload, loading: false};
    case SERVICEPROVIDER.DELETE.FAILURE:
        return { ...state, loading: false};
    default:
      return state;
  }
};

export default serviceProviderReducer;
