import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { patchProductsAction, postProductsAction } from 'common/business/actions/productsAction';
import { PropTypes } from 'prop-types';
import FormTemplate from './formTemplate';
import { AuthContext } from '../../utils/context/authContext';
import { onCancel, onError, onSuccess } from '../../utils/formFx';

const ProductForm = ({ update, onClose, product }) => {
  const { loading } = useSelector((state) => state.productsReducer);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = product;
  const { t } = useTranslation();
  const authCtx = useContext(AuthContext);
  const { token } = authCtx;

  useEffect(() => {
    if (update) {
      if (product) {
        const {
          name,
        } = product;
        form.setFieldsValue({
          name,
        });
      }
    } else {
      form.resetFields();
    }
  }, [update, product]);

  const onFinish = ({
    name,
  }) => {
    const resultPromise = [];
    if (update) {
      resultPromise.push(dispatch(patchProductsAction.request({
        productId: id, name, source: 'web', token,
      })));
    } else {
      resultPromise.push(dispatch(postProductsAction.request({
        name, token,
      })));
    }
    return Promise.allSettled(resultPromise);
  };

  const variables = [
    {
      name: 'name',
      label: t('product.name'),
      rules: [
        {
          required: true,
          min: 2,
          message: t('form.inputRequired'),
        },
      ],
      placeholder: t('product.form.name'),
    },
  ];

  const variablesItems = () => {
    if (update) {
      return variables.filter((item) => item.name !== 'password');
    }
    return variables;
  };
  return (
    <>
      <p>Les produits seront enregistrés dans une base commune</p>
      <FormTemplate
        className="form"
        nameForm="register"
        useForm={form}
        variables={variablesItems()}
        onSuccess={() => onSuccess({
          loading, update, form, onClose, messageTxt: 'product.success.title', descriptionTxt: 'product.success.descriptionUpdate',
        })}
        onFinishApiCall={onFinish}
        onError={() => onError({ messageTxt: 'product.error.title', descriptionTxt: 'product.error.description' })}
        buttonValidText="form.valid"
        buttonCancelText="form.cancel"
        onCancel={() => onCancel(form)}
        loading={loading}
      />
    </>
  );
};

ProductForm.propTypes = {
  update: PropTypes.bool,
  onClose: PropTypes.func,
  product: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
};

ProductForm.defaultProps = {
  update: false,
  onClose: () => {},
  product: {},
};

export default ProductForm;
