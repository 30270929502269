import { SENTINELLES, CURRENTSENT, SENTBYBLOCK, POSITION } from '../actions/sentinellesAction';

const INITIAL_STATE = { sentinelles: null, loading: false, sentinelleState: null, blockSentinelles: null, positionState: null };

const sentinellesReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case SENTINELLES.FETCH.REQUEST:
      return {...state, loading: true};
    case SENTINELLES.FETCH.SUCCESS:
        return {...state, sentinelles: payload, loading: false};
    case SENTINELLES.FETCH.FAILURE:
        return {...state, loading: false};
    case SENTBYBLOCK.FETCH.REQUEST:
      return {...state, loading: true};
    case SENTBYBLOCK.FETCH.SUCCESS:
        return {...state, blockSentinelles: payload, loading: false};
    case SENTBYBLOCK.FETCH.FAILURE:
        return {...state, loading: false};
    case CURRENTSENT.FETCH.REQUEST:
      return {...state, loading: true};
    case CURRENTSENT.FETCH.SUCCESS:
      return {...state, currentSentinelle: payload, loading: false};
    case CURRENTSENT.FETCH.FAILURE:
      return {...state, loading: false};
    case SENTINELLES.POST.REQUEST:
        return { ...state, loading: true };
    case SENTINELLES.POST.SUCCESS:
        return { ...state, loading: false, sentinelleState: payload };
    case SENTINELLES.POST.FAILURE:
        return { ...state, loading: false };
    case POSITION.POST.REQUEST:
        return { ...state, loading: true };
    case POSITION.POST.SUCCESS:
        return { ...state, loading: false, positionState: payload };
    case POSITION.POST.FAILURE:
        return { ...state, loading: false };
    case SENTINELLES.PATCH.REQUEST:
        return { ...state, loading: true };
    case SENTINELLES.PATCH.SUCCESS:
        return { ...state, loading: false, sentinelleState: payload };
    case SENTINELLES.PATCH.FAILURE:
        return { ...state, loading: false };
    case SENTINELLES.DELETE.REQUEST:
        return { ...state, loading: true };
    case SENTINELLES.DELETE.SUCCESS:
        return { ...state, sentinelleState: payload, loading: false};
    case SENTINELLES.DELETE.FAILURE:
        return { ...state, loading: false};
    default:
      return state;
  }
};

export default sentinellesReducer;
