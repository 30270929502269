import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const POST = 'POST';

export const AUTH = createRequestTypes('AUTH', [POST]);
export const REFRESH = createRequestTypes('REFRESH', [POST]);
export const LOGOUT = "LOGOUT";

export const postAuthAction = {
    request: (id) => action(AUTH.POST.REQUEST, id),
    success: (data) => action(AUTH.POST.SUCCESS, data),
    failure: (error) => action(AUTH.POST.FAILURE, error),
    error: (err) => action(AUTH.POST.ERROR, err),
};

export const postRefreshAction = {
  request: (id) => action(REFRESH.POST.REQUEST, id),
    success: (data) => action(REFRESH.POST.SUCCESS, data),
    failure: (error) => action(REFRESH.POST.FAILURE, error),
}

export const postLogoutAction = {
  request: () => ({type: LOGOUT})
}
