import { all } from 'redux-saga/effects';
import { postAuthSaga, postRefreshSaga } from './authSaga';
import { getLoginSaga, getLogoutSaga } from './loginSaga';
import { getWarningSaga } from './warningSaga';
import { getWeatherForecastSaga } from './weatherSaga';
import { getPicassoSaga } from './picassoSaga';
import { sagas as vehiclesSagas } from './vehiclesSaga';
import { sagas as serviceProviderSagas } from './serviceProviderSaga';
import { sagas as blocksSagas } from './blocksSaga';
import { sagas as domainsSagas } from './domainsSaga';
import { sagas as productsSagas } from './productsSaga';
import { sagas as sentinellesSagas } from './sentinellesSaga';
import { sagas as tasksSagas } from './tasksSaga';
import { sagas as typeTaskSagas } from './typeTaskSaga';
import { sagas as usersSagas } from './usersSaga';
import { sagas as tracesSagas } from './tracesSaga';
import { sagas as windsSagas } from './windSaga';
import { sagas as imagesSagas } from './imagesSaga';
import { sagas as companiesSaga } from './companySaga';
import { sagas as positionSaga } from './sentPositionsSaga';

export default function* rootSaga() {
	yield all({
		...vehiclesSagas,
		...serviceProviderSagas,
		...blocksSagas,
		...domainsSagas,
		...productsSagas,
		...sentinellesSagas,
		...tasksSagas,
		...typeTaskSagas,
		...usersSagas,
		...tracesSagas,
		...windsSagas,
		...imagesSagas,
		...companiesSaga,
		...positionSaga,
	});
	yield all([
		postAuthSaga(),
		postRefreshSaga(),
		getLoginSaga(),
		getLogoutSaga(),
		getWarningSaga(),
		getWeatherForecastSaga(),
		getPicassoSaga(),
	]);
}
