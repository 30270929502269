import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { networkErrorDismissAction } from 'common/business/actions/errorAction';
import { postLogoutAction } from 'common/business/actions/authAction';

const ErrorComponent = () => {
  const dispatch = useDispatch();
  // const history = useHistory();

  const handleClick = () => {
    dispatch(networkErrorDismissAction());
    dispatch(postLogoutAction.request(null));
    localStorage.clear();
    // history.push('/');
  };
  return (
    <Layout className="home-bg">
      <article>
        <p>
          Vous n&apos;avez pas encore toutes les données nécessaires
          pour afficher un tableau de bord.
          Contactez votre administrateur.
        </p>
        <Link to="/" onClick={handleClick}>retour</Link>
      </article>
    </Layout>
  );
};

export default ErrorComponent;
