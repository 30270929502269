import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';
const PATCH = 'PATCH';
const DELETE = 'DELETE';

export const DOMAIN = createRequestTypes('DOMAIN', [FETCH, POST, PATCH, DELETE]);
export const CURRENTDOMAIN = createRequestTypes('CURRENTDOMAIN', [FETCH]);
export const PAYLOADSDOMAIN = createRequestTypes('PAYLOADSDOMAIN', [FETCH]);

export const getDomainsAction = {
  request: payload => action(DOMAIN.FETCH.REQUEST, payload),
  success: data => action(DOMAIN.FETCH.SUCCESS, data),
  failure: error => action(DOMAIN.FETCH.FAILURE, error),
};
export const getCurrentDomainAction = {
  request: payload => action(CURRENTDOMAIN.FETCH.REQUEST, payload),
  success: data => action(CURRENTDOMAIN.FETCH.SUCCESS, data),
  failure: error => action(CURRENTDOMAIN.FETCH.FAILURE, error),
};
export const getPayloadsDomainAction = {
  request: payload => action(PAYLOADSDOMAIN.FETCH.REQUEST, payload),
  success: data => action(PAYLOADSDOMAIN.FETCH.SUCCESS, data),
  failure: error => action(PAYLOADSDOMAIN.FETCH.FAILURE, error),
};
export const postDomainAction = {
  request: (payload) => action(DOMAIN.POST.REQUEST, payload),
  success: (data) => action(DOMAIN.POST.SUCCESS, data),
  failure: (error) => action(DOMAIN.POST.FAILURE, error),
  error: (error) => action(DOMAIN.POST.ERROR, error),
};
export const patchDomainAction = {
  request: (payload) => action(DOMAIN.PATCH.REQUEST, payload),
  success: (data) => action(DOMAIN.PATCH.SUCCESS, data),
  failure: (error) => action(DOMAIN.PATCH.FAILURE, error),
};
export const deleteDomainAction = {
  request: (id) => action(DOMAIN.DELETE.REQUEST, id),
  success: (data) => action(DOMAIN.DELETE.SUCCESS, data),
  failure: (error) => action(DOMAIN.DELETE.FAILURE, error),
};
