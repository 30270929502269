import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore, PURGE } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import AsyncStorage from '@react-native-community/async-storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootSaga from '../sagas/rootSaga';
import authReducer from '../reducers/authReducer';
import errorReducer from '../reducers/errorReducer';
import loginReducer from '../reducers/loginReducer';
import usersReducer from '../reducers/usersReducer';
import warningReducer from '../reducers/warningReducer';
import tracesReducer from '../reducers/tracesReducer';
import tasksReducer from '../reducers/tasksReducer';
import typeTaskReducer from '../reducers/typeTaskReducer';
import blocksReducer from '../reducers/blocksReducer';
import imagesReducer from '../reducers/imagesReducer';
import sentPositionsReducer from '../reducers/sentPositionsReducer';
import weatherReducer from '../reducers/weatherReducer';
import domainsReducer from '../reducers/domainsReducer';
import vehicleReducer from '../reducers/vehiclesReducer';
import sentinellesReducer from '../reducers/sentinellesReducer';
import productsReducer from '../reducers/productsReducer';
import windsReducer from '../reducers/windsReducer';
import serviceProviderReducer from '../reducers/serviceProviderReducer';
import picassoReducer from '../reducers/picassoReducer';
import companiesReducer from '../reducers/companyReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();
const middleware = composeWithDevTools(applyMiddleware(sagaMiddleware, logger));

const appReducer = combineReducers({
	authReducer,
	errorReducer,
	loginReducer,
	usersReducer,
	warningReducer,
	tracesReducer,
	tasksReducer,
	typeTaskReducer,
	blocksReducer,
	imagesReducer,
	sentPositionsReducer,
	weatherReducer,
	domainsReducer,
	sentinellesReducer,
	vehicleReducer,
	productsReducer,
	serviceProviderReducer,
	windsReducer,
	picassoReducer,
	companiesReducer,
	
});

const rootReducer = (state, action) => {
	if (action.type === 'LOGIN.DELETE.SUCCESS') {
		localStorage.clear();
		AsyncStorage.clear();
	}
	return appReducer(state, action);
};

const persistConfig = {
	key: 'root',
	storage: AsyncStorage,
	stateReconciler: autoMergeLevel2,
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, middleware);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
// persistor.purge();
