import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';

export const WEATHER = createRequestTypes('WEATHER', [FETCH]);
export const FORECAST = createRequestTypes('FORECAST', [FETCH]);

export const getWeatherAction = {
  request: payload => action(WEATHER.FETCH.REQUEST, payload),
  success: data => action(WEATHER.FETCH.SUCCESS, data),
  failure: error => action(WEATHER.FETCH.FAILURE, error),
};
export const getWeatherForecastAction = {
  request: payload => action(FORECAST.FETCH.REQUEST, payload),
  success: data => action(FORECAST.FETCH.SUCCESS, data),
  failure: error => action(FORECAST.FETCH.FAILURE, error),
};
