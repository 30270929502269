import Styled from 'styled-components';

export const ColorPanel = Styled.li`
    background-color: ${({ color }) => color};
    border-radius: 4px;
`;

export const LayerPanel = Styled.li`
    color: #333;
`;
