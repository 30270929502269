import { NETWORK_ERROR_DISMISS, NETWORK_ERROR } from "../actions/errorAction";

const INITIAL_STATE = { msg: null };

const errorReducer =  (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch(type) {
        case NETWORK_ERROR:
            return { msg: payload };
        case NETWORK_ERROR_DISMISS:
            return INITIAL_STATE;
        default:
            return state;
    }
};


export default errorReducer;