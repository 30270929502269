import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';
const PATCH = 'PATCH';
const DELETE = 'DELETE';

export const SERVICEPROVIDER = createRequestTypes('SERVICEPROVIDER', [FETCH, POST, PATCH, DELETE]);

export const getServiceProviderAction = {
  request: payload => action(SERVICEPROVIDER.FETCH.REQUEST, payload),
  success: data => action(SERVICEPROVIDER.FETCH.SUCCESS, data),
  failure: error => action(SERVICEPROVIDER.FETCH.FAILURE, error),
};
export const postServiceProviderAction = {
    request: (payload) => action(SERVICEPROVIDER.POST.REQUEST, payload),
    success: (data) => action(SERVICEPROVIDER.POST.SUCCESS, data),
    failure: (error) => action(SERVICEPROVIDER.POST.FAILURE, error),
  };
export const patchServiceProviderAction = {
  request: (payload) => action(SERVICEPROVIDER.PATCH.REQUEST, payload),
  success: (data) => action(SERVICEPROVIDER.PATCH.SUCCESS, data),
  failure: (error) => action(SERVICEPROVIDER.PATCH.FAILURE, error),
};
export const deleteServiceProviderAction = {
    request: (id) => action(SERVICEPROVIDER.DELETE.REQUEST, id),
    success: (data) => action(SERVICEPROVIDER.DELETE.SUCCESS, data),
    failure: (error) => action(SERVICEPROVIDER.DELETE.FAILURE, error),
  };
  