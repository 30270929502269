import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { formatDate, windDirection, windOrientation } from 'common/utils/utils';
import { getWeatherForecastAction } from 'common/business/actions/weatherAction';
import { Divider, Select } from 'antd';
import { CSVLink } from 'react-csv';
import TableTemplate from '../../shared/TableTemplate';
import { customIcon } from '../../utils/Icons';
import { AuthContext } from '../../utils/context/authContext';

const { Option } = Select;

const PredictionList = ({ startDateValue, endDateValue, forecast }) => {
  const { currentDomain } = useSelector((state) => state.domainsReducer);
  const { blocks } = useSelector((state) => state.blocksReducer);
  const [weatherData, setWeatherData] = useState([]);
  const [selectBlockOption, setSelectBlockOption] = useState('all');
  const [selectBlockNameValue, setSelectBlockNameValue] = useState();
  const { token } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mapWeatherData = (weatherItems) => weatherItems.map((item) => {
    const indexDirection = windDirection(item.wd);
    return {
      key: item.id,
      date: item.date,
      dryBulbTemp: item.t2m.toFixed(2),
      tempHumidity: item.dewt2m.toFixed(2),
      speedWind2m: item.ws2m.toFixed(2),
      speedWind10m: item.ws10m.toFixed(2),
      windDirection: item.wd.toFixed(2),
      windPicto: windOrientation[indexDirection],
    };
  });

  const renderData = () => {
    const data = mapWeatherData(forecast);
    setWeatherData(data);
  };
  useEffect(() => {
    renderData();
  }, [forecast]);

  const getCsvFileName = () => {
    const dateRange = `${formatDate({ unixDate: startDateValue })}-au-${formatDate({ unixDate: endDateValue })}`;
    if (selectBlockNameValue) {
      return `${selectBlockNameValue}-${dateRange}.csv`;
    }
    return `toutes-parcelles-${dateRange}.csv`;
  };

  const onChange = (value) => {
    if (value === 'all') {
      dispatch(getWeatherForecastAction.request({ currentDomain: currentDomain.id, token }));
    } else {
      const selectedBlock = blocks.filter((block) => block.id === value);
      const selectedBlockWeatherMeasureId = selectedBlock[0].weatherMeasureId;
      const selectedBlockName = selectedBlock[0].name;

      dispatch(getWeatherForecastAction.request({
        weatherMeasureId: selectedBlockWeatherMeasureId,
        token,
      }));
      setSelectBlockOption(value);
      setSelectBlockNameValue(selectedBlockName);
    }
  };

  const renderOptions = () => {
    const allBlocks = <Option key="all" value="all">{t('module.statistics.allBlocks')}</Option>;

    const blockOption = blocks?.map((block) => (
      <Option key={block?.id} value={block?.id}>{block?.name}</Option>
    ));
    const finalBlockOption = [allBlocks, ...blockOption];

    return finalBlockOption;
  };

  const columns = [
    {
      title: t('module.statistics.date'),
      dataIndex: 'date',
      key: 'date',
      render: (text) => <span>{formatDate({ unixDate: text }).concat(' à ', formatDate({ unixHour: text }))}</span>,
      responsive: ['md', 'lg'],
      width: 60,
      align: 'center',
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: t('module.statistics.dryBulbTemp'),
      dataIndex: 'dryBulbTemp',
      key: 'dryBulbTemp',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      align: 'center',
      width: 60,
    },
    {
      title: t('module.statistics.tempHumidity'),
      dataIndex: 'tempHumidity',
      key: 'tempHumidity',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      align: 'center',
      width: 60,
    },
    {
      title: t('module.statistics.speedWind2m'),
      dataIndex: 'speedWind2m',
      key: 'speedWind2m',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      align: 'center',
      width: 70,
    },
    {
      title: t('module.statistics.speedWind10m'),
      dataIndex: 'speedWind10m',
      key: 'speedWind10m',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      align: 'center',
      width: 70,
    },
    {
      title: customIcon.direction(30, '#000'),
      dataIndex: 'windPicto',
      key: 'windPicto',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      align: 'center',
      width: 50,
    },
  ];

  const csvData = useMemo(() => [['Date', 'T° bulbe sèche', 'T° humide', 'Vitesse vent 2m', 'Vitesse vent 10m', 'Direction vent'],
    ...weatherData.map((payload) => [
      `${formatDate({ unixDate: payload.date })} à ${formatDate({ unixHour: payload.date })}`,
      payload.dryBulbTemp,
      payload.tempHumidity,
      payload.speedWind2m,
      payload.speedWind10m,
      payload.windDirection]),
  ], [weatherData]);

  return (
    <section className="list">
      <div className="list-header">
        <Select
          placeholder={t('module.statistics.filterByBlock')}
          optionFilterProp="block"
          value={selectBlockOption}
          onChange={(value) => onChange(value)}
          defaultValue={selectBlockOption}
        >
          {renderOptions()}
        </Select>
        <span>
          <CSVLink className="link-csv" data={csvData} filename={getCsvFileName()}>{t('module.statistics.downloadCSV')}</CSVLink>
        </span>
      </div>
      <Divider />
      <TableTemplate columns={columns} data={weatherData} height={600} />
    </section>
  );
};

PredictionList.propTypes = {
  startDateValue: PropTypes.number.isRequired,
  endDateValue: PropTypes.number.isRequired,
  forecast: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.number.isRequired,
    dewt2m: PropTypes.number.isRequired,
    t2m: PropTypes.number.isRequired,
  })).isRequired,
};

export default PredictionList;
