import { put, takeEvery, call } from 'redux-saga/effects';
import { AUTH, postAuthAction, postRefreshAction, REFRESH } from '../actions/authAction';
import { postAuthService, postRefreshService } from '../api/authService';

export function* postAuth({ payload }) {
  try {
    const token = yield call(postAuthService, payload);
    if (token) {
      const { data } = token;
      const { accessToken } = data;
      
      yield put(postAuthAction.success(token.data));
    } else {
      yield put(postAuthAction.error());
    }
  }
  catch (err) {
    yield put(postAuthAction.failure(err));
  }
}

export function* postAuthSaga() {
    yield takeEvery(AUTH.POST.REQUEST, postAuth)
}

export function* postRefresh({ payload }) {
  try {
    const token = yield call(postRefreshService, payload);
    if (token) {
      yield put(postRefreshAction.success(token.data));
    }
  }
  catch (err) {
    yield put(postRefreshAction.failure(err));
  }
}

export function* postRefreshSaga() {
    yield takeEvery(REFRESH.POST.REQUEST, postRefresh)
}

