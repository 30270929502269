import { put, takeEvery, call } from 'redux-saga/effects';
import { getTasksAction, deleteTasksAction,  TASKS, patchTasksAction, postTasksAction } from '../actions/tasksAction';
import { getTasksService, deleteTasksService, patchTasksService, postTasksService } from '../api/tasksService';

export function* getTasks({ payload }) {
    try {
        const tasks = yield call(getTasksService, payload);
        const sortedTasks = () => {
           return tasks.data.sort((a, b) => {
            return a.date > b.date ? -1 : a.date > b.date ? 0 : 1;
          })
        }
        yield put(getTasksAction.success(sortedTasks()))
        
    }
    catch (err) {
        yield put(getTasksAction.failure(err))
    }
};

export function* postTasks({ payload }) {
  const { companyId, domainId, token } = payload;
    try {
      yield call(postTasksService, payload);
      yield put(postTasksAction.success("task registered"));
      const tasks = yield call(getTasksService, { companyId, domainId, token });
      yield put(getTasksAction.success(tasks.data))
    } catch (error) {
      yield put(postTasksAction.failure(error));
    }
  }
  
  export function* patchTasks({ payload }) {
    const { token } = payload;
    try {
      yield call(patchTasksService, payload);
      yield put(patchTasksAction.success('task updated'));
      const tasks = yield call(getTasksService, {token: token});
      yield put(getTasksAction.success(tasks.data))

    } catch (err) {
      yield put(patchTasksAction.failure(err));
    }
  }

export function* deleteTasks({ payload }) {
  const { token } = payload;
    try {
      yield call(deleteTasksService, payload);
      yield put(deleteTasksAction.success('tasks deleted'));
      const tasks = yield call(getTasksService, {token: token});
      yield put(getTasksAction.success(tasks.data));
    }
    catch (err) {
      yield put(deleteTasksAction.failure(err));
    }
  }
  
  export const sagas = {
    deleteTasksSaga: takeEvery(TASKS.DELETE.REQUEST, deleteTasks),
    patchTasksSaga: takeEvery(TASKS.PATCH.REQUEST, patchTasks),
    postTasksSaga: takeEvery(TASKS.POST.REQUEST, postTasks),
    getTasksSaga: takeEvery(TASKS.FETCH.REQUEST, getTasks),
  }
  