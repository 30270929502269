import React from 'react';
import { PageHeader, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import VehicleForm from '../../shared/forms/VehicleForm';

const Vehicle = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader onBack={() => window.history.back()} title={t('vehicle.addvehicle')} />
      <Divider />
      <VehicleForm />
    </>
  );
};

export default Vehicle;
