import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Form, notification,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { patchSentPositionsAction, postSentPositionsAction } from 'common/business/actions/sentPositionsAction';
import { PropTypes } from 'prop-types';
import FormTemplate from './formTemplate';
import { renderDomainsCheckbox, renderSelectOptions } from '../../utils/utils';
import { AuthContext } from '../../utils/context/authContext';

const PositionForm = ({
  update, onClose, currentPosition,
}) => {
  const { blockId, domainId, companyId } = currentPosition;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { blocks } = useSelector((state) => state.blocksReducer);
  const { domains } = useSelector((state) => state.domainsReducer);
  const { companies } = useSelector((state) => state.companiesReducer);
  const { sentinelles } = useSelector((state) => state.sentinellesReducer);
  const [currentDomainState, setCurrentDomainState] = useState(domainId);
  const [currentCompanyState, setCurrentCompanyState] = useState(companyId);
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const activeDomains = domains.filter((domain) => domain.active);

  useEffect(() => {
    if (update) {
      if (currentPosition) {
        const {
          name, longitude, latitude, altitude, sentinel,
        } = currentPosition;
        const { id: sentinelId } = sentinel;
        form.setFieldsValue({

          blockId,
          point: [
            {
              longitude,
              latitude,
              altitude,
              name,
            },
          ],
          sentinelId,
        });
      }
    } else {
      form.resetFields();
    }
  }, [currentPosition, update]);

  const onSuccess = () => {
    notification.success({
      message: <Trans i18nKey="sentinelle.success.title" />,
      description: <Trans i18nKey="sentinelle.success.description" />,
    });
    if (update) {
      onClose();
    } else {
      form.resetFields();
    }
  };

  const onFinish = (event) => {
    const {
      sentinelId,
      point,
      blockId: eventBlockId,
      companyId: eventCompanyId,
      domainId: eventDomainId,
    } = event;
    const resultPromise = [];
    const updateEvent = { weathermeasuresLocationId: 1, sentinelId, ...point[0] };

    if (update) {
      resultPromise.push(dispatch(patchSentPositionsAction.request({
        companyId,
        domainId,
        blockId,
        sentinelPositionId: currentPosition.id,
        event: updateEvent,
        token,
        blocks,
      })));
    } else {
      resultPromise.push(dispatch(postSentPositionsAction.request({
        companyId: eventCompanyId,
        domainId: eventDomainId,
        blockId: eventBlockId,
        sentinelId,
        event: point[0],
        token,
      })));
    }

    return Promise.allSettled(resultPromise);
  };

  const onError = () => {
    notification.error({
      message: <Trans i18nKey="domain.error.title" />,
      description: <Trans i18nKey="domain.error.description" />,
    });
  };

  const onCancel = () => {
    form.resetFields();
  };

  const onChangeOption = (e) => {
    setCurrentDomainState(e.target.value);
  };

  const variables = [
    {
      name: 'companyId',
      label: t('form.company'),
      type: 'select',
      change: true,
      noUpdate: update,
      defaultValue: companyId,
      selectValues: renderSelectOptions(companies, companyId),
      disabled: !!companyId,
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'form.companyPH',
    },
    {
      name: 'domainId',
      label: t('form.domains'),
      type: 'radioGroup',
      noUpdate: update,
      radioOptions: renderDomainsCheckbox({ currentCompanyState, activeDomains }),
      defaultValue: domainId,
      disabled: domainId,
      rules: [
        {
          required: true,
        },
      ],
      onChangeOption: (e) => onChangeOption(e),
    },
    {
      name: 'blockId',
      label: t('task.landlot'),
      type: 'select',
      selectValues: renderSelectOptions(blocks, currentDomainState, 'blocks'),
      disabled: !blockId && update,
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'form.blockPH',
    },
    {
      name: 'sentinelId',
      label: t('form.serial'),
      type: 'select',
      showSearch: true,
      selectValues: renderSelectOptions(sentinelles, null, 'sentinelle'),
      placeholder: 'form.serialPH',
    },
    {
      name: 'point',
      label: t('form.sentinelPosition'),
      inputs: ['latitude', 'longitude', 'altitude', 'name'],
      type: 'groupInput',
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'form.blockPH',
    },
  ];

  const variablesItems = () => variables;
  return (
    <FormTemplate
      className="form"
      nameForm="register"
      useForm={form}
      variables={variablesItems()}
      onSuccess={onSuccess}
      onFinishApiCall={(event) => onFinish(event)}
      onError={onError}
      buttonValidText="form.valid"
      buttonCancelText="form.cancel"
      onCancel={onCancel}
      setSelectedValue={setCurrentCompanyState}
    />
  );
};

PositionForm.propTypes = {
  update: PropTypes.bool,
  onClose: PropTypes.func,
  currentPosition: PropTypes.shape({
    name: PropTypes.number,
    id: PropTypes.string,
    longitude: PropTypes.number,
    latitude: PropTypes.number,
    altitude: PropTypes.number,
    blockId: PropTypes.string,
    domainId: PropTypes.string,
    companyId: PropTypes.string,
    sentinel: PropTypes.shape({
      id: PropTypes.string,
      serial: PropTypes.string,
    }),
  }),
};

PositionForm.defaultProps = {
  update: false,
  onClose: () => {},
  currentPosition: {},
};

export default PositionForm;
