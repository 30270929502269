import React, { useEffect, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { patchTasksAction } from 'common/business/actions/tasksAction';
import { postCompanyAction } from 'common/business/actions/companyAction';
import { PropTypes } from 'prop-types';
import { regexEmail, regexMDP } from 'common/utils/utils';
import FormTemplate from './formTemplate';
import { AuthContext } from '../../utils/context/authContext';
import { Context } from '../../utils/context/intlContext';

const CompanyForm = ({ update, onClose, company }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = company;
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const context = useContext(Context);
  const lang = context?.locale.locale;
  const { t } = useTranslation();

  useEffect(() => {
    if (update) {
      if (company) {
        const {
          name,
          property,
          contact,
          phoneNumber,
          email,
          firstname,
          lastname,
          password,
          language,
          phone,

        } = company;
        form.setFieldsValue({
          name,
          property,
          contact,
          phoneNumber,
          email,
          firstname,
          lastname,
          password,
          language,
          phone,
        });
      }
    } else {
      form.resetFields();
    }
  }, [company, update]);

  const onSuccess = () => {
    notification.success({
      message: <Trans i18nKey="task.success.title" />,
      description: <Trans i18nKey="task.success.description" />,
    });
    if (update) {
      onClose();
    } else {
      form.resetFields();
    }
  };

  const onFinish = ({
    name,
    property,
    contact,
    phoneNumber,
    email,
    firstname,
    lastname,
    password,
    language,
    phone,
  }) => {
    const resultPromise = [];
    if (update) {
      resultPromise.push(dispatch(patchTasksAction.request({
        id,
        name,
        property,
        contact,
        phoneNumber,
        email,
        firstname,
        lastname,
        password,
        language,
        phone,
        source: 'web',
      })));
    } else {
      resultPromise.push(dispatch(postCompanyAction.request({
        name,
        property,
        contact,
        phoneNumber,
        user: {
          email,
          firstname,
          lastname,
          password,
          language,
          phone,
        },
        token,
      })));
    }
    return Promise.allSettled(resultPromise);
  };

  const onError = () => {
    notification.error({
      message: <Trans i18nKey="register.error.title" />,
      description: <Trans i18nKey="register.error.description.Task" />,
    });
  };

  const onCancel = () => {
    form.resetFields();
  };

  const variables = [
    {
      name: 'name',
      label: 'company.name',
      rules: [
        {
          required: true,
          min: 2,
        },
      ],
      placeholder: 'company.form.name',
    },
    {
      name: 'property',
      label: 'company.property',
      placeholder: 'company.form.property',
      rules: [
        {
          min: 2,
        },
      ],
    },
    {
      name: 'contact',
      label: 'company.contact',
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'company.form.contact',
    },
    {
      name: 'phoneNumber',
      label: 'company.phoneNumber',
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'company.form.phoneNumber',
    },
    {
      name: 'email',
      label: 'company.emailUser',
      rules: [
        {
          required: true,
          pattern: regexEmail,
        },
      ],
      placeholder: 'company.form.emailUser',
    },
    {
      name: 'firstname',
      label: 'company.firstnameUser',
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'company.form.firstnameUser',
    },
    {
      name: 'lastname',
      label: 'company.lastnameUser',
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'company.form.lastnameUser',
    },
    {
      name: 'password',
      label: 'company.passwordUser',
      rules: [
        {
          required: true,
          pattern: regexMDP,
        },
        {
          min: 12,
        },
      ],
      help: t('company.form.passwordUserHelp'),
      placeholder: 'company.form.passwordUser',
    },
    {
      name: 'language',
      label: 'company.languageUser',
      type: 'select',
      defaultValue: lang,
      selectValues: [
        { value: 'fr', text: 'fr-FR  🇫🇷' },
        { value: 'en', text: 'en-US  🇺🇸' },

      ],
      placeholder: 'company.form.languageUser',
    },
    {
      name: 'phone',
      label: 'company.phoneNumberUser',
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'company.form.phoneNumberUser',
    },
  ];
  const variablesItems = () => {
    if (update) {
      return variables.filter((item) => item.name !== 'password');
    }
    return variables;
  };
  return (
    <FormTemplate
      className="form"
      nameForm="register"
      useForm={form}
      variables={variablesItems()}
      onSuccess={onSuccess}
      onFinishApiCall={onFinish}
      onError={onError}
      buttonValidText="form.valid"
      buttonCancelText="form.cancel"
      onCancel={onCancel}
    />
  );
};

CompanyForm.propTypes = {
  update: PropTypes.bool,
  onClose: PropTypes.func,
  company: PropTypes.shape({
    name: PropTypes.string,
    property: PropTypes.string,
    contact: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    password: PropTypes.string,
    language: PropTypes.string,
    phone: PropTypes.string,
    id: PropTypes.number,
  }),
};

CompanyForm.defaultProps = {
  update: false,
  onClose: () => {},
  company: {},
};

export default CompanyForm;
