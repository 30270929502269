import axios from "axios";
import { networkErrorAction } from "../business/actions/errorAction";
import { store } from "../business/store";

// const apiServ = `https://sentinelle-vignes.ddns.net/`; // serveur de test
// const apiServ = `https://sentinelle-vignes.fr/`; // serveur de prod
// export const apiServ = `https://app.sentinelle-vignes.fr/`; // serveur de prod
const apiServ = 'https://app.sentinelle-vignes.fr/api/v0/company/' // apiServ access from same docker-compose
const aeolusAPI = 'https://app.sentinelle-vignes.fr/aeolus/v1/' // apiServ access from same docker-compose
// export const apiServ = `http://10.0.33.11:5000/api/v0/company/`;
// export const aeolusAPI = 'http://10.0.33.8:3324/aeolus/v1/'

export const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(response => response, (error) => {
  store.dispatch(networkErrorAction(error.response.data))
  return Promise.reject(error)
});

const headers = (token) => {
  const header = {
    headers: {
    'Authorization': `Bearer ${token}`
  }}
  return header
}

const get = async (data) => {
  const {url, source, id, token, endpoint } = data;
  const urlAPI = endpoint ? `${aeolusAPI}${url}` : url ? `${apiServ}${url}` : `${apiServ}`;

  const headers = {
    headers: {
      'Authorization': endpoint === 'aeolus' ? `Basic ${token}` : `Bearer ${token}`
    }
  }

  try {
    let response;
    if(!!token) {
      const res = await axiosInstance.get(urlAPI, headers);
      const resData = res.data;
      if (typeof (resData) === 'string' && res.status === 200) {
        const replace = resData.replace(/\bNaN\b/g, "null");
        const reponse = JSON.parse(replace)
        response = reponse;

      } else {
        res.status === 204 ? response = { data: res.statusText } : response = res;
        
      }
    } else {
      const res = await axiosInstance.get(urlAPI);
      
      res.status === 204 ? response = { data: res.statusText } : response = res;
    }
    return response.data
  }
  catch(err) {
    console.log('err : ', err);
  }
}

const put = async (url,user) => {
  try{
    const response = await axios.put(`${baseUrl}${url}`,user); 
    return response.data;
  }
  catch(err){
    console.log(err);
  }
}

const create = async (url,data) => {
  const { event, token } = data;
  const urlAPI = `${apiServ}${url}`;

  try{
    const response = await axiosInstance.post(urlAPI, event ? event : data , headers(token));
    return response.data;
  }
  catch(err){
    console.log('post error : ', err);
    return err;
  }
}

const createAeolus = async (url, data) => {
  const { token } = data;
  const urlAPI = `${aeolusAPI}${url}`;

  const headers = {
    headers: {
      'Authorization': `Basic ${token}`
    }
  }


  try {
    const response = await axiosInstance.post(urlAPI, data, headers)

    return response.data
  } catch (err) {

  }
} 

const auth = async (url, user) => {  
  try{
    const response = await axiosInstance.post(`${apiServ}${url}`, user, headers);
    return response.data;
  }
  catch(err){
    console.log(err);
  }
};

const update = async (url, data) => {
  const { token, event } = data;
  try{
    const response = await axios.patch(`${apiServ}${url}`, event ? event : data, headers(token));
    return response.data;
  }
  catch(err) {
    console.log(err);
  }
}

const updateAeolus = async (url, data) => {
  const { token } = data;
  const headers = {
    headers: {
      'Authorization': `Basic ${token}`
    }
  }

  try {
    const response = await axios.patch(`${aeolusAPI}${url}`, data, headers);
    return response;
  }
  catch (err) {
    console.log(err);
  }
}

const del = async (url, token) => {
  try {
    const response = await axios.delete(`${apiServ}${url}`, headers(token));
    return response.data;
    }
    catch(err) {
      console.log("!!--WARNING--!! : ",err);
    }
  }

  const getImages = async (data) => {
    const { url, token } = data;
    const urlAPI = `${apiServ}${url}`;
    const headers = {
      responseType: "arraybuffer",
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    try {
      const res = await axiosInstance.get(apiServ, headers);
      const data = `data:${res.headers["content-type"]};base64,${new Buffer(res.data, 'binary').toString('base64')}`;
      return data;
  
    }
    catch (err) {
      console.log(err)
    }
  }

  export default { get, put, create, update, del, auth, getImages, createAeolus, updateAeolus }
  