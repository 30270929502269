import { notification } from 'antd';
import { Trans } from 'react-i18next';

const renderTrans = (text) => <Trans i18nKey={text} />;

export const onSuccess = ({
  loading, update, form, onClose, messageTxt, descriptionTxt,
}) => {
  if (!loading) {
    notification.success({
      message: renderTrans(messageTxt),
      description: renderTrans(descriptionTxt),
    });
  }
  if (update) {
    onClose();
  } else {
    form.resetFields();
  }
};

export const onCancel = (form) => {
  form.resetFields();
};

export const onError = ({ messageTxt, descriptionTxt }) => {
  notification.error({
    message: renderTrans(messageTxt),
    description: renderTrans(descriptionTxt),
  });
};
