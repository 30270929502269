import { createContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const defaultValue = { token: '', refreshToken: '' };

export const AuthContext = createContext(defaultValue);

const AuthContextProvider = ({ children }) => {
  const { token } = useSelector((state) => state.authReducer);

  const contextValue = {
    token: token?.accessToken,
    refreshToken: token?.refreshToken,
    expirDate: token?.expiryDate,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      { children }
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default AuthContextProvider;
