import { BLOCKS, CURRENTBLOCK, PAYLOADS } from '../actions/blocksAction';

const INITIAL_STATE = { blocks: null, loading: false, blockState: null, payloads: null };

const blocksReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case BLOCKS.FETCH.REQUEST:
      return {...state, loading: true};
    case BLOCKS.FETCH.SUCCESS:
        return {...state, blocks: payload, loading: false};
    case BLOCKS.FETCH.FAILURE:
        return {...state, loading: false};
    case CURRENTBLOCK.FETCH.REQUEST:
      return {...state, loading: true};
    case CURRENTBLOCK.FETCH.SUCCESS:
      return {...state, currentBlock: payload, loading: false};
    case CURRENTBLOCK.FETCH.FAILURE:
      return {...state, loading: false };
    case PAYLOADS.FETCH.REQUEST:
        return {...state, loading: true};
    case PAYLOADS.FETCH.SUCCESS:
          return {...state, payloads: payload, loading: false};
    case PAYLOADS.FETCH.FAILURE:
          return {...state, loading: false};
    case BLOCKS.POST.REQUEST:
        return { ...state, loading: true };
    case BLOCKS.POST.SUCCESS:
        return { ...state, loading: false, blockState: payload };
    case BLOCKS.POST.FAILURE:
        return { ...state, loading: false };
    case BLOCKS.PATCH.REQUEST:
      return { ...state, loading: true };
    case BLOCKS.PATCH.SUCCESS:
      return { ...state, loading: false, blockState: payload };
    case BLOCKS.PATCH.FAILURE:
      return { ...state, loading: false };
    case BLOCKS.DELETE.REQUEST:
      return { ...state, loading: true };
    case BLOCKS.DELETE.SUCCESS:
      return { ...state, blockState: payload, loading: false};
    case BLOCKS.DELETE.FAILURE:
      return { ...state, loading: false};  
    default:
      return state;
  }
};

export default blocksReducer;
