/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Layout } from 'antd';
// import LanguageSwitch from './LanguageSwitch';
import { isLoggedin, showError } from '../utils/utils';

const { Content } = Layout;

const PublicRoute = ({
  component: Component, isLogged, setLogged, ...rest
}) => {
  const { path, location } = rest;
  const { currentUser } = useSelector((state) => state.loginReducer);
  const { msg } = useSelector((state) => state.errorReducer);
  const dispatch = useDispatch();
  // const isLogin = localStorage.getItem('userId');

  useEffect(() => {
    if (msg) {
      showError({ msg, dispatch });
    }
  }, [msg]);

  return (
    <Route
      path={path}
      location={location}
      render={(props) => (
        !isLoggedin(currentUser)
          ? (
            <>
              {/* <LanguageSwitch className="switch-lang" /> */}
              <Layout className="public-layout">
                <Layout className="public-content">
                  <Content>
                    <Component {...props} />
                  </Content>
                </Layout>
              </Layout>
            </>
          )

          : <Redirect to="/dashboard" />

      )}
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isLogged: PropTypes.bool,
  setLogged: PropTypes.func,
};

PublicRoute.defaultProps = {
  isLogged: null,
  setLogged: () => {},
};

export default PublicRoute;
