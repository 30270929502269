import { deleteProductsAction } from 'common/business/actions/productsAction';
import React, { useState, useContext, useEffect } from 'react';
import {
  Divider, Drawer, Popconfirm, Space,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DeleteOutlined, DiffOutlined, EditOutlined } from '@ant-design/icons';
import ProductForm from '../../shared/forms/ProductForm';
import { AuthContext } from '../../utils/context/authContext';
import DashboardList from './DashboardStyle';
import TableTemplate from '../../shared/TableTemplate';

const ProductsList = ({ isADMIN, isAGS }) => {
  const [visible, setVisible] = useState(false);
  const [currentProduct, setCurrentProduct] = useState();
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const { products } = useSelector((state) => state.productsReducer);
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();

  const renderData = () => {
    const data = [];
    products?.map((product) => data.push({
      key: product.id, ...product,
    }));
    setTableData(data);
  };

  useEffect(() => {
    renderData();
  }, [products]);

  const onClose = () => {
    setVisible(false);
    setUpdate(false);
  };

  const confirm = (id) => {
    dispatch(deleteProductsAction.request({ id, token }));
  };

  const columns = [
    {
      title: 'nom du produit',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      width: 90,
      hidden: false,
    },
    {
      title: t('module.actions'),
      dataIndex: t('module.actions'),
      key: t('module.actions'),
      align: 'right',
      width: 50,
      hidden: !(isAGS || isADMIN),
      render: (event, record) => (
        <Space size="middle">
          <EditOutlined
            className="iconAction disabled"
            onClick={() => {
              setVisible(!visible);
              setCurrentProduct(record);
              setUpdate(true);
            }}
          />
          { isAGS && (
            <Popconfirm
              title={t('module.delete')}
              onConfirm={() => confirm(record.id)}
            >
              <DeleteOutlined className="iconAction disabled" />
            </Popconfirm>
          )}
        </Space>
      ),
      responsive: ['md', 'lg'],
    },
  ];

  const renderColumns = columns.filter((col) => col.hidden === false);

  return (
    <>
      <DashboardList width="400">
        <div className="header-list">
          <h3>
            {t('module.product.title')}
          </h3>
          {(isAGS || isADMIN) && (
          <DiffOutlined onClick={() => setVisible(!visible)} />
          )}
        </div>
        <Divider />
        <TableTemplate columns={renderColumns} data={tableData} />
      </DashboardList>
      <Drawer
        title={update ? t('module.product.update') : t('module.product.create')}
        placement="right"
        closable
        onClose={onClose}
        open={visible}
        width={500}
      >
        <ProductForm update={!!update} onClose={onClose} product={currentProduct} token={token} />
      </Drawer>
    </>
  );
};

ProductsList.propTypes = {
  isADMIN: PropTypes.bool.isRequired,
  isAGS: PropTypes.bool.isRequired,
};

export default ProductsList;
