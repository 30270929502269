import api from '../../utils/query';

export const getDomainsService = (data) => {
    const { source, token, companyId } = data
    const url = companyId ? `${companyId}/domain` : 'domains'
    return api.get({url, source, token});
}

export const getAllDomainsService = (data) => {
    const { token } = data;
    const url = 'domains'

    return api.get({ url, token});
}

export const getPayloadsDomainService = (data) => {
    const { domainId, startDate, endDate, token } = data
    const url = `domain/${domainId}/payload/${startDate}/${endDate}`
    return api.get({url, token});
}

export const postDomainService = (data) => {
    const { companyId } = data;
    const urlAPI = `${companyId}/domain`;
    return api.create(urlAPI, data);
}

export const patchDomainService = (data) => {
    const {id} = data;
    const url = `domain/${id}`;
    return api.update(url, data);
}

export const deleteDomainService = (id) => {
    const domain = id.toString();

    return api.del("domains",id);
}
