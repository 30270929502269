import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { currentDate } from 'common/utils/utils';
import { useLayoutEffect, useState } from 'react';
import { getImagesAction } from 'common/business/actions/imagesAction';
import { Select } from 'antd';
import { getCurrentBlockAction } from 'common/business/actions/blocksAction';
import ModalGallery from './ModalGallery';
import ThumbList from './ThumbList';

const { Option } = Select;

const Gallery = () => {
  const { currentDomain } = useSelector((state) => state.domainsReducer);
  const { currentBlock, blocks } = useSelector((state) => state.blocksReducer);
  const { currentSentinelle } = useSelector((state) => state.sentinellesReducer);
  const { token } = useSelector((state) => state.authReducer);
  const {
    images, loading, cam0f, cam1f,
  } = useSelector((state) => state.imagesReducer);
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectOption, setSelectOption] = useState(currentBlock?.id);
  const [currentList, setCurrentList] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const timestamp = currentDate * 1000;

  useLayoutEffect(() => {
    dispatch(getImagesAction.request({
      currentBlockId: currentBlock.id,
      companyId: currentDomain.companyId,
      currentDomainId: currentDomain.id,
      timestamp,
      token: token.accessToken,
    }));
  }, [selectOption]);

  const onChange = (value) => {
    const selectedBlock = blocks?.filter((block) => (
      block?.id === value
    ));
    dispatch(getCurrentBlockAction.request(selectedBlock[0]));
    setSelectOption(value);
  };

  const renderOptions = () => blocks?.map((block) => (
    <Option key={block?.id} value={block?.id}>{block?.name}</Option>
  ));

  return (
    <section>
      <h2>{t('menu.gallery')}</h2>
      <p>{t('gallery.infoMsg')}</p>
      <Select
        className="select-gallery"
        placeholder="Select a block"
        optionFilterProp="block"
        value={selectOption}
        onChange={(value) => onChange(value)}
        defaultValue={selectOption}
      >
        {renderOptions()}
      </Select>
      {currentSentinelle && (
      <ThumbList
        currentSentinelle={currentSentinelle}
        cam0f={cam0f}
        cam1f={cam1f}
        images={images}
        loading={loading}
        setCurrentList={setCurrentList}
        setCurrentImage={setCurrentImage}
        setVisibleModal={setVisibleModal}

      />
      )}
      {visibleModal && (
        <ModalGallery
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          currentList={currentList}
          currentImage={currentImage}
        />
      )}
    </section>
  );
};

export default Gallery;
