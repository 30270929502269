import { Divider, PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import SentinelleForm from '../../shared/forms/SentinelleForm';

const SentinelSettings = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader onBack={() => window.history.back()} title={t('settings.addSent')} />
      <Divider />
      <SentinelleForm />
    </>
  );
};

export default SentinelSettings;
