import action from '../../utils/actionsConf';
import createRequestTypes from '../../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';
const PATCH = 'PATCH';
const DELETE = 'DELETE';

export const TYPE_TASKS = createRequestTypes('TYPE_TASKS', [FETCH, POST, PATCH, DELETE]);

export const getTypeTasksAction = {
  request: payload => action(TYPE_TASKS.FETCH.REQUEST, payload),
  success: data => action(TYPE_TASKS.FETCH.SUCCESS, data),
  failure: error => action(TYPE_TASKS.FETCH.FAILURE, error),
};
export const postTypeTasksAction = {
  request: (payload) => action(TYPE_TASKS.POST.REQUEST, payload),
  success: (data) => action(TYPE_TASKS.POST.SUCCESS, data),
  failure: (error) => action(TYPE_TASKS.POST.FAILURE, error),
};
export const patchTypeTasksAction = {
  request: (payload) => action(TYPE_TASKS.PATCH.REQUEST, payload),
  success: (data) => action(TYPE_TASKS.PATCH.SUCCESS, data),
  failure: (error) => action(TYPE_TASKS.PATCH.FAILURE, error),
};
export const deleteTypeTasksAction = {
  request: (id) => action(TYPE_TASKS.DELETE.REQUEST, id),
  success: (data) => action(TYPE_TASKS.DELETE.SUCCESS, data),
  failure: (error) => action(TYPE_TASKS.DELETE.FAILURE, error),
};
