import React, { useEffect, useState } from 'react';
import {
  Divider, Drawer, Space,
} from 'antd';
import { useSelector } from 'react-redux';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CompanyForm from '../../shared/forms/CompanyForm';
import TableTemplate from '../../shared/TableTemplate';
import DashboardList from './DashboardStyle';
import { renderActiveElement } from '../../utils/utils';

const CompaniesList = () => {
  const [visible, setVisible] = useState(false);
  const { companies, currentCompany } = useSelector((state) => state.companiesReducer);
  const [update, setUpdate] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();

  const renderData = () => {
    const data = [];
    companies?.map((company) => data.push({
      key: company.id, ...company,
    }));
    setTableData(data);
  };
  useEffect(() => {
    renderData();
  }, [companies]);

  const onClose = () => {
    setVisible(false);
    setUpdate(false);
  };

  // const handleDelete = (event, id) => {
  //   event.preventDefault();
  //   dispatch(deleteBlockAction.request(id));
  // };

  // const confirm = (event, id) => {
  //   handleDelete(event, id);
  // };

  // const handleClickSentinelles = () => {
  //   dispatch(getSentinellesByBlockAction.request({
  //     token,
  //     currentBlockId: actualBlock.id,
  //     companyId,
  //     currentDomainId: currentDomain.id,
  //   }));
  // };

  const columns = [
    {
      title: t('module.company.title'),
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      width: 90,
    },
    {
      title: t('module.company.contact'),
      dataIndex: 'contact',
      key: 'contact',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      width: 120,
    },
    {
      title: t('module.company.activ'),
      dataIndex: 'active',
      key: 'active',
      render: (text) => renderActiveElement(text),
      responsive: ['md', 'lg'],
      width: 40,
      align: 'center',
    },
    {
      title: t('module.actions'),
      dataIndex: t('module.actions'),
      key: t('module.actions'),
      align: 'right',
      width: 50,
      render: () => (
        <Space size="middle">
          <EditOutlined
            className="iconAction disabled"
            // onClick={() => {
              // setVisible(!visible);
              // setCurrentBlock(record);
              // setUpdate(true);
            // }}
          />
          {/* <Popconfirm
            title={t('module.delete')}
            onConfirm={(event) => confirm(event, record.id)}
          > */}
          <DeleteOutlined className="iconAction disabled" />
          {/* </Popconfirm> */}
        </Space>
      ),
      responsive: ['md', 'lg'],
    },
  ];

  return (
    <>
      <DashboardList width="600">
        <div className="header-list">
          <h3>
            {t('module.company.list')}
          </h3>
          {/* <DiffOutlined onClick={() => setVisible(!visible)} /> */}
        </div>

        <Divider />
        <TableTemplate columns={columns} data={tableData} />
      </DashboardList>
      <Drawer
        title={update ? t('module.company.update') : t('module.company.create')}
        placement="right"
        closable
        onClose={onClose}
        open={visible}
        width={500}
      >
        <CompanyForm
          update={!!update}
          onClose={onClose}
          company={currentCompany}
        />
      </Drawer>
    </>
  );
};

export default CompaniesList;
