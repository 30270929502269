import { COMPANY, deleteCompanyAction, patchCompanyAction, postCompanyAction } from '../actions/companyAction';
import { put, takeEvery, call } from 'redux-saga/effects';
import { getCompaniesAction } from '../actions/companyAction';
import { getAllCompaniesService, postCompanyService } from '../api/companyService';

export function* getCompanies({ payload }) {
    try {
        const companies = yield call(getAllCompaniesService, payload);
        yield put(getCompaniesAction.success(companies.data));
    }
    catch (err) {
        yield put(getCompaniesAction.failure(err));
    }
};

export function* postCompany({ payload }) {
    const { token } = payload
    try {
      yield call(postCompanyService, payload);
      yield put(postCompanyAction.success("company has been created"));
      const companies = yield call(getAllCompaniesService, { token })
      yield put(getCompaniesAction.success(companies.data));
    } catch (error) {
      yield put(postCompanyAction.failure(error));
    }
  }
  
  function* patchCompany({ payload }) {
    try {
      yield call(patchBlockService, payload);
      yield put(patchCompanyAction.success('company has been updated'));
      yield put(getCompaniesAction.request());
    } catch (err) {
      yield put(patchCompanyAction.failure(err));
    }
  }
  
  function* deleteCompany({ payload }) {
    try {
      yield call(deleteCompanyService, payload);
      yield put(deleteCompanyAction.success('company has been deleted'));
      yield put(getCompaniesAction.request());
    }
    catch (err) {
      yield put(deleteCompanyAction.failure(err));
    }
  }
  
  export const sagas = {
    getCompaniesSaga: takeEvery(COMPANY.FETCH.REQUEST, getCompanies),
    postCompanySaga: takeEvery(COMPANY.POST.REQUEST, postCompany),
    patchCompanySaga: takeEvery(COMPANY.PATCH.REQUEST, patchCompany),
    deleteCompanySaga: takeEvery(COMPANY.DELETE.REQUEST, deleteCompany),
  }
