import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Form, notification,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { patchSentinelleAction, postSentinelleAction } from 'common/business/actions/sentinellesAction';
import { PropTypes } from 'prop-types';
import FormTemplate from './formTemplate';
import { renderDomainsCheckbox, renderSelectOptions } from '../../utils/utils';
import { AuthContext } from '../../utils/context/authContext';

const SentinelleForm = ({
  update, onClose, sentinelle,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = sentinelle;
  const { blocks } = useSelector((state) => state.blocksReducer);
  const { domains, currentDomain } = useSelector((state) => state.domainsReducer);
  const { companies, currentCompany } = useSelector((state) => state.companiesReducer);
  const [currentDomainState, setCurrentDomainState] = useState(currentDomain?.id);
  const [currentCompanyState, setCurrentCompanyState] = useState(currentCompany);
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const activeDomains = domains.filter((domain) => domain.active);

  useEffect(() => {
    if (update) {
      if (sentinelle) {
        const { sentName } = sentinelle;
        form.setFieldsValue({
          serial: sentName,
        });
      }
    } else {
      form.resetFields();
    }
  }, [sentinelle, update]);

  const onSuccess = () => {
    notification.success({
      message: <Trans i18nKey="sentinelle.success.title" />,
      description: <Trans i18nKey="sentinelle.success.description" />,
    });
    if (update) {
      onClose();
    } else {
      form.resetFields();
    }
  };

  const onFinish = (event) => {
    const {
      companyId, domainId, blockId, serial, point,
    } = event;
    const resultPromise = [];
    if (update) {
      resultPromise.push(dispatch(patchSentinelleAction.request({
        id, source: 'web',
      })));
    } else {
      resultPromise.push(dispatch(postSentinelleAction.request({
        companyId,
        domainId,
        blockId,
        serial,
        sentinelPositions: point,
        token,
      })));
    }
    return Promise.allSettled(resultPromise);
  };

  const onError = () => {
    notification.error({
      message: <Trans i18nKey="domain.error.title" />,
      description: <Trans i18nKey="domain.error.description" />,
    });
  };

  const onCancel = () => {
    form.resetFields();
  };

  const onChangeOption = (e) => {
    setCurrentDomainState(e.target.value);
  };

  const variables = [
    {
      name: 'companyId',
      label: t('form.company'),
      type: 'select',
      change: true,
      defaultValue: currentCompany,
      selectValues: renderSelectOptions(companies, currentCompany),
      disabled: !!currentCompany,
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'form.companyPH',
    },
    {
      name: 'domainId',
      label: t('form.domains'),
      type: 'radioGroup',
      radioOptions: renderDomainsCheckbox({ currentCompanyState, activeDomains }),
      defaultValue: currentDomain?.id,
      disabled: !!currentDomain?.id,
      rules: [
        {
          required: true,
        },
      ],
      onChangeOption: (e) => onChangeOption(e),
    },
    {
      name: 'blockId',
      label: t('task.landlot'),
      type: 'select',
      selectValues: renderSelectOptions(blocks, currentDomainState, 'blocks'),
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'form.blockPH',
    },
    {
      name: 'serial',
      label: t('form.serial'),
      placeholder: 'form.serialPH',
    },
    {
      name: 'point',
      label: t('form.sentinelPosition'),
      inputs: ['latitude', 'longitude', 'altitude', 'name'],
      type: 'groupInput',
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'form.blockPH',
    },
  ];

  const variablesItems = () => variables;
  return (
    <FormTemplate
      className="form"
      nameForm="register"
      useForm={form}
      variables={variablesItems()}
      onSuccess={onSuccess}
      onFinishApiCall={(event) => onFinish(event)}
      onError={onError}
      buttonValidText="form.valid"
      buttonCancelText="form.cancel"
      onCancel={onCancel}
      setSelectedValue={setCurrentCompanyState}
    />
  );
};

SentinelleForm.propTypes = {
  update: PropTypes.bool,
  onClose: PropTypes.func,
  sentinelle: PropTypes.shape({
    sentName: PropTypes.string,
    id: PropTypes.number,
    long: PropTypes.string,
    lat: PropTypes.string,
    alt: PropTypes.string,
    blockID: PropTypes.number,
  }),
};

SentinelleForm.defaultProps = {
  update: false,
  onClose: () => {},
  sentinelle: {},
};

export default SentinelleForm;
