import { Form, notification } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { patchServiceProviderAction, postServiceProviderAction } from 'common/business/actions/serviceProviderAction';
import FormTemplate from './formTemplate';
import { AuthContext } from '../../utils/context/authContext';

const ServiceProviderForm = ({
  serviceProvider, update, onClose,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { domains, currentDomain } = useSelector((state) => state.domainsReducer);
  const authCtx = useContext(AuthContext);
  const { token, companyId } = authCtx;

  useEffect(() => {
    if (update) {
      if (serviceProvider) {
        const {
          name,
          phone,
          domainId,
        } = serviceProvider;

        form.setFieldsValue({
          name,
          phone,
          domainId,
        });
      }
    } else {
      form.resetFields();
    }
  }, [update, serviceProvider]);

  const renderblocksOptions = () => {
    const blocksOptions = [];
    domains?.map((domain) => blocksOptions.push({ value: domain.id, text: domain.name }));
    return blocksOptions;
  };

  const onSuccess = () => {
    notification.success({
      message: <Trans i18nKey="register.success.title" />,
      description: <Trans i18nKey="register.success.description.serviceProvider" />,
    });
    if (update) {
      onClose();
      form.resetFields();
    } else {
      form.resetFields();
    }
  };

  const onFinish = ({ name, phone, domainId }) => {
    const resultPromise = [];

    if (update) {
      resultPromise.push(dispatch(patchServiceProviderAction.request({
        id: serviceProvider.id, name, phone, domainId, token,
      })));
    } else {
      resultPromise.push(dispatch(postServiceProviderAction.request({
        name,
        phoneNumber: phone,
        domainIdList: domainId,
        currentDomainId: currentDomain?.id,
        token,
        companyId,
      })));
    }
    return Promise.allSettled(resultPromise);
  };

  const onError = () => {
    notification.error({
      message: <Trans i18nKey="serviceProvider.error.title" />,
      description: <Trans i18nKey="serviceProvider.error.description" />,
    });
  };

  const onCancel = () => {
    form.resetFields();
  };

  const variables = [
    {
      name: 'name',
      label: t('serviceProvider.form.name'),
      rules: [
        {
          required: true,
          min: 2,
          whiteSpace: true,
        },
      ],
      placeholder: t('serviceProvider.form.placeholderName'),
    },
    {
      name: 'phone',
      label: t('serviceProvider.form.phone'),
      type: 'number',
      rules: [
        {
          required: true,
        },
      ],
      placeholder: t('serviceProvider.form.placeholderPhone'),
    },
    {
      name: 'domainId',
      label: t('serviceProvider.form.domains'),
      type: 'select',
      mode: 'multiple',
      selectValues: renderblocksOptions(),
      rules: [
        {
          required: true,
        },
      ],
      placeholder: t('serviceProvider.form.placeholderDomains'),
    },
  ];

  return (
    <FormTemplate
      className="form"
      nameForm="serviceProvider"
      useForm={form}
      variables={variables}
      onSuccess={onSuccess}
      onFinishApiCall={onFinish}
      onError={onError}
      buttonValidText="form.valid"
      buttonCancelText="form.cancel"
      onCancel={onCancel}
    />
  );
};

ServiceProviderForm.propTypes = {
  serviceProvider: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    id: PropTypes.number,
    domainId: PropTypes.arrayOf(PropTypes.shape()),
  }),
  update: PropTypes.bool,
  onClose: PropTypes.func,
};

ServiceProviderForm.defaultProps = {
  update: false,
  onClose: () => {},
  serviceProvider: {},
};

export default ServiceProviderForm;
