import api from '../../utils/query';

export const getVehicleService = (data) => {
    const {companyId, source, token} = data;
    const url = `${companyId}/vehicles`
    return api.get({url, source, token});
}

export const postVehiclesService = (data) => {
    return api.create("vehicle", data);
}

export const patchVehiclesService = (data) => {
    const { id } = data;
    const url = `vehicle/${id}`;
    return api.update(url, data);
}

export const deleteVehiclesService = (id) => {
    const url = `vehicle/${id}`
    return api.del(url);
}
