import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Form, notification,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { patchWindAction, postWindAction } from 'common/business/actions/windsAction';
import { PropTypes } from 'prop-types';
import FormTemplate from './formTemplate';
import { AGS, renderDomainsCheckbox, renderSelectOptions } from '../../utils/utils';

const WindForm = ({
  update, onClose, currentWind,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    blocks: selectedBlocks,
    domainId: selectedDomain,
    companyId: selectedCompany,
    serial: selectedSerial,
    blocksId: selectedBlocksId,
    active: selectedWindActive,
  } = currentWind;
  const { blocks } = useSelector((state) => state.blocksReducer);
  const { serials } = useSelector((state) => state.windsReducer);
  const { domains, currentDomain } = useSelector((state) => state.domainsReducer);
  const { companies, currentCompany } = useSelector((state) => state.companiesReducer);
  const { userInfos } = useSelector((state) => state.usersReducer);
  const [currentDomainState, setCurrentDomainState] = useState(currentDomain?.id);
  const [currentCompanyState, setCurrentCompanyState] = useState(currentCompany);
  const [isActive, setIsActive] = useState(selectedWindActive);
  const { t } = useTranslation();
  const serialList = serials && serials.filter((elt) => !elt.activated);
  const activeDomains = domains && domains.filter((domain) => domain.active);
  const blocksIdList = selectedBlocksId?.split(', ');
  const { roleCode } = userInfos;
  const isAGS = AGS.includes(roleCode);
  const TOKEN = process.env.REACT_APP_API_AEOLUS_TOKEN;

  useEffect(() => {
    if (update) {
      const currentBlocksUpdate = [];
      selectedBlocks?.map((block, index) => {
        blocksIdList?.map((blockIdList, blockIndex) => {
          if (index === blockIndex) {
            currentBlocksUpdate.push(
              { value: blockIdList, text: block },
            );
          }
          return null;
        });
        return null;
      });
      setCurrentCompanyState(selectedCompany);
      setCurrentDomainState(selectedDomain);
      if (currentWind) {
        form.setFieldsValue({
          blocksId: currentBlocksUpdate,
          domainId: selectedDomain,
          companyId: selectedCompany,
          serial: selectedSerial,
          active: isActive,
        });
      }
    } else {
      form.resetFields();
    }
  }, [serials, update]);

  const onSuccess = () => {
    notification.success({
      message: <Trans i18nKey="sentinelle.success.title" />,
      description: <Trans i18nKey="sentinelle.success.description" />,
    });
    if (update) {
      onClose();
    } else {
      form.resetFields();
    }
  };

  const onFinish = (event) => {
    const {
      companyId, domainId, blocksId, serial,
    } = event;
    const resultPromise = [];
    if (update) {
      resultPromise.push(dispatch(patchWindAction.request({
        blocksId: blocksId.join(', '),
        domainId: selectedDomain,
        serial: selectedSerial,
        roleCode,
        companyId: selectedCompany,
        active: isActive,
        source: 'web',
        token: TOKEN,
      })));
    } else {
      resultPromise.push(dispatch(postWindAction.request({
        companyId,
        domainId,
        blocksId: blocksId.join(', '),
        serial,
        token: TOKEN,
      })));
    }
    return Promise.allSettled(resultPromise);
  };

  const onError = () => {
    notification.error({
      message: <Trans i18nKey="domain.error.title" />,
      description: <Trans i18nKey="domain.error.description" />,
    });
  };

  const onCancel = () => {
    form.resetFields();
  };

  const onChangeOption = (e) => {
    setCurrentDomainState(e.target.value);
  };

  const handleChange = () => {
    setIsActive(!isActive);
  };

  const variables = [
    {
      name: 'companyId',
      label: t('form.company'),
      type: 'select',
      change: true,
      initialValue: currentCompany,
      selectValues: renderSelectOptions(companies, selectedCompany),
      disabled: update,
      rules: [
        {
          required: !!update,
        },
      ],
      placeholder: 'form.companyPH',
    },
    {
      name: 'domainId',
      label: t('form.domains'),
      type: 'radioGroup',
      radioOptions: renderDomainsCheckbox({ currentCompanyState, activeDomains }),
      initialValue: currentDomain?.id,
      rules: [
        {
          required: !!update,
        },
      ],
      onChangeOption: (e) => onChangeOption(e),
    },
    {
      name: 'blocksId',
      label: t('task.landlot'),
      type: 'select',
      mode: 'multiple',
      selectValues: renderSelectOptions(blocks, currentDomainState, 'blocks'),
      rules: [
        {
          required: true,
        },
      ],
      placeholder: 'form.blockPH',
    },
    {
      name: 'serial',
      label: t('form.serial'),
      type: 'select',
      showSearch: true,
      disabled: update,
      selectValues: renderSelectOptions(serialList, currentDomainState, 'serial'),
      rules: [{
        required: !update,
      }],
      placeholder: 'form.serialPH',
    },
    {
      name: 'active',
      label: t('form.active'),
      type: 'switch',
      hidden: !isAGS,
      active: isActive,
      onChange: handleChange,
    },
  ];

  const variablesItems = () => variables;
  return (
    <FormTemplate
      className="form"
      nameForm="register"
      useForm={form}
      variables={variablesItems()}
      onSuccess={onSuccess}
      onFinishApiCall={(event) => onFinish(event)}
      onError={onError}
      buttonValidText="form.valid"
      buttonCancelText="form.cancel"
      onCancel={onCancel}
      setSelectedValue={setCurrentCompanyState}
    />
  );
};

WindForm.propTypes = {
  update: PropTypes.bool,
  onClose: PropTypes.func,
  currentWind: PropTypes.shape({
    domainId: PropTypes.string,
    blocks: PropTypes.arrayOf(PropTypes.string),
    companyId: PropTypes.string,
    serial: PropTypes.string,
    blocksId: PropTypes.string,
    active: PropTypes.bool,
  }),
};

WindForm.defaultProps = {
  update: false,
  onClose: () => {},
  currentWind: {},
};

export default WindForm;
