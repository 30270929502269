import React, { useEffect, useState } from 'react';
import {
  Divider,
} from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TableTemplate from '../../shared/TableTemplate';
import DashboardList from './DashboardStyle';
import { renderActiveElement } from '../../utils/utils';

const SentinellesList = () => {
  const { sentPositions } = useSelector((state) => state.sentPositionsReducer);

  const { sentinelles } = useSelector((state) => state.sentinellesReducer);
  const { blocks } = useSelector((state) => state.blocksReducer);
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();

  const renderData = () => {
    const data = [];

    sentinelles?.map((sentinelle) => data.push({
      key: sentinelle.id,
      blockName: 'block',
      sentName: sentinelle.serial,
      ...sentinelle,
    }));

    setTableData(data);
    return 'block';
  };
  const renderBlockName = (text, item) => {
    let blockName = '';
    if (sentPositions) {
      const position = sentPositions?.filter((pos) => item.serial === pos.sentinel.serial);
      if (position?.length > 0) {
        const { blockId } = position[0];
        const blockTarget = blockId && blocks.filter((block) => block.id === blockId);
        blockName = blockTarget && blockTarget[0]?.name;
      }
    }
    return <span>{blockName?.length > 0 ? blockName : 'non attribuée'}</span>;
  };
  const renderSentName = (text, item) => {
    let sentName = [];
    if (sentPositions) {
      sentName = sentPositions.filter((pos) => item.serial === pos.sentinel.serial);
    }
    return <span>{sentName?.length > 0 ? sentName[0]?.name : '--'}</span>;
  };

  useEffect(() => {
    renderData();
  }, [sentinelles]);

  const columns = [
    {
      title: t('module.sentinel.serial'),
      dataIndex: 'serial',
      key: 'serial',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      width: 110,
      sorter: (a, b) => a.serial - b.serial,
    },
    {
      title: t('module.sentinel.title'),
      dataIndex: 'sentName',
      key: 'sentName',
      render: (text, item) => renderSentName(text, item),
      responsive: ['md', 'lg'],
      width: 70,
      sorter: (a, b) => a.sentName - b.sentName,
    },
    {
      title: t('module.block.title'),
      dataIndex: 'blockName',
      key: 'blockName',
      render: (text, item) => renderBlockName(text, item),
      responsive: ['md', 'lg'],
      width: 90,
    },
    {
      title: t('module.company.activ'),
      dataIndex: 'enabled',
      key: 'enabled',
      render: (text) => renderActiveElement(text),
      responsive: ['md', 'lg'],
      width: 50,
      align: 'center',
      filters: [
        {
          text: 'active',
          value: true,
        },
        {
          text: 'inactive',
          value: false,
        },
      ],
      onFilter: (value, record) => record.enabled === value,
    },
  ];

  return (
    <DashboardList width="900">
      <div className="header-list">
        <h3>{t('module.sentinel.list')}</h3>
      </div>
      <Divider />
      <TableTemplate columns={columns} data={tableData} />
    </DashboardList>
  );
};

export default SentinellesList;
