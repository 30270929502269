import { put, takeEvery, call } from 'redux-saga/effects';
import { getWarningAction, WARNING } from '../actions/warningAction';
import { getWarningService } from '../api/warningService';

export function* getwarning({ payload }) {
    try {
        const warning = yield call(getWarningService, payload);
       
        yield put(getWarningAction.success(warning.data))
        
    }
    catch (err) {
        yield put(getWarningAction.failure(err))
    }
};

export function* getWarningSaga() {
    yield takeEvery(WARNING.FETCH.REQUEST, getwarning)
}
