import React from 'react';
import { Affix, Layout } from 'antd';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sentLogo from 'common/assets/images/GLOBE-SENTINELLE.png';

const { Header } = Layout;

const HeaderContent = ({ path }) => {
  const { currentDomain } = useSelector((state) => state.domainsReducer);
  const top = 0;

  return (
    <Header className={path === '/dashboard' || path === '/gallery' || path === '/statistics' || path === '/domains' ? 'header header-bg' : 'header header-test'}>
      <section className="right-content">
        <span>{currentDomain ? currentDomain?.name : 'Tous les domaines'}</span>
        <div className="domain">
          <Affix offsetTop={top}>
            <Link to="/domains">
              <img
                src={currentDomain ? `https://app.sentinelle-vignes.fr/assets/images/domains/${currentDomain.logo}` : sentLogo}
                alt={currentDomain ? currentDomain?.name : 'AGS-administrator'}
              />
            </Link>
          </Affix>
        </div>
      </section>
    </Header>
  );
};
HeaderContent.propTypes = {
  path: PropTypes.string,
};

HeaderContent.defaultProps = {
  path: '/dashboard',
};

export default HeaderContent;
