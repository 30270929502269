import React from 'react';
import { PageHeader, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import ProductForm from '../../shared/forms/ProductForm';

const Products = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader onBack={() => window.history.back()} title={t('product.addProduct')} />
      <Divider />
      <ProductForm />
    </>
  );
};

export default Products;
