import {
  fresh1Filter,
  fresh1HC,
  fresh2Filter,
  fresh2HC,
  fresh3Filter,
  fresh3HC,
  fresh4Filter,
  fresh4HC,
  fresh5Filter,
  fresh5HC,
  heatmapOpacity,
  heatmapRadius,
  hot1Filter,
  hot1HC,
  hot2Filter,
  hot2HC,
  hot3Filter,
  hot3HC,
  hot4Filter,
  hot4HC,
  hot5Filter,
  hot5HC,
  median1Filter,
  median1HC,
  median2Filter,
  median2HC,
  median3Filter,
  median3HC,
  median4Filter,
  median4HC,
  median5Filter,
  median5HC,
  neutralFilter,
  neutralHC,
  sickness1Filter,
  sickness2Filter,
  sickness3Filter,
  sickness4Filter,
  sickness5Filter,
  sickness6Filter,
  sicknessNeutralFilter,
} from 'common/utils/utils';
import React from 'react';
import { Layer } from 'react-map-gl';

const layerStyleFresh1 = {
  id: 'fresh1',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': fresh5HC,
  },
  filter: fresh1Filter,
};

const layerStyleFresh2 = {
  id: 'fresh2',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': fresh4HC,
  },
  filter: fresh2Filter,
};

const layerStyleFresh3 = {
  id: 'fresh3',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': fresh3HC,
  },
  filter: fresh3Filter,
};

const layerStyleFresh4 = {
  id: 'fresh4',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': fresh2HC,
  },
  filter: fresh4Filter,
};

const layerStyleFresh5 = {
  id: 'fresh5',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': fresh1HC,
  },
  filter: fresh5Filter,
};

const layerStyleMedian1 = {
  id: 'median1',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': median1HC,
  },
  filter: median1Filter,
};
const layerStyleMedian2 = {
  id: 'median2',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': median2HC,
  },
  filter: median2Filter,
};
const layerStyleMedian3 = {
  id: 'median3',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': median3HC,
  },
  filter: median3Filter,
};
const layerStyleMedian4 = {
  id: 'median4',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': median4HC,
  },
  filter: median4Filter,
};
const layerStyleMedian5 = {
  id: 'median5',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': median5HC,
  },
  filter: median5Filter,
};
const layerStyleHot1 = {
  id: 'hot1',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': hot1HC,
  },
  filter: hot1Filter,
};
const layerStyleHot2 = {
  id: 'hot2',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': hot2HC,
  },
  filter: hot2Filter,
};
const layerStyleHot3 = {
  id: 'hot3',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': hot3HC,
  },
  filter: hot3Filter,
};
const layerStyleHot4 = {
  id: 'hot4',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': hot4HC,
  },
  filter: hot4Filter,
};
const layerStyleHot5 = {
  id: 'hot5',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': hot5HC,
  },
  filter: hot5Filter,
};
const layerStyleNeutral = {
  id: 'neutralTemp',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': neutralHC,
  },
  filter: neutralFilter,
};
const sicknessLevel0 = {
  id: 'level0',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': median5HC,
  },
  filter: sickness1Filter,
};
const sicknessLevel1 = {
  id: 'level1',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': hot1HC,
  },
  filter: sickness2Filter,
};
const sicknessLevel2 = {
  id: 'level2',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': hot2HC,
  },
  filter: sickness3Filter,
};
const sicknessLevel3 = {
  id: 'level3',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': hot3HC,
  },
  filter: sickness4Filter,
};
const sicknessLevel4 = {
  id: 'level4',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': hot4HC,
  },
  filter: sickness5Filter,
};
const sicknessLevel5 = {
  id: 'level5',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': hot5HC,
  },
  filter: sickness6Filter,
};
const sicknessNeutral = {
  id: 'neutral',
  type: 'heatmap',
  paint: {
    'heatmap-radius': heatmapRadius,
    'heatmap-opacity': heatmapOpacity,
    'heatmap-color': neutralHC,
  },
  filter: sicknessNeutralFilter,
};

export const circleLayer = {
  id: 'sentinelle',
  type: 'circle',
  paint: {
    'circle-color': '#000',
    'circle-radius': 5,
  },
};

export const lineLayer = {
  id: 'trace',
  type: 'line',
  paint: {
    'line-color': 'rgb(233, 183, 233)',
    'line-width': 5,
  },
};
export const buildValuesColorSickness = ({ elt, setValuesColor }) => {
  const array = [];
  elt.forEach((valueFilter) => {
    const { value } = valueFilter.properties;
    switch (true) {
      case value > 0 && value <= 10:
        array.push('level1');
        break;
      case value > 10 && value <= 20:
        array.push('level2');
        break;
      case value > 20 && value <= 30:
        array.push('level3');
        break;
      case value > 30 && value <= 40:
        array.push('level4');
        break;
      case value > 40 && value <= 59:
        array.push('level5');
        break;
      case value === 201:
        array.push('neutral');
        break;
      default:
        array.push('level0');
    }
  });
  const newArray = [...new Set(array)];
  setValuesColor(newArray);
};

export const buildValuesColor = ({ elt, setValuesColor }) => {
  const array = [];
  elt.forEach((valueFilter) => {
    const { value } = valueFilter.properties;
    switch (true) {
      case value <= 5:
        array.push('fresh5');
        break;
      case value > 5 && value <= 10:
        array.push('fresh4');
        break;
      case value > 10 && value <= 15:
        array.push('fresh3');
        break;
      case value > 15 && value <= 20:
        array.push('fresh2');
        break;
      case value > 20 && value <= 26:
        array.push('fresh1');
        break;
      case value > 26 && value <= 35:
        array.push('median5');
        break;
      case value > 35 && value <= 39:
        array.push('median4');
        break;
      case value > 39 && value <= 55:
        array.push('median3');
        break;
      case value > 55 && value <= 65:
        array.push('median2');
        break;
      case value > 65 && value <= 68:
        array.push('median1');
        break;
      case value > 68 && value <= 80:
        array.push('hot1');
        break;
      case value > 80 && value <= 85:
        array.push('hot2');
        break;
      case value > 85 && value <= 90:
        array.push('hot3');
        break;
      case value > 90 && value <= 95:
        array.push('hot4');
        break;
      case value > 95 && value <= 100:
        array.push('hot5');
        break;
      case value === 101:
        array.push('neutralTemp');
        break;
      default:
        array.push('');
    }
  });
  const newArray = [...new Set(array)];
  setValuesColor(newArray);
};

export const showLayers = (valuesColor) => valuesColor.map((value) => {
  switch (true) {
    case value === 'fresh1':
      return <Layer key={value} beforeId="waterway-label" id={layerStyleFresh1.id} type={layerStyleFresh1.type} paint={layerStyleFresh1.paint} filter={layerStyleFresh1.filter} />;
    case value === 'fresh2':
      return <Layer key={value} beforeId="waterway-label" id={layerStyleFresh2.id} type={layerStyleFresh2.type} paint={layerStyleFresh2.paint} filter={layerStyleFresh2.filter} />;
    case value === 'fresh3':
      return <Layer key={value} beforeId="waterway-label" id={layerStyleFresh3.id} type={layerStyleFresh3.type} paint={layerStyleFresh3.paint} filter={layerStyleFresh3.filter} />;
    case value === 'fresh4':
      return <Layer key={value} beforeId="waterway-label" id={layerStyleFresh4.id} type={layerStyleFresh4.type} paint={layerStyleFresh4.paint} filter={layerStyleFresh4.filter} />;
    case value === 'fresh5':
      return <Layer key={value} beforeId="waterway-label" id={layerStyleFresh5.id} type={layerStyleFresh5.type} paint={layerStyleFresh5.paint} filter={layerStyleFresh5.filter} />;
    case value === 'median1':
      return <Layer key={value} beforeId="waterway-label" id={layerStyleMedian1.id} type={layerStyleMedian1.type} paint={layerStyleMedian1.paint} filter={layerStyleMedian1.filter} />;
    case value === 'median2':
      return <Layer key={value} beforeId="waterway-label" id={layerStyleMedian2.id} type={layerStyleMedian2.type} paint={layerStyleMedian2.paint} filter={layerStyleMedian2.filter} />;
    case value === 'median3':
      return <Layer key={value} beforeId="waterway-label" id={layerStyleMedian3.id} type={layerStyleMedian3.type} paint={layerStyleMedian3.paint} filter={layerStyleMedian3.filter} />;
    case value === 'median4':
      return <Layer key={value} beforeId="waterway-label" id={layerStyleMedian4.id} type={layerStyleMedian4.type} paint={layerStyleMedian4.paint} filter={layerStyleMedian4.filter} />;
    case value === 'median5':
      return <Layer key={value} beforeId="waterway-label" id={layerStyleMedian5.id} type={layerStyleMedian5.type} paint={layerStyleMedian5.paint} filter={layerStyleMedian5.filter} />;
    case value === 'hot1':
      return <Layer key={value} beforeId="waterway-label" id={layerStyleHot1.id} type={layerStyleHot1.type} paint={layerStyleHot1.paint} filter={layerStyleHot1.filter} />;
    case value === 'hot2':
      return <Layer key={value} beforeId="waterway-label" id={layerStyleHot2.id} type={layerStyleHot2.type} paint={layerStyleHot2.paint} filter={layerStyleHot2.filter} />;
    case value === 'hot3':
      return <Layer key={value} beforeId="waterway-label" id={layerStyleHot3.id} type={layerStyleHot3.type} paint={layerStyleHot3.paint} filter={layerStyleHot3.filter} />;
    case value === 'hot4':
      return <Layer key={value} beforeId="waterway-label" id={layerStyleHot4.id} type={layerStyleHot4.type} paint={layerStyleHot4.paint} filter={layerStyleHot4.filter} />;
    case value === 'hot5':
      return <Layer key={value} beforeId="waterway-label" id={layerStyleHot5.id} type={layerStyleHot5.type} paint={layerStyleHot5.paint} filter={layerStyleHot5.filter} />;
    case value === 'neutralTemp':
      return <Layer key={value} beforeId="waterway-label" id={layerStyleNeutral.id} type={layerStyleNeutral.type} paint={layerStyleNeutral.paint} filter={layerStyleNeutral.filter} />;
    case value === 'level0':
      return <Layer key={value} beforeId="waterway-label" id={sicknessLevel0.id} type={sicknessLevel0.type} paint={sicknessLevel0.paint} filter={sicknessLevel0.filter} />;
    case value === 'level1':
      return <Layer key={value} beforeId="waterway-label" id={sicknessLevel1.id} type={sicknessLevel1.type} paint={sicknessLevel1.paint} filter={sicknessLevel1.filter} />;
    case value === 'level2':
      return <Layer key={value} beforeId="waterway-label" id={sicknessLevel2.id} type={sicknessLevel2.type} paint={sicknessLevel2.paint} filter={sicknessLevel2.filter} />;
    case value === 'level3':
      return <Layer key={value} beforeId="waterway-label" id={sicknessLevel3.id} type={sicknessLevel3.type} paint={sicknessLevel3.paint} filter={sicknessLevel3.filter} />;
    case value === 'level4':
      return <Layer key={value} beforeId="waterway-label" id={sicknessLevel4.id} type={sicknessLevel4.type} paint={sicknessLevel4.paint} filter={sicknessLevel4.filter} />;
    case value === 'level5':
      return <Layer key={value} beforeId="waterway-label" id={sicknessLevel5.id} type={sicknessLevel5.type} paint={sicknessLevel5.paint} filter={sicknessLevel5.filter} />;
    case value === 'neutral':
      return <Layer key={value} beforeId="waterway-label" id={sicknessNeutral.id} type={sicknessNeutral.type} paint={sicknessNeutral.paint} filter={sicknessNeutral.filter} />;
    default:
      return null;
  }
});
