import { COMPANY, CURRENT_COMPANY } from '../actions/companyAction';

const INITIAL_STATE = { companies: null, loading: false, currentCompany: null, companyState: null };

const companiesReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case COMPANY.FETCH.REQUEST:
      return {...state, loading: true};
    case COMPANY.FETCH.SUCCESS:
        return {...state, companies: payload, loading: false};
    case COMPANY.FETCH.FAILURE:
        return {...state, loading: false};
    case CURRENT_COMPANY.FETCH.REQUEST:
      return {...state, loading: true};
    case CURRENT_COMPANY.FETCH.SUCCESS:
        return {...state, currentCompany: payload, loading: false};
    case CURRENT_COMPANY.FETCH.FAILURE:
        return {...state, loading: false};
    case COMPANY.POST.REQUEST:
        return { ...state, loading: true };
    case COMPANY.POST.SUCCESS:
        return { ...state, loading: false, companyState: payload };
    case COMPANY.POST.FAILURE:
        return { ...state, loading: false };
    case COMPANY.PATCH.REQUEST:
      return { ...state, loading: true };
    case COMPANY.PATCH.SUCCESS:
      return { ...state, loading: false, companyState: payload };
    case COMPANY.PATCH.FAILURE:
      return { ...state, loading: false };
    case COMPANY.DELETE.REQUEST:
      return { ...state, loading: true };
    case COMPANY.DELETE.SUCCESS:
      return { ...state, companyState: payload, loading: false};
    case COMPANY.DELETE.FAILURE:
      return { ...state, loading: false};  
    default:
      return state;
  }
};

export default companiesReducer;
