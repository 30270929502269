import React from 'react';
import { PageHeader, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import UserForm from '../../shared/forms/UserForm';

const UserSettings = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader onBack={() => window.history.back()} title={t('settings.addUser')} />
      <Divider />
      <UserForm />
    </>
  );
};

export default UserSettings;
