import { Divider, PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import WindForm from '../../shared/forms/WindForm';

const WindSettings = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader onBack={() => window.history.back()} title={t('settings.addWind')} />
      <Divider />
      <WindForm />
    </>
  );
};

export default WindSettings;
