import { getWindPayloadsAction } from 'common/business/actions/windsAction';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Card, Tag } from 'antd';
import notice from 'common/assets/pdf/WIND_VF.pdf';
import manual from 'common/assets/pdf/manual-wind.pdf';
import { AimOutlined, PoweroffOutlined } from '@ant-design/icons';
import {
  formatDate, getDistanceInMiles, windDirection, windOrientation,
} from 'common/utils/utils';
// import markerImage from 'common/assets/images/GLOBE-SENTINELLE.png';
import { Marker, Map } from 'react-map-gl';
import { AGS } from '../../utils/utils';
import IconFont from '../../utils/CustomIconsAd';

const TOKEN = process.env.REACT_APP_API_AEOLUS_TOKEN;
const MBToken = process.env.REACT_APP_MB_TOKEN;
const MAPSTYLE = process.env.REACT_APP_MB_MAPSTYLE;

const Winds = () => {
  const { currentDomain } = useSelector((state) => state.domainsReducer);
  const { blocks } = useSelector((state) => state.blocksReducer);
  const { winds, windPayloads } = useSelector((state) => state.windsReducer);
  const { userInfos } = useSelector((state) => state.usersReducer);
  const { roleCode } = userInfos;
  const isAGS = AGS.includes(roleCode);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isLangFr = i18n.language === 'fr';

  useEffect(() => {
    if (isAGS && !currentDomain) {
      dispatch(getWindPayloadsAction.request({ token: TOKEN, winds }));
    } else {
      dispatch(getWindPayloadsAction.request({
        companyId: currentDomain.companyId, winds, token: TOKEN,
      }));
    }
  }, []);

  const renderBlocksByName = (blocksId) => blocksId.map((blockId) => {
    const block = blocks.find((item) => item.id === blockId);
    if (block) {
      const { name } = block;
      return <Tag icon={<AimOutlined />} color="#8ADFC1" key={blockId}>{name}</Tag>;
    }
    return null;
  });

  const onResumeClick = (type) => {
    if (type === 'notice') {
      window.open(notice);
    } else if (type === 'manual') {
      window.open(manual);
    }
  };

  return (
    <section className="container winds">
      <div className="pdf-link">
        <Button onClick={() => onResumeClick('notice')}>{t('winds.notice')}</Button>
        <Button onClick={() => onResumeClick('manual')}>{t('winds.manual')}</Button>
      </div>
      <>
        {windPayloads && windPayloads.map((payload) => {
          const blocksId = payload?.blocksId.split(', ');
          const { orientation, windSpeed, date } = payload;
          const indexDirection = windDirection(orientation);
          const speed = isLangFr ? (Math.ceil(windSpeed) * (60 * 60)) / 1000
            : getDistanceInMiles(windSpeed);
          return (
            <Card
              className="card"
              key={payload.serial}
              title={(
                <>
                  <span className="card-title">
                    {t('winds.wind')}
                    {payload.serial}
                  </span>
                  <PoweroffOutlined className={`${payload.poweron ? 'active-icon' : 'inactive-icon'}`} />
                </>
              )}
            >
              <div className="card-content">
                <div className="card-infos">
                  <p>
                    {t('winds.ventilatedParcel')}
                    {renderBlocksByName(blocksId)}
                  </p>
                  <p>
                    {t('winds.levelBattery')}
                    {`${payload.batteryLevel} %`}
                  </p>
                  <p>
                    {t('winds.bladesRotation')}
                    {`${payload.fanSpeed} TPM`}
                  </p>
                  <p>
                    {t('winds.orientation')}
                    {t(windOrientation[indexDirection])}
                  </p>
                  <p>
                    {t('winds.speed')}
                    {`${speed} m/s`}
                  </p>
                  <p>
                    {t('winds.positionMat')}
                    {payload.positionMat ? t('winds.raised') : t('winds.lowered')}
                  </p>
                  <p>
                    {t('winds.lastUpdate')}
                    {`${formatDate({ unixDate: date })} à ${formatDate({ unixHour: date })}`}
                  </p>
                </div>
                <div className="card-map">
                  <span>Dernière position connue avant extinction</span>
                  <Map
                    latitude={payload.latitude}
                    longitude={payload.longitude}
                    width="100%"
                    height="100%"
                    mapboxAccessToken={MBToken}
                    mapStyle={MAPSTYLE}
                    zoom={14.9}
                  >
                    <Marker
                      latitude={payload.latitude}
                      longitude={payload.longitude}
                    >
                      <IconFont
                        type="icon-si-glyph-wind-turbines"
                        className="marker-wind"
                      />
                    </Marker>
                  </Map>
                </div>
              </div>
            </Card>
          );
        })}
      </>
    </section>
  );
};
export default Winds;
