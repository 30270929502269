import { WEATHER, FORECAST } from '../actions/weatherAction';

const INITIAL_STATE = { weather: null, loading: false, forecast: null };

const weatherReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case WEATHER.FETCH.REQUEST:
      return {...state, loading: true};
    case WEATHER.FETCH.SUCCESS:
        return {...state, weather: payload, loading: false};
    case WEATHER.FETCH.FAILURE:
        return {...state, loading: false};    
    case FORECAST.FETCH.REQUEST:
      return {...state, loading: true};
    case FORECAST.FETCH.SUCCESS:
        return {...state, forecast: payload, loading: false};
    case FORECAST.FETCH.FAILURE:
        return {...state, loading: false};    
    default:
      return state;
  }
};

export default weatherReducer;
