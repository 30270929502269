import {
  CheckCircleOutlined, CloseCircleOutlined,
} from '@ant-design/icons';
import { notification } from 'antd';
import { networkErrorDismissAction } from 'common/business/actions/errorAction';

export const getBreakPoints = (width) => {
  switch (true) {
    case width < 768:
      return 'mobile';
    case width >= 768 && width < 980:
      return 'tablet';
    case width >= 980 && width < 1024:
      return 'smallDesk';
    case width >= 1024 && width < 1280:
      return 'mediumDesk';
    case width >= 1280:
      return 'largeDesk';
    default:
      return undefined;
  }
};

export const renderSelectOptions = (typeSelect, current, state) => {
  const arrayOptions = [];

  if (state === 'sentinelle') {
    const newList = typeSelect.filter((item) => item.enabled === false);
    newList.map((item) => arrayOptions.push({ value: item.id, text: item.serial }));
  } else if (state === 'blocks') {
    if (current) {
      const newList = typeSelect?.filter((item) => item.domainId === current);
      newList.map((item) => arrayOptions.push({ value: item.id, text: item.name }));
    }
  } else if (state === 'serial') {
    typeSelect.map((item) => arrayOptions.push({ value: item.serial, text: item.serial }));
  } else {
    typeSelect?.map((elt) => {
      if (current && current === elt.id) {
        return arrayOptions.push({ value: current, text: elt.name });
      }
      arrayOptions.push({ value: elt.id, text: elt.name });

      return 1;
    });
  }

  return arrayOptions;
};

export const typesCategorieTask = [{ id: 0, name: 'Mécanique' }, { id: 1, name: 'Chimique' }, { id: 2, name: 'Manuelle' }];

export const isLoggedin = (user) => !!user;

export const AGS_ADMIN = 'AGS_ADMIN';
export const AGS_USER = 'AGS_USER';
export const SUPER_ADMIN = 'SUPER_ADMIN';
export const ADMIN = 'ADMIN';
export const USER = 'USER';
export const CONSULTANT = 'CONSULTANT';
export const ALL_ADMIN = ['ADMIN', 'SUPER_ADMIN'];
export const AGS = ['AGS_ADMIN', 'AGS_USER'];

export const isInPermission = (roles, permission) => {
  let claims = [];
  if (Array.isArray(roles)) {
    claims = claims.concat(roles);
  } else {
    claims.push(roles || '');
  }
  return claims.find((c) => permission.find((perm) => perm === c)) != null;
};

export const renderDomainsCheckbox = ({ currentCompanyState, activeDomains }) => {
  const options = [];
  if (activeDomains) {
    if (currentCompanyState) {
      const newList = activeDomains.filter((item) => item.companyId === currentCompanyState);
      newList.forEach((item) => options.push({ value: item.id, label: item.name }));
    } else {
      activeDomains.forEach((item) => options.push({ value: item.id, label: item.name }));
    }
  }
  return options;
};

export const showError = ({ msg, dispatch }) => {
  const description = typeof (msg?.error?.detail) === 'string' ? msg?.error?.detail : msg?.error?.detail.title;
  notification.error({
    message: 'Oups ! un problème est survenu',
    description,
    closable: true,
    duration: 2,
    onClose: () => { dispatch(networkErrorDismissAction()); },
  });
};

export const renderActiveElement = (text) => {
  if (text) {
    return <CheckCircleOutlined style={{ color: '#52c41a' }} />;
  }
  return <CloseCircleOutlined style={{ color: '#770000' }} />;
};

export const toggleShowDrawer = () => {
  console.log('test');
};
